import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { UserService } from '../../services/user.service';

import { AppHelper } from '../../helpers/app-helper';

import { faInfoCircle, faCoin, faPlus, faMinus, faWallet } from '@fortawesome/pro-regular-svg-icons';

import { environment } from '../../../environments/environment';
import { MerchantService } from 'src/app/services/merchant.service';
import { OrderService } from 'src/app/services/order.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'loyalty-card',
  templateUrl: './loyalty-card.component.html',
  styleUrls: ['./loyalty-card.component.scss'],
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate('350ms')),
    ])
  ]
})
export class LoyaltyCardComponent implements OnInit {

  @Input() flipped: boolean = false;
  @Input() user_loyalty_point: any;
  @Input() large_screen: boolean = false;
  @Input() max_index = 100;
  @Output() flipped_changed = new EventEmitter<boolean>();
  @Output() max_index_changed = new EventEmitter();

  public faInfoCircle = faInfoCircle;
  public faCoin = faCoin;
  public faPlus = faPlus;
  public faMinus = faMinus;
  public faWallet = faWallet;
  public logo;
  public loyalty_points: any;
  public checkoutFieldUpdateSubscription;

  public background_url = "assets/backgrounds/empty.png";

  public selectedQrCode: any;
  private merchant_slug: any;

  public whitelabel = environment.whitelabel;
  public fol = false;

  constructor(  public userService: UserService,
                private appHelper: AppHelper,
                public merchantService: MerchantService,
                public orderService: OrderService,
                public router: Router,
                private route: ActivatedRoute) { 
     this.fol = this.appHelper.isFol();
                  this.route.params.subscribe(
                    params => {
                      this.merchant_slug = params['merchant_slug'];
                      console.log(this.merchant_slug);
                    });
                }

  ngOnInit(): void {
  	this.logo = './assets/logos/' + environment.whitelabel + '.png';
    if (this.user_loyalty_point.merchant.header_image) { this.background_url = this.user_loyalty_point.merchant.header_image.large_url; };

    this.getLoyaltyPointsForOrder();

    this.checkoutFieldUpdateSubscription = this.userService.checkoutFieldsUpdate.subscribe((updateOngoing) => {
      this.getLoyaltyPointsForOrder();
    });
  }

  onClick() {
    if (this.merchant_slug) {
      this.router.navigate(['../loyalty-card'], { relativeTo: this.route });
    } else {
      let domain = this.user_loyalty_point.merchant.domain;
      this.router.navigate([`../${domain}/loyalty-card`], { relativeTo: this.route });
    }
  }

  flipIt(loyalty_point, large_screen) {
  
    if (!this.user_loyalty_point.empty) {
      if (!large_screen && loyalty_point.z_index != this.max_index) {
			  this.max_index++;
			  loyalty_point.z_index = this.max_index;
			  this.max_index_changed.emit(this.max_index);
      } else {
    		loyalty_point.flipped = !loyalty_point.flipped;
    		loyalty_point.animationState == 'in' ? loyalty_point.animationState = 'out' : loyalty_point.animationState = 'in';
    		loyalty_point.flipped ? this.flipped = true : this.flipped = false;
    		this.flipped_changed.emit(this.flipped);
      }
    }
  }

  getLoyaltyPointsForOrder() {
    if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) {
      let order_id = this.userService.basket.order.id;
      this.orderService.getLoyaltyPoints(order_id).subscribe(
        response => {
          this.loyalty_points = +response.points;
          this.loyalty_points = Math.floor(this.loyalty_points);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  goToVenue(merchant, event) {
    this.appHelper.setVenue(merchant.domain);
    event.stopPropagation();
  }

  ngOnDestroy() {
    if (this.checkoutFieldUpdateSubscription) this.checkoutFieldUpdateSubscription.unsubscribe();
  }
}
