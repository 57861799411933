<div class="main d-flex flex-column justify-content-between" 
	 style="height:100%;">

<div class="titlebar px-3 py-3 rounded-top">
	<div class="d-flex justify-content-between first-part">
		<div>{{ 'location.title' | translate }}</div>
		<div class="clickable" (click)="close()">
			<mat-icon>close</mat-icon>
		</div>
	</div>
</div>

<div class="h-100 mat-app-background">

	<div class="subtitle p-3">
		{{ 'location.address_search' | translate }}
	</div>

	<div class="p-3">
		<div style="width:100%;">
			<input  style="padding:20px; border:1px solid darkgrey;border-radius:5px; height:40px; width: 100%;"
			type="text"
			placeholder="Adres zoeken"
			(ngModelChange)="getPlaces.next($event)"
			[ngModel]="selected_address">

		</div>
	</div>	

	<div class="p-3 d-flex flex-column" style="flex-grow:1; overflow: auto;">
		<ng-container 	class="place" 
		*ngFor="let prediction of predictions">
		<div class="place" *ngIf="prediction.types.includes('route') || prediction.types.includes('street_address') || prediction.types.includes('premise')">
			<div class="d-flex">
				
				<fa-icon
						class="pe-3" 
						*ngIf="prediction.selected"
                      	[icon]="faMapMarker">
                </fa-icon>

                <fa-icon 
                		class="pe-3"
                		*ngIf="!prediction.selected"
                      	[icon]="faMapMarkerRegular">
                </fa-icon>

				<div 	
						style="flex-grow:1">
					<div  id="{{ prediction.place_id }}"
						  *ngIf="prediction.types.includes('route')"
						  class="clickable"
						  style="text-align: left;"
						  (click)="setFocus(prediction,$event)">
						<div>{{ prediction.description }}</div>
    					<div style="position:relative;">
    						<div style="position:absolute;top:1px;left:10px;">
    							+
    						</div>
    						<input class="add_street_number"
    							   type="text" 
    							   placeholder="Huisnummer toevoegen"
    							   (change)="setStreetNumber(prediction, $event)">
    					</div>
					</div>
					<div 	*ngIf="prediction.types.includes('street_address') || prediction.types.includes('premise')"
					class="clickable"
					style="text-align: left;"
					(click)="setPlace(prediction)">
					{{ prediction.description }}
					</div>
					<!--div *ngIf="place.has_street_number">{{ appHelper.getAddressFormatted(place.address) }}</div>
					<div *ngIf="!place.has_street_number">
						{{ appHelper.getAddressFormatted(place.address, false) }}
						<input 	matInput placeholder="enter street number" 
								[(ngModel)]="place.address.street_number" 
								(change)="setStreetNumber(place)"
								type="text">
					</div>
				-->
			</div>
			<div style="width:50px; flex-shrink: 0;">
				<mat-icon *ngIf="prediction.selected" color="primary">check</mat-icon>
			</div>
		</div>
	</div>
</ng-container>

<div class="place clickable" *ngIf="currentAddress && currentAddress.selected && currentAddress.line1 != undefined">
	<div class="d-flex">
		<fa-icon 
				class="pe-3"
               	[icon]="faMapMarker">
        </fa-icon>

		<div style="flex-grow:1">{{ appHelper.getAddressFormatted(currentAddress) }}</div>
		<div style="width:50px; flex-shrink: 0;">
			<mat-icon *ngIf="currentAddress.selected" color="primary">check</mat-icon>
		</div>
	</div>
</div>
<div 	class="place clickable" 
		*ngIf="geoAddress && 
				(!currentAddress || 
				!(currentAddress.latitude == geoAddress.latitude && currentAddress.longitude == geoAddress.longitude)
				)" 
		(click)="setCurrentLocation()">
	<div class="d-flex">
		<fa-icon 
			class="pe-3"
			*ngIf="geoAddress.selected"
        	[icon]="faLocation">
        </fa-icon>

        <fa-icon 
        	class="pe-3"
        	*ngIf="!geoAddress.selected"
        	[icon]="faLocationRegular">
        </fa-icon>

		<div style="flex-grow:1">{{ appHelper.getAddressFormatted(geoAddress) }}</div>
		<div style="width:50px; flex-shrink: 0;">
			<mat-icon *ngIf="geoAddress.selected" color="primary">check</mat-icon>
		</div>
	</div>
</div>
</div>

</div>

<div 	(click)="saveLocation()"
		[ngClass]="[userService.address ? 'd-flex align-items-center justify-content-between footer p-3 clickable rounded-bottom' : ' d-flex align-items-center justify-content-between footer footer-unavailable p-3 rounded-bottom']">

	<div class="bar-add no_selection" style="flex-grow:1;text-align:center;font-weight:bolder;">
		{{ 'save_address' | translate }}
	</div>
</div>

</div>
		