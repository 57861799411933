import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-catering-location-sheet',
  templateUrl: './catering-location-sheet.component.html',
  styleUrls: ['./catering-location-sheet.component.scss']
})
export class CateringLocationSheetComponent implements OnInit {

  public cateringLocationSelected = new EventEmitter();


  constructor(public userService: UserService,
  			  @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    console.log('THESE ARE THE locations');
    console.log(this.data.cateringLocations);
  }

  selectCateringLocation(cateringLocation) {
  	this.cateringLocationSelected.emit(cateringLocation);
  }

}
