import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { MerchantService} from '../../services/merchant.service';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.css']
})
export class TimeSheetComponent implements OnInit {

  public timeSelected = new EventEmitter();
  public block_display = 'TIME';
  public block_duration = 15;

  constructor(public merchantService: MerchantService,
              public userService: UserService,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {

    let scenario = this.userService.basket.order.scenario;
    let block_duration = 15;

    if (this.merchantService.merchant.settings['s_block_display_scenario_' + scenario] && this.merchantService.merchant.settings['s_block_display_scenario_' + scenario] == 'BLOCK') {
      this.block_display = 'BLOCK';
      this.block_duration = this.merchantService.merchant.settings['s_block_duration_scenario_' + scenario];
    }

    if (this.block_display == 'BLOCK') {
      for (let slot of this.data.slots) {
        let begin = new Date(slot.value);
        let end = new Date(slot.value);
        end.setMinutes(end.getMinutes() + this.block_duration);
        slot.begin = begin;
        slot.end = end;
      }
    }

  }

  selectTime(slot, index) {
  	this.timeSelected.emit(slot);
  }

}
