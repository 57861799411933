<!-- TEMPLATE HIGHLIGHT -->

<ng-template #highlight let-product="product">
	<div class="template_product_highlight d-flex justify-content-between align-items-center">
		<div class="w-50 h-100">
			<div 	*ngIf="product.main_image"
					class="highlight-image"
					[style.background-image]="'url(' + product.main_image.medium_url + ')'">
					<div 	style="position:absolute;top:0px;right:0px;z-index:490"
							*ngIf="product.in_promo">
						<img class="promo" src='./assets/promo/promo.png'>
					</div>
				</div>
		</div>
		<div class="w-50 h-100 p-3">
			<div class="product-div d-flex flex-column justify-content-between align-items-end">
				<div class="template2 product_name pe-1">{{ product.name }}</div>
				<div class="template2 product_description">
					{{ (product.description.length > 200)? (product.description | slice:0:200) : (product.description) }}
					<p *ngIf="product.description.length > 200" class="clickable">... {{ 'read_more' | translate }}</p>
				</div>
				<div>
					<ng-container *ngTemplateOutlet="price; context:{product:product, imgClass: 'template2 product_price p-1 mt-1'}"></ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- TEMPLATE PRICE -->

<ng-template #price let-product="product" let-imgClass="imgClass">
	<div 	class="{{ imgClass }}"
			[ngStyle]="{'color': !fol ? 'white' : '#fdd329', 'background-color': !fol && merchantService.merchant.primary_color}"
			*ngIf="product.is_available && !product.in_promo && product.price > 0">
					{{ product.price | currency:'EUR' }}
					<span *ngIf="product.unit != 'piece'"> / {{ 'order_unit.' + product.unit | translate }}</span>
			</div>
			<div 	 class="d-flex align-items-center"
					 *ngIf="product.in_promo">

			<div class="line-through pt-1 pb-1 mt-1 pe-3">
				{{ product.price | currency:'EUR' }}
			</div>

			<div class="template2 product_price p-1 mt-1 font-weight-bold"
				 [ngStyle]="{'color': !fol ? 'white' : '#fdd329', 'background-color': !fol && merchantService.merchant.primary_color}">		 
				 {{ product.price_with_modifiers | currency:'EUR' }}
			</div>


			</div>
	<div class="product_unavailable" *ngIf="!product.is_available">{{ 'product.out_of_stock' | translate }}</div>
</ng-template>

<!-- TEMPLATE PRODUCT IMAGE -->

<ng-template #productImage let-product="product" let-imgClass="imgClass">
	<div *ngIf="product.main_image"
		class="{{ imgClass }}"
		[style.background-image]="'url(' + product.main_image.medium_url + ')'"
		[style.background-position]="'center'"
		[style.background-repeat]="'no-repeat'"
		[style.background-size]="product_display">
		<div 	style="position:absolute;top:0px;right:0px;z-index:490"
				*ngIf="product.in_promo">
			<img class="promo" src='./assets/promo/promo.png'>
		</div>
	</div>
</ng-template>

<ng-container *ngIf="template == 'template1'">

<ng-container *ngIf="taxon.depth==0">
</ng-container>

<div class="taxonIcon" *ngIf="taxon.depth > 1 && !taxon.empty && products.length > 0">
	<div id="category_{{ taxon.id }}">
		
		<ng-container *ngIf="taxon.index > 0 || searchText.length > 0">
			<div class="template1 category_title py-1 px-2 mb-2"
				 [ngStyle]="{'background-color': !fol && primary_color, 'border-color': fol && '#fdd329'}">
				{{ taxon.name }}
			</div>
		</ng-container>

	</div>
</div>
	
<div class="container-fluid">
<div class="template1 row font-dosis">
	<div tabindex="0" class="col-12 col-md-6 p-md-3 product-col clickable" 
	*ngFor="let product of products; index as i"
	(click)="openProduct($event,product)">
	<div [ngClass]="[!product.is_available ? 'product unavailable d-flex justify-content-between w-100 px-0 align-items-center' : 'product d-flex justify-content-between px-0 align-items-center']">
		<div class="align-self-center marked-product-div">
			<div class="d-flex align-items-center"
			*ngIf="product.basket_count > 0">
			
			<div class="marked-product pe-1"
				 [ngStyle]="{'border-color': !fol && merchantService.merchant.primary_color}">
			</div>
			
			<div 	class="d-block d-md-none marked-product-text"
					[ngStyle]="{'color': !fol && merchantService.merchant.primary_color}"
					*ngIf="product.unit == 'piece'">
				{{ product.basket_count }}x
			</div>
		</div>
	</div>
	<div class="template1 main-image-div">

		<div *ngIf="!product.main_image" style="position:relative">
			<div style="position:absolute;top:0px;right:0px;z-index:490"
			*ngIf="product.in_promo">
			<img class="promo" src='./assets/promo/promo.png'>
		</div>
		<p 	 class="svg-icon"
			 [ngStyle]="{'fill': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '#fdd329'}"
			 [innerHTML]="icon_data">
		</p>
	</div>

	<div *ngIf="product.main_image"
		class="template1 main-image"
		[style.background-image]="'url(' + product.main_image.medium_url + ')'"
		[style.background-position]="'center'"
		[style.background-repeat]="'no-repeat'"
		[style.background-size]="product_display">
		<div 	style="position:absolute;top:0px;right:0px;z-index:490"
				*ngIf="product.in_promo">
			<img class="promo" src='./assets/promo/promo.png'>
		</div>
	</div>

</div>
<div class="product-div">
	<div class="template1 product_name">{{ product.name }}</div>
	<div class="template1 product_description">
		{{ (product.description.length > 200)? (product.description | slice:0:200) : (product.description) }}
		<p *ngIf="product.description.length > 200" class="mt-1 clickable">... {{ 'read_more' | translate }}</p>
	</div>
	<div class="d-none d-md-block">
		<div class="template1 product_price" 
		*ngIf="product.is_available"
		[ngClass]="{ 'line-through': product.in_promo }">
		{{ product.price | currency:'EUR' }}
	</div>
	<div class="template1 product_price"
	*ngIf="product.in_promo">
	{{ product.price_with_modifiers | currency:'EUR' }}
</div>
<div class="product_unavailable" *ngIf="!product.is_available">{{ 'product.out_of_stock' | translate }}</div>

</div>
</div>

<div class="d-block d-md-none" style="padding-right:10px;flex-basis:50px;text-align: right; flex-shrink:0">
	<div class="template1 product_price" 
	*ngIf="product.is_available"
	[ngClass]="{ 'line-through': product.in_promo }">
	{{ product.price | currency:'EUR' }}
</div>
<div class="template1 product_price"
*ngIf="product.in_promo">
{{ product.price_with_modifiers | currency:'EUR' }}
</div>
<div class="product_unavailable" *ngIf="!product.is_available">{{ 'product.out_of_stock' | translate }}</div>
</div>

</div>
<div>
</div>
</div>
</div>
</div>

</ng-container>

















<ng-container *ngIf="template == 'template2'">
	<ng-container *ngIf="taxon.depth > 1 && !taxon.empty && products.length > 0">

	<ng-container *ngIf="view == 'limited'">
			<div class="template2 row row-full font-dosis">
			<div 	tabindex="0" 
					class="clickable"
					[ngClass]="[view == 'full' ? 'col-12 col-sm-6' : 'col-12 col-lg-6']"
					*ngFor="let product of products; index as i"
					(click)="openProduct($event,product)">
			<div class="d-flex justify-content-between align-items-center">
				<div style="width:40px;">
					<ng-container *ngTemplateOutlet="productImage; context:{product:product, imgClass: 'main-image-small'}"></ng-container>
				</div>
				<div>
					<div class="template2 product_name pe-1">{{ product.name }}</div>
				</div>
				<div>
					<ng-container *ngTemplateOutlet="price; context:{product:product, imgClass: 'template2 product_price p-1 mt-1'}"></ng-container>
				</div>
			</div>
			</div>
			</div>
	</ng-container>

<ng-container *ngIf="view == 'full'">

<div 	class="taxonIcon" >
	<div id="category_{{ taxon.id }}">
		<div class="template2 category_title py-1 px-2 mb-2"
			 [ngStyle]="{'border-color': fol ? '#fdd329' : nil, 'border-width.px': fol ? 4 : nil}">
			{{ taxon.name | capitalizeFirst }}
		</div>
	</div>
</div>

<div class="container-fluid">
<div class="template2 row row-full g-0 mb-5 font-dosis">

	<div 	tabindex="0"
			class="clickable rounded" 
			[ngClass]="{'product-col col-12 highlight': product.template == 'highlight' && !fol, 'product-col col-12 highlight_fol': product.template == 'highlight' && fol, 'product-col py-3 col-12 col-md-6 col-lg-12 col-xl-6 p-xl-3': product.template != 'highlight'}"
			*ngFor="let product of products; index as i"
			(click)="openProduct($event,product)">

	<ng-container *ngIf="product.template == 'highlight'">
		<ng-container 
				*ngTemplateOutlet="highlight; context:{product:product}">
		</ng-container>
	</ng-container>
	
	<div 	*ngIf="!product.template || product.template == 'default'"
			[ngClass]="[!product.is_available ? 'product unavailable d-flex justify-content-between w-100 px-0 align-items-start' : 'product d-flex justify-content-between px-0 align-items-start']">
		<div class="marked-product-div">
			<div class="d-flex align-items-center"
			*ngIf="product.basket_count > 0">
			
				<div class="marked-product pe-1"
					 [ngStyle]="{'border-color': !fol && merchantService.merchant.primary_color}">
				</div>
				
				<div 	class="d-block d-md-none marked-product-text"
						[ngStyle]="{'color': !fol && merchantService.merchant.primary_color}"
						*ngIf="product.unit == 'piece'">
					{{ product.basket_count }}x
				</div>
			</div>
		</div>
		<div class="template2 main-image-div">

			<div *ngIf="!product.main_image" style="position:relative">
				<div style="position:absolute;top:0px;right:0px;z-index:490"
				*ngIf="product.in_promo">
				<img class="promo" src='./assets/promo/promo.png'>
			</div>
			<p 	 class="svg-icon"
				 [ngStyle]="{'fill': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '#fdd329' }"
				 [innerHTML]="icon_data">
			</p>
			</div>

			<!--
			<div>
				<img 	*ngIf="product.main_image"
						[defaultImage]="'/assets/logos/guide.png'" [lazyLoad]="product.main_image.medium_url">
			</div>
			-->

			<ng-container *ngTemplateOutlet="productImage; context:{product:product, imgClass: 'template2 main-image'}"></ng-container>

		</div>
		<div class="product-div d-flex flex-column justify-content-between">
			<div class="template2 product_name pe-1">{{ product.name }}</div>
			<div class="template2 product_description">
				{{ (product.description.length > 200)? (product.description | slice:0:200) : (product.description) }}
				<p *ngIf="product.description.length > 200" class="clickable">... {{ 'read_more' | translate }}</p>
			</div>
			<div class="d-none d-md-block">
				<ng-container *ngTemplateOutlet="price; context:{product:product, imgClass: 'template2 product_price p-1 mt-1'}"></ng-container>
			</div>
		</div>

		<div class="d-block d-md-none" style="padding-right:10px;margin-top: -5px; text-align: right; flex-shrink:0">
			<ng-container *ngTemplateOutlet="price; context:{product:product, imgClass: 'template2 product_price p-1 mt-1'}"></ng-container>
		</div>

	</div>

<div>
</div>
</div>
</div>
</div>
</ng-container>

</ng-container>

</ng-container>






















<ng-container *ngIf="template == 'template_kiosk'">
	<ng-container *ngIf="taxon.depth > 1 && !taxon.empty && products.length > 0">

<ng-container *ngIf="view == 'full'">

<div 	class="taxonIcon" >
	<div id="category_{{ taxon.id }}">
		<div class="template_kiosk category_title py-1 px-2 mb-2"
			 [ngStyle]="{'color': !fol && primary_color}">
			{{ taxon.name | capitalizeFirst }}
		</div>
	</div>
</div>

<div class="container-fluid">
<div class="template_kiosk row row-full mb-5">

	<div tabindex="0" class="col-12 col-md-6 p-xl-3 product-col py-3 clickable" 
	*ngFor="let product of products; index as i"
	(click)="openProduct($event,product)">
	<div [ngClass]="[!product.is_available ? 'product unavailable d-flex justify-content-between w-100 px-0 align-items-start' : 'product d-flex justify-content-between px-0 align-items-start']">
		<div class="marked-product-div">
			<div class="d-flex align-items-center"
			*ngIf="product.basket_count > 0">
			
			<div class="marked-product pe-1"
				 [ngStyle]="{'border-color': !fol && merchantService.merchant.primary_color}">
			</div>
			
			<div 	class="d-block d-md-none marked-product-text"
					[ngStyle]="{'color': !fol && merchantService.merchant.primary_color}"
					*ngIf="product.unit == 'piece'">
				{{ product.basket_count }}x
			</div>
		</div>
	</div>
	<div class="template_kiosk main-image-div">

		<div *ngIf="!product.main_image" style="position:relative">
			<div style="position:absolute;top:0px;right:0px;z-index:490"
			*ngIf="product.in_promo">
			<img class="promo" src='./assets/promo/promo.png'>
		</div>
		<p 	 class="svg-icon"
			 [ngStyle]="{'fill': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '#fdd329'}"
			 [innerHTML]="icon_data">
		</p>
	</div>

	<!--
	<div>
		<img 	*ngIf="product.main_image"
				[defaultImage]="'/assets/logos/guide.png'" [lazyLoad]="product.main_image.medium_url">
	</div>
	-->

	<ng-container *ngTemplateOutlet="productImage; context:{product:product, imgClass: 'template_kiosk main-image'}"></ng-container>

</div>
<div class="product-div d-flex flex-column justify-content-between font-dosis">
	<div class="template_kiosk product_name pe-1">{{ product.name }}</div>
	<div class="template_kiosk product_description">
		<div>{{ (product.description.length > 200)? (product.description | slice:0:200) : (product.description) }}</div>
		<div *ngIf="product.description.length > 200" class="clickable">... {{ 'read_more' | translate }}</div>
	</div>
	<div class="d-none d-md-block">
			<ng-container *ngTemplateOutlet="price; context:{product:product, imgClass: 'template_kiosk product_price p-1 mt-1'}"></ng-container>
	</div>
</div>

<div class="d-block d-md-none" style="padding-right:10px;margin-top: -5px; text-align: right; flex-shrink:0">
	<ng-container *ngTemplateOutlet="price; context:{product:product, imgClass: 'template_kiosk product_price p-1 mt-1'}"></ng-container>
</div>

</div>
<div>
</div>
</div>
</div>
</div>
</ng-container>

</ng-container>

</ng-container>
