/*
                    "id": 669288,
                    "option_id": 826,
                    "price_adapt": "0.0",
                    "name": "Ui",
                    "order_option_values": [
                        {
                            "id": 742768,
                            "price_adapt": "0.0",
                            "is_default": false,
                            "option_value_id": 2330,
                            "name": "Verse ui"
                        }
                    ]
 */

 import { OrderOptionValue } from '../models/order_option_value';

export class OrderOption {

    id?: number;
    option_id?: number;
    price_adapt?: number;
    name?: string;
    order_option_values?: Array<OrderOptionValue>;
    valid = false;
    part_set_id: number;
    product_id?: number;
    enabled: boolean;
    base_unit?: any;
    base_amount?: any;


    constructor(id, option_id, enabled = true, part_set_id?, product_id?) {
        this.id = id;
        this.option_id = option_id;
        this.product_id = product_id;
        this.order_option_values = [];
        this.enabled = enabled;
        this.part_set_id = part_set_id;
    }
  }
