import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { Product } from '../models/product';
import { Option } from '../models/option';
import { PartSet } from '../models/part_set';
import { UserService } from './user.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private products: { [key: number]: Product } = {};
  private options:  { [key: number]: Option } = {};
  private part_sets:  { [key: number]: PartSet } = {};
  private tempColorSubject = new BehaviorSubject<string>(null);

  constructor(
  	private apiService: ApiService,
    private userService: UserService,
    private http: HttpClient
  ) { }

  createNewProduct(product) {
    let params = { product: product }
    return this.apiService.post('api/v1/products', params, this.userService.access_token);
  }

  getTempColor(): Observable<any> {
    return this.tempColorSubject.asObservable();
  }

  setTempColor(color: string) {
    return this.tempColorSubject.next(color);
  }

  getStoredProduct(id) {
  	return this.products[id];
  }

  getProductsForMerchant(merchant_id: number) {
    const access_token = null;
    return this.apiService.get('api/v1/products?all_page=true&q[is_orderable_true]=1&q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant', access_token);
  }

  getProductsByQuery(query: string) {
    const access_token = null;
    return this.apiService.get('api/v1/products?all_page=true&q[is_orderable_true]=1&' + query, access_token);
  }

  getProducts(merchant_id: number, taxon_id: number) {
  	const access_token = null;
    return this.apiService.get('api/v1/products?all_page=true&q[is_orderable_true]=1&q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant&q[product_taxons_taxon_id_eq]=' + taxon_id, access_token);
  }

  getProductsWithPriceModifier(merchant_id: number) {
    const access_token = null;
    return this.apiService.get('api/v1/products/price_with_modifiers?&q[is_orderable_true]=1&q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant', access_token);
  }

  getProduct(id: number) {
    const access_token = null;
    return this.apiService.get('api/v1/products?q[id_eq]=' + id, access_token);
  }

  updateStoredProducts(products) {
    for (const product of products) {
      if (this.products[product.id]) {
        for (const key in product) {
          this.products[product.id][key] = product[key];
      }
      } else {
        this.products[product.id] = product;
      }
    }
  }

  updateStoredProductsOptions(options) {

  }

  updateStoredProduct(product) {
      if (this.products[product.id]) {
        for (const key in product) {
          this.products[product.id][key] = product[key];
      }
      } else {
        this.products[product.id] = product;
      }
   }

   updateProductImage(product_id, payload) {
      let api_url: string = environment.base_api_url;
      api_url = api_url.replace("prd.","prd1.");

      let headers: HttpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.userService.access_token)
      let apiEndPoint = api_url + 'api/v1/products/' + product_id + '/main_image'

      return this.http.post(`${apiEndPoint}`, payload, {headers: headers})
    }

  getOptions(merchant_id: number) {
    const access_token = null;
    return this.apiService.get('api/v1/options?q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant&q[s]=created_at desc', access_token);
  }

  getPartSets(merchant_id: number) {
    const access_token = null;
    return this.apiService.get('api/v1/part_sets?q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant&q[s]=created_at desc', access_token);
  }

  getStoredOption(id) {
    return this.options[id];
  }

  getStoredPartSet(id) {
    return this.part_sets[id];
  }

  getStoredOptions() {
    return this.options;
  }

  updateStoredOptions(options) {
    for (const option of options) {
      if (this.options[option.id]) {
        for (const key in option) {
          this.options[option.id][key] = option[key];
      }
      } else {
        this.options[option.id] = option;
      }
    }
  }

  updateStoredPartSets(part_sets) {
    for (const part_set of part_sets) {
      if (this.part_sets[part_set.id]) {
        for (const key in part_set) {
          this.part_sets[part_set.id][key] = part_set[key];
      }
      } else {
        this.part_sets[part_set.id] = part_set;
      }
    }
  }

  updateProduct(product) {
    let payload = { 
      product: product
    }

    return this.apiService.put('api/v1/products/' + product.id, payload, this.userService.access_token);
  }

  getAllergens() {
    return this.apiService.get('api/v1/allergenes', this.userService.access_token);
  }

  getBlueprints() {
    return this.apiService.get('api/v1/products?q[is_blueprint_eq]=true', this.userService.access_token);
  }

  deleteProduct(id) {
    return this.apiService.delete('api/v1/products/' + id, this.userService.access_token);
  }
}
