<div class="title">
	{{ 'timing.slot_title' | translate }}
</div>

<mat-nav-list>
  <div *ngFor="let slot of data.slots; let i = index" style="width:100%;">
  <a (click)="selectTime(slot,i)" mat-list-item *ngIf="slot.is_enabled || slot.price_reason">

  	<span mat-line *ngIf="block_display == 'BLOCK'; else time_block">
  		{{ 'timing.block' | translate: { begin: slot.begin | date:'HH:mm', end: slot.end | date:'HH:mm'} }}
    </span>
  	<ng-template #time_block>
  			<ng-container>{{ slot.value | date:'HH:mm'}}{{ 'timing.h' | translate }}</ng-container>
  	</ng-template>
  </a>
  </div>
</mat-nav-list>