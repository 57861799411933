<div class="mat-app-background darkMode">
  <h1 mat-dialog-title>{{ 'pos.cash_payment' | translate}}</h1>
  <div mat-dialog-content>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column">
        <div>{{ 'pos.rounded_price_order' | translate}}</div>
        <div class="data mt-1">{{ order.rounded_price | number:'1.2-2' | currency:'EUR' }}</div>
        <div>{{ order.price | number:'1.2-2' | currency:'EUR' }}</div>
      </div>
      <div class="d-flex flex-column align-items-end">
        <div>{{ 'pos.change' | translate}}</div>
        <div class="data mt-1">{{ change | number:'1.2-2' | currency:'EUR' }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-end my-3">
      <div class="data">
        <ng-container *ngIf="moneyReceived != '-'">
          {{ moneyReceived | number:'1.2-2' | currency:'EUR' }}
        </ng-container>
      </div>
    </div>
    <div 
        *ngIf="ongoing"
        class="d-flex flex-column align-items-center mb-4">
      <div class="row row1">
        <button (click)="clickNumber(1)" mat-flat-button>1</button>
        <button (click)="clickNumber(2)" mat-flat-button>2</button>
        <button (click)="clickNumber(3)" mat-flat-button>3</button>
      </div>
      <div class="row">
        <button (click)="clickNumber(4)" mat-flat-button>4</button>
        <button (click)="clickNumber(5)" mat-flat-button>5</button>
        <button (click)="clickNumber(6)" mat-flat-button>6</button>
      </div>
      <div class="row">
        <button (click)="clickNumber(7)" mat-flat-button>7</button>
        <button (click)="clickNumber(8)" mat-flat-button>8</button>
        <button (click)="clickNumber(9)" mat-flat-button>9</button>
      </div>
      <div class="row row4">
        <button (click)="erase()" mat-flat-button style="font-size: 2.5rem;">
          <fa-icon 
          [icon]="arrow_left">
        </fa-icon></button>
        <button (click)="clickNumber(0)" mat-flat-button>0</button>
        <button (click)="clickNumber('.')" mat-flat-button>.</button>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-center align-items-end w-100">
    <div  *ngIf="ongoing"
          class="me-3">
          <button style="font-size:1.2rem"
            mat-stroked-button
            (click)="onCancel()"
            [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"> {{ 'cancel' | translate}}
          </button>
    </div>
    <div  *ngIf="ongoing"
          class="d-flex flex-column align-items-end">
      <button *ngIf="order.price_after_coupons != 0 && order.order_type != 'refund'"
        style="font-size:1.2rem;margin-bottom:10px"
        mat-button
        (click)="onPayLater()"
        [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">{{ 'pos.pay_later' | translate}}</button>
      <button style="font-size:1.2rem; margin:0"
        mat-button
        [disabled]="!change || (order.order_type != 'refund' && change < 0) || (order.order_type == 'refund' && change > 0 )"
        (click)="onConfirmPayment()"
        [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">{{ 'pos.confirm_payment' | translate}}</button>
    </div>
     <div *ngIf="!ongoing">
          <button style="font-size:1.2rem"
            mat-stroked-button
            (click)="onOk()"
            [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"> {{ 'pos.close' | translate}}
          </button>
    </div>
  </div>
</div>