<button 
        mat-flat-button
        color="primary"
        style="color: white;"
        [ngStyle]="!fol && merchantService.merchant.primary_color && { 'background-color': merchantService.merchant.primary_color }"
        *ngIf="highlightLanguageSelection && !this.merchantService.kiosk && !this.merchantService.pos"
        [matMenuTriggerFor]="menuLanguage">
  
  <div class="d-flex flex-row">

      <fa-icon [icon]="faGlobe" class="me-2"></fa-icon>
      <div class="current-lang">{{ translate.currentLang | uppercase }}</div>
      <fa-icon  
        class="clickable" 
        [icon]="faChevronDown">
      </fa-icon>
    </div>
    <mat-menu #menuLanguage="matMenu">
      <button mat-menu-item (click)="changeLanguage('en')">English</button>
      <button mat-menu-item (click)="changeLanguage('fr')">Français</button>
      <button mat-menu-item (click)="changeLanguage('nl')">Nederlands</button>
      <button mat-menu-item (click)="changeLanguage('de')">Deutsch</button>
      <button mat-menu-item (click)="changeLanguage('it')">Italiano</button>
      <button mat-menu-item (click)="changeLanguage('es')">Español</button>
    </mat-menu>

</button>

<div class='ps-1'
     *ngIf="!highlightLanguageSelection || this.merchantService.kiosk || this.merchantService.pos">
  <div class="menu-item" id="language-button">
    <div [matMenuTriggerFor]="menuLanguage" class="d-flex flex-row clickable"
      [ngClass]="{'language-button-kiosk': this.merchantService.kiosk, 'language-button-pos': this.merchantService.pos}"
      [ngStyle]="{'background-color': this.merchantService.kiosk || this.merchantService.pos ? (merchantService.merchant && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '') : ''}">

      <fa-icon [icon]="faGlobe" class="ms-1 me-1"></fa-icon>
      <div class="current-lang">{{ translate.currentLang | uppercase }}</div>
      <fa-icon  
        [icon]="faChevronDown">
      </fa-icon>
    </div>
    <mat-menu #menuLanguage="matMenu">
      <button mat-menu-item (click)="changeLanguage('en')">English</button>
      <button mat-menu-item (click)="changeLanguage('fr')">Français</button>
      <button mat-menu-item (click)="changeLanguage('nl')">Nederlands</button>
      <button mat-menu-item (click)="changeLanguage('de')">Deutsch</button>
      <button mat-menu-item (click)="changeLanguage('it')">Italiano</button>
      <button mat-menu-item (click)="changeLanguage('es')">Español</button>
    </mat-menu>
  </div>
</div>
