<div *ngIf="navigation" class="selected-product">
  <app-location (completed)="locationCompleted()"></app-location>
</div>

<div class="faded-bg" (click)="backgroundClicked()" *ngIf="navigation" [@fadeInOut]>
</div>

<div class="navbar py-3" [ngClass]="{'default-background-color': background, 'fol-header': fol_bg}">
  <div class="container-fluid d-flex justify-content-between align-items-start">

    <div *ngIf="fol" class="dropdown-main-menu d-flex">
      <button type="button" id="menu-button" class="menu-button" (click)="openMenu()" *ngIf="menuShown">
        <fa-icon [icon]="faBars" class="fa-lg" style="color: #fdd329;"></fa-icon>
      </button>
    </div>

    <div *ngIf="!fol" class="d-flex align-items-center" [ngStyle]="{'color': frontColor }">
      <ng-container *ngIf="menuShown && (!userService.user || !userService.user.virtual_user)">
        <div class="clickable">
          <div class="clickable pe-1 d-flex align-items-center" matBadge="{{userService.warnings}}"
            [matBadgeHidden]="userService.warnings == 0" matBadgeColor="warn" (click)="openMenu()">

            <fa-icon class="clickable" [icon]="faBars">
            </fa-icon>

            <div class="menu ms-1">
              {{ 'navigation.menu' | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div style="text-align: center;">
      <img *ngIf="!fol && logoShown && logoSize == 'normal'" class="logo" src="{{ logo }}">
      <img *ngIf="!fol &&  logoShown && logoSize == 'big'" class="logo-big" src="{{ logo }}">
      <img *ngIf="fol" class="fol-logo clickable" src="{{ logo }}" (click)="navigateToMainPage()">
    </div>

    <div class="d-flex align-items-center justify-content-end" style="text-align:right;"
      [ngStyle]="{'color': fol ? '' : frontColor }">
      <ng-container *ngIf="!fol">

        <div class="ps-1" (click)="openNavigation()" *ngIf="locationShown">
          <ng-container *ngIf="city">{{ (city.length > 6 && this.appHelper.isMobile())? (city | slice:0:6)+'..':(city)
            }}</ng-container>

          <fa-icon *ngIf="!city" class="ps-1 clickable" [icon]="faMapMarkedAlt">
          </fa-icon>
          <fa-icon class="ps-1 clickable" [icon]="faChevronDown">
          </fa-icon>

        </div>

      </ng-container>
      <app-language-selection [highlightLanguageSelection]="highlightLanguageSelection"
        class="ms-1"></app-language-selection>

    </div>

  </div>
</div>