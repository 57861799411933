import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

import { UserService} from '../services/user.service';
import { MerchantService } from '../services/merchant.service';
import { OrderService} from '../services/order.service';

import { MatSelectionList, MatListOption } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';

import { AppHelper } from '../helpers/app-helper';

import { ScenarioSheetComponent } from '../sheets/scenario-sheet/scenario-sheet.component';
import { CateringLocationSheetComponent } from '../sheets/catering-location-sheet/catering-location-sheet.component';
import { LocalLocationSheetComponent } from '../sheets/local-location-sheet/local-location-sheet.component';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { environment } from '../../environments/environment';

import { faEdit, faCaretDown, faShop, faShopLock } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss'],
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(350)),
    ])
  ]
})
export class ScenarioComponent implements OnInit, OnDestroy {

  @Input() condensed = false;
  @Output() emitUpdate = new EventEmitter<boolean>();

  faEdit = faEdit;
  faCaretDown = faCaretDown;
  faShop = faShop;
  faShopLock = faShopLock;

  public scenarios = [];
  public scenariosForSheet = [];

  //public currentScenario;
  public navigation = false;
  public whitelabel = environment.whitelabel;
  public whitelabel_for_icons = environment.whitelabel;

  public validDeliveryAddressSubscription: any;

  public counters;
  public rooms;
  public tables;
  public takeaway_locations;

  public template = "template_default";
  public fol = false;

  constructor(	public userService: UserService,
  				public merchantService: MerchantService,
  				public orderService: OrderService,
          private appHelper: AppHelper,
  				private bottomSheet: MatBottomSheet,
          private cd: ChangeDetectorRef,
  				private router: Router) { 

    this.fol = this.appHelper.isFol();
    
  }

  openScenarioSheet(): void {

    const sheetRef = this.bottomSheet.open(ScenarioSheetComponent, { data: { scenarios: this.scenariosForSheet, compareWith: this.userService.basket.order.scenario }, });
    const scenarioSubscription = sheetRef.instance.scenarioSelected.subscribe((scenario) => {
  		this.selectScenario(scenario);
  		sheetRef.dismiss();
	});
	sheetRef.afterDismissed().subscribe(() => {
  		scenarioSubscription.unsubscribe();
	});
  }

  openCateringLocationSheet(): void {

    const cateringLocations = this.userService.basket.checkoutFields.location;

    const sheetRef = this.bottomSheet.open(CateringLocationSheetComponent, { data: { cateringLocations: cateringLocations, compareWith: this.userService.basket.order.location }, });
    const cateringLocationSubscription = sheetRef.instance.cateringLocationSelected.subscribe((cateringLocation) => {
      this.selectCateringLocation(cateringLocation);
      sheetRef.dismiss();
  });
  sheetRef.afterDismissed().subscribe(() => {
      cateringLocationSubscription.unsubscribe();
  });
  }

  openLocalLocationSheet(): void {

    if (this.takeaway_locations.length > 1) {
      const sheetRef = this.bottomSheet.open(LocalLocationSheetComponent, { data: { localLocations: this.takeaway_locations, compareWith: this.userService.basket.order.dist_location_id }, });
      const localLocationSubscription = sheetRef.instance.localLocationSelected.subscribe((localLocation) => {
        this.selectLocalLocation(localLocation);
        sheetRef.dismiss();
      });
      sheetRef.afterDismissed().subscribe(() => {
        localLocationSubscription.unsubscribe();
      });
    }

  }

  openRoomserviceLocationSheet(): void {

    if (this.rooms.length > 1) {
      const sheetRef = this.bottomSheet.open(LocalLocationSheetComponent, { data: { localLocations: this.rooms, compareWith: this.userService.basket.order.dist_location_id }, });
      const localLocationSubscription = sheetRef.instance.localLocationSelected.subscribe((localLocation) => {
        this.selectRoomserviceLocation(localLocation);
        sheetRef.dismiss();
      });
      sheetRef.afterDismissed().subscribe(() => {
        localLocationSubscription.unsubscribe();
      });
    }

  }

  openWaiterLocationSheet(): void {

     this.merchantService.getLocalLocations().subscribe(
        response => {

          this.appHelper.getLocalLocations(response);

          this.initLocalLocations();
                    
          if (this.tables.length > 0) {
            const sheetRef = this.bottomSheet.open(LocalLocationSheetComponent, { data: { localLocations: this.tables, compareWith: this.userService.basket.order.dist_location_id }, });
            const localLocationSubscription = sheetRef.instance.localLocationSelected.subscribe((localLocation) => {
              this.selectWaiterLocation(localLocation);
              sheetRef.dismiss();
            });
            sheetRef.afterDismissed().subscribe(() => {
              localLocationSubscription.unsubscribe();
            });
          }

        });

  }

  initLocalLocations() {

     if (this.merchantService.merchant.local_locations) {
      this.counters = this.merchantService.merchant.local_locations.filter(location => location.typecode == 'counter');
      this.rooms = this.merchantService.merchant.local_locations.filter(location => location.typecode == 'room');
      this.tables = this.merchantService.merchant.local_locations.filter(location => location.typecode == 'table');
      this.takeaway_locations = this.merchantService.merchant.local_locations.filter(location => location.is_shown_to_endusers && (location.typecode == 'counter' || location.typecode == 'vending_machine'));
     }

  }

  ngOnInit() {

    if (!this.appHelper.doesFileExist(`assets/scenarios/${this.whitelabel}/takeaway.png`)) { this.whitelabel_for_icons = "guide"; };

    if (this.merchantService.pos) { this.template = 'template_pos' };
    if (this.fol) { this.template = 'template_fol' };

    //is user a waiter?
    let employee_role = this.userService.user.roles.filter(role => { return role.typecode == 'merchant_employee' && role.merchant_id ==  this.userService.basket.order.merchant_id } );  

    for (const scenario of this.userService.basket.checkoutFields.scenario) {
      if (scenario.is_supported) { this.scenarios.push(scenario.value); }
      if (scenario.is_supported && ( (this.merchantService.pos == true && scenario.value == 'table') || 
                                     (scenario.value != 'table' && scenario.value != 'waiter')
                                   )
         ) { this.scenariosForSheet.push(scenario); }
      if (scenario.is_supported && scenario.value == 'waiter' && employee_role && employee_role.length > 0) { this.scenariosForSheet.push(scenario); }
    }

    this.validDeliveryAddressSubscription = this.userService.validDeliveryAddressEmitter.subscribe((state) => {
      this.cd.detectChanges();
    });

    this.initLocalLocations();
   
    
  }

  ngOnDestroy() {
    if (this.userService.basket && 
        this.userService.basket.order.scenario == 'delivery' && 
        this.userService.basket.validDeliveryAddress == -1) {
      this.appHelper.prepareScenario();
    }

    if (this.validDeliveryAddressSubscription) this.validDeliveryAddressSubscription.unsubscribe();
  
  }

  selectCateringLocation(cateringLocation) {
    this.userService.basket.order.location = cateringLocation.value;
    this.userService.basket.order.dist_location.address.location = cateringLocation.value;
    this.emitToParent();
  }

  selectLocalLocation(localLocation) {
    this.userService.basket.order.dist_location = null;
    this.userService.basket.order.dist_location_name = localLocation.name;
    this.userService.basket.order.dist_location_id = localLocation.id;
    this.userService.basket.order.dist_location_typecode = localLocation.typecode;
    this.emitToParent();
  }

  selectRoomserviceLocation(localLocation) {
    this.userService.basket.order.dist_location = null;
    this.userService.basket.order.dist_location_name = localLocation.name;
    this.userService.basket.order.dist_location_id = localLocation.id;
    this.userService.basket.order.dist_location_typecode = localLocation.typecode;
    this.userService.basket.order.order_location_name = localLocation.name;
    this.userService.basket.order.order_location_id = localLocation.id;
    this.userService.basket.order.order_location_typecode = localLocation.typecode;
    this.userService.basket.order.consumption_area = "local";
    this.emitToParent();
  }

  selectWaiterLocation(localLocation) {
    this.userService.basket.order.dist_location = null;
    this.userService.basket.order.dist_location_name = localLocation.name;
    this.userService.basket.order.dist_location_id = localLocation.id;
    this.userService.basket.order.dist_location_typecode = localLocation.typecode;
    
    //this needs fix for pos
    if (this.userService.basket.order.device_type !== 'POS') {
      this.userService.basket.order.order_location_name = localLocation.name;
      this.userService.basket.order.order_location_id = localLocation.id;
      this.userService.basket.order.order_location_typecode = localLocation.typecode;
      this.userService.basket.order.consumption_area = "local";
      this.userService.local_location = localLocation;
    }
    
    this.emitToParent();
  }

  selectScenario(scenario) {

    this.userService.removeDistLocation();

    //this.currentScenario = scenario;
    this.userService.scenario = scenario.value;
    //if (this.userService.basket.order.dist_timing == 'timed') this.userService.basket.order.dist_timing_target = null;
    this.userService.basket.order.scenario = scenario.value;

    this.appHelper.prepareScenario();

    if (this.userService.basket.order.scenario == 'takeaway' && this.userService.basket.order.dist_location_id == undefined) { 
        this.userService.basket.order.dist_location_id = this.merchantService.merchant.local_locations[0].id;
        this.userService.basket.order.dist_location_name = this.merchantService.merchant.local_locations[0].name;
        this.userService.basket.order.dist_location_typecode = this.merchantService.merchant.local_locations[0].typecode;
    } else if (this.userService.basket.order.scenario == 'catering' && this.userService.basket.order.location == undefined) { 
      this.openCateringLocationSheet(); 
    }

    this.emitToParent();
  }

  emitToParent() {
    this.emitUpdate.emit(true);
   }

  openLocation() {
     this.router.navigate(['/location']);
  }

  updateDeliveryAddress() {
    this.navigation = false;
    if (this.userService.basket) { this.userService.setValidDeliveryAddress(-1); }
    this.appHelper.prepareScenario();
  }

  scenarioEnabled() {
    if (this.userService.basket && this.userService.basket.checkoutFields) { 
      for (const scenario of this.userService.basket.checkoutFields.scenario) {
        if (this.userService.basket.order.scenario == scenario.value) {
           return scenario.is_enabled;
       }
      }
    }

    return false;
  
  }

  adaptAddress() {
      
    let address_submit = JSON.parse(JSON.stringify(this.userService.address));
    address_submit.id = null;
    address_submit.line1 += ' ' + address_submit.street_number;

    this.userService.basket.order.dist_location = { address: address_submit };
    this.userService.basket.order.dist_location_name = this.appHelper.getAddressFormatted(this.userService.address);
    this.userService.basket.order.dist_location_typecode = 'remote';
  }

  selectNavigation() {
    if (this.userService.basket.order.order_type == 'personal') { this.navigation = true; }
  }
}
