import { OrderPartAdd } from '../models/order_part_add';

export class OrderPartSet {

    id?: number;
    part_set_id?: number;
    price_adapt?: number;
    name?: string;
    order_part_adds?: Array<OrderPartAdd>;
    valid = false;

    constructor(id, part_set_id) {
        this.id = id;
        this.part_set_id = part_set_id;
        this.order_part_adds = [];
    }
  }
