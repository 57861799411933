import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UtilityService {

  constructor(
    private snackBar: MatSnackBar
  ) {}

  openSnackBar(message: string, action: string, duration?: number) {
  	let options = {};
    if (!duration) { duration = 3000; };
    if (duration > 0) options = { duration: duration };
    this.snackBar.open(message, action, options);
  }

}
