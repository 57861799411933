<div class="subtitle"
    [ngClass]="{'clickable': !this.merchantService.kiosk}"
    (click)="!this.merchantService.kiosk ? openPaymentMethodSheet() : '' "
    *ngIf="payment_methods.length > 1 &&  template != 'template_pos'">

  {{ 'payment_method.title' | translate }}
</div>

<div class="subtitle" 
    [ngClass]="{'clickable': !this.merchantService.kiosk}"
    (click)="!this.merchantService.kiosk ? openPaymentMethodSheet() : '' "
    *ngIf="payment_methods.length <= 1 && template != 'template_pos'">

  {{ 'order.payment_method' | translate | titlecase }}
</div>


<div  class="{{ template }} d-flex align-items-center clickable no-selection font-dosis" 
      *ngIf="!this.merchantService.kiosk">
	
	<div style="width:120px;text-align: center"
       (click)="openPaymentMethodSheet()"
       *ngIf="template != 'template_pos'">
    	
      <img *ngIf="userService.basket.order.payment_method && userService.basket.order.payment_method !== 'WALLET'"
    		 class="image" 
    		 src='assets/payment_methods/{{ userService.basket.order.payment_method }}.png'/>

      <fa-icon 
          *ngIf="userService.basket.order.payment_method === 'WALLET'"
          class="pe-1"
          size="2x"
          [icon]="faWallet">
      </fa-icon>

  	</div>

    <div  style="display:inline-flex;"
      [ngStyle]="{'font-size': font_size + 'px'}"
      *ngIf="template == 'template_pos'">
  
          <div 
              class="d-flex w-100"
              *ngFor="let payment_method of payment_methods">

            <div  class="clickable d-flex flex-column justify-content-center align-items-center p-2 m-1 w-100" 
              style="min-height: 60px; border-radius: 10px; background-color: darkgray; color: black; text-align: center;"
              [ngStyle]="{'color': userService.basket.order.payment_method == payment_method.value ? 'white' : 'black', 'background-color': userService.basket.order.payment_method !== 'CASH' && userService.basket.order.payment_method == payment_method.value ? merchantService.merchant.primary_color : (userService.basket.order.payment_method == 'CASH' && userService.basket.order.payment_method == payment_method.value ? 'green' : 'darkgrey')}"
              (click)="selectPaymentMethod(payment_method)">
             <div>
                <!--
                <img style="max-width: 50px; max-height: 50px;" src='./assets/payment_methods/{{ payment_method.value }}.png'/>
                -->
                 <fa-icon *ngIf="payment_method.value == 'INVOICE'"
                      class="pb-1"
                      size="1x"
                      [icon]="faFileLines">
                  </fa-icon> 
                 <fa-icon 
                    *ngIf="payment_method.value == 'WALLET'"
                    class="pb-1"
                    size="1x"
                    [icon]="faWallet">
                </fa-icon>  
                <fa-icon 
                    *ngIf="payment_method.value == 'CASH'"
                    class="pb-1"
                    size="1x"
                    [icon]="faCoins">
                </fa-icon>  
             </div>
             <div style="font-size:0.8em; line-height:1.0;">
               {{ payment_method.description }}
             </div>
            </div>

          </div>

  </div>

	<div   *ngIf="!merchantService.pos"
         class="display-inline" 
         style="flex-grow:1;"
         (click)="openPaymentMethodSheet()">
	
  	<ng-container *ngIf="userService.basket.order.payment_method">
      <strong>{{ payment_method.description }}</strong>
		  
      <div class="ms-2"
           style="display:inline">
        <i>
          <fa-icon 
                class="ps-1 secondary_low"
                [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
                [icon]="faEdit">
            </fa-icon>
          <span   class="secondary_low bold"
              [ngStyle]="{'color': !fol &&  merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}">
            {{ 'payment_method.edit' | translate }}
          </span>
        </i>
      </div>

      <div *ngIf="userService.basket.order.payment_method == 'MONIZZEMEAL' && monizze_balance">
        De balans van je Monizze maaltijdchequekaart bedraagt {{ monizze_balance / 100 | currency:'EUR' }}
      </div>

       <!-- <div class="mb-3"
          *ngIf="userService.basket.order.payment_method == 'WALLET' && user_loyalty_point">
            <loyalty-card [flipped]="false" [user_loyalty_point]="user_loyalty_point" [large_screen]="true"></loyalty-card>
       </div> -->

    </ng-container>

    <ng-container *ngIf="!userService.basket.order.payment_method">
      <i class="ps-2">{{ 'payment_method.title' | translate }}</i>
    </ng-container>
	
  </div>

</div>

<div class="d-flex flex-row justify-content-around font-dosis" *ngIf="this.merchantService.kiosk">
  <div class="d-flex flex-column" *ngFor="let payment_method of payment_methods; let i = index">
    
    <ng-container *ngIf="payment_method.is_supported && payment_method.is_enabled">
      <div class="payment-method"
				 [ngStyle]="{'border-color': payment_method.value == userService.basket.order.payment_method ? primary_color : 'white'}">			 
        <a (click)="selectPaymentMethod(payment_method)">
          <div class="d-flex flex-column" style="text-align:center">
            <div style="height:80px;margin-bottom: 5px;" class="d-flex align-items-center justify-content-center">
              <img class="payment-method_img" src='./assets/payment_methods/{{ payment_method.value }}.png'>
            </div>
            <div [ngClass]="{ 'text-highlight': payment_method.value == userService.basket.order.payment_method}">
            {{ payment_method.description | translate }}
            </div>
          </div>
        </a>
      </div>
    </ng-container>

  </div>
  
</div>