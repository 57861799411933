<mat-nav-list>
  <div class="location d-flex" *ngFor="let cateringLocation of data.cateringLocations; let i = index">
  	<div class="marker" *ngIf="cateringLocation.value == data.compareWith">
  	</div>
  	<div style="width:100%;">
  		<a (click)="selectCateringLocation(cateringLocation)" mat-list-item>
    	<span mat-line>
        <div class="d-flex">
          <div style="width:50px;text-align:center">
            <img class="location_img" src='./assets/scenarios/gray/catering.png'>
          </div>
          <div>{{ cateringLocation.value }}</div>
        </div>
      </span>
  		</a>
  	</div>
  </div>
  
</mat-nav-list>