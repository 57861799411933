import { Component, OnInit, Inject } from '@angular/core';

import { Merchant } from '../models/merchant';
import { MerchantService } from '../services/merchant.service';
import { UserService} from '../services/user.service';

import { PubnubService } from '../services/pubnub.service';
import { ChangeDetectorRef } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import {environment} from '../../environments/environment';
import { Router } from '@angular/router';

import { AppHelper } from '../helpers/app-helper';

import { faHome, faComments, faUser, faSignIn, faSignOut } from '@fortawesome/pro-regular-svg-icons';


@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

	public faHome = faHome;
	public faComments = faComments;
	public faUser = faUser;
	public faSignIn = faSignIn;
	public faSignOut = faSignOut;

	public background_image: string;
	public logo: string;
	public fol = false

	public background_repeat = environment.background_repeat;
  	public background_size = environment.background_size;

	constructor(
		public merchantService: MerchantService,
		public userService: UserService,
		private pubnubService: PubnubService,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogRef: MatDialogRef<NavigationComponent>,
		private router: Router,
		private appHelper: AppHelper,
		private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) data
        ) {}

	ngOnInit() {
		this.background_image = 'assets/backgrounds/' + environment.whitelabel + '.jpg';
		this.logo = 'assets/logos/' + environment.whitelabel + '.png';
		this.fol = this.appHelper.isFol();	
	}

	ngOnDestroy() {
	}

	closeMenu() {
        this.dialogRef.close();
    }

    logoutUser() {
    	this.userService.logoutUser();
		this.closeMenu();
		this.router.navigate(['/']);
	}

	changeLanguage(lang) {
		this.translate.use(lang);
		this.userService.changeUserLanguage(lang);
	}

	openPage(page) {
		console.log("Open page...");
		console.log(page);
	}

}
