<div class="d-flex justify-content-between {{ template }}_title pt-3 pb-2">
  <div class="title-name">
    {{ part_set.name }}
    <ng-container *ngIf="part_set.price_adapt_level === 'forfait' && part_set.price_adapt_abs">
      (+{{ part_set.price_adapt_abs | currency:'EUR'}})
    </ng-container>
    <ng-container *ngIf="part_set.price_adapt_level === 'forfait' && part_set.price_adapt_method === 'rel'">
      (+{{ product_price * part_set.price_adapt_rel | currency:'EUR'}})
    </ng-container>
  </div>
  <div *ngIf="part_set.min_select == 1 && part_set.max_select == 1; else multipleOptionsTitle"
        style="text-align: right">
    <mat-error *ngIf="!order_part_set.valid">{{ 'option.radio_title' | translate }}</mat-error>
    <ng-container *ngIf="order_part_set.valid">{{ 'option.radio_title' | translate }}</ng-container>
  </div>
  <ng-template #multipleOptionsTitle>
    <div style="text-align: right">
      <mat-error *ngIf="!order_part_set.valid">
        {{ 'option.checkbox_title_begin' | translate }}{{ part_set.min_select }} {{ 'option.checkbox_title_mid' | translate }} {{ part_set.max_select }} {{ 'option.checkbox_title_end' | translate }}
      </mat-error>
      <ng-container *ngIf="order_part_set.valid && part_set.min_select > 0 && part_set.max_select > 0">
        {{ 'option.checkbox_title_begin' | translate }}{{ part_set.min_select }} {{ 'option.checkbox_title_mid' | translate }} {{ part_set.max_select }} {{ 'option.checkbox_title_end' | translate }}
      </ng-container>
    </div>
  </ng-template>
</div>

<ng-container *ngIf="with_image else without_image">
  

  <!-- 1 option max -->
  <div *ngIf="part_set.min_select == 1 && part_set.max_select == 1; else multiplepart_sets" class="with_image">
    <div style="padding-top:8px" class="container">
      <mat-radio-group class="row">
        <div class="col-6 mb-3 radio-button-part-set"
              *ngFor="let part_set_value of part_set.products; let i = index" 
              (click)="switchpart_setValue(part_set_value)">
          <mat-radio-button
              matRipple
              [matRippleColor]="userService.darkMode ? '#535252' : '#E0E0E0'"
              [matRippleAnimation]="{enterDuration: 200, exitDuration: 50}"
              class="{{ template }} {{template}}_mat-list-option part-set-option d-flex justify-content-center invisible-checkbox"
              [ngStyle]="{'border-color': selected_part_adds_ids.includes(part_set_value.id) ? primary_color : '#D3D3D3'}"
              [disabled]="!part_set_value.is_available">
            <fa-icon [icon]="faCircleCheck"
              style="position:absolute;top:10px;right:10px;"
              class="fa-2x"
              [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
              *ngIf="selected_part_adds_ids.includes(part_set_value.id)"></fa-icon>

              <div class="d-flex" [ngClass]="{'w-100 justify-content-between': merchantService.pos, 'flex-column align-items-center': merchantService.kiosk}"
                  style="margin-right: 20px;">

              <div  class="text-center d-flex align-content-center" style="flex-wrap:wrap;">
                <img class="pe-2" 
                    *ngIf="part_set_value.main_image?.medium_url"
                    src="{{part_set_value.main_image.medium_url }}" 
                    [ngStyle]="{'max-height': merchantService.pos ? '60px' : '120px'}"
                    style="border-radius: 5px;width:auto;max-width:260px;">
              </div>

              <div class="d-flex flex-column justify-content-center" [ngClass]="{'align-items-end': merchantService.pos}">

                <div class="d-flex flex-row flex-wrap align-items-center option-info"
                    style="white-space: normal; line-height: normal;height:fit-content"
                    [ngClass]="{'justify-content-end': merchantService.pos, 'mt-2 justify-content-center': merchantService.kiosk}">
                  <div [ngStyle]="{'text-align': merchantService.pos ? 'right' : merchantService.kiosk ? 'center' : ''}">{{ part_set_value.name }}</div>
                  <div class="ms-2" *ngIf="part_set_value.price_adapt_method == 'abs' && part_set_value.price_adapt_abs > 0">
                    (+{{ part_set_value.price_adapt_abs | currency:'EUR' }})
                  </div>
                  <div class="ms-2" *ngIf="part_set.price_adapt_level == 'value' && part_set_value.price_with_modifiers > 0">
                    (+{{ part_set_value.price_with_modifiers | currency:'EUR' }})
                  </div>
                </div>
                <div *ngIf="!part_set_value.is_available" class="d-flex justify-content-center">({{ 'product.out_of_stock' | translate }})</div>
              </div>
            </div>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </div>
  
  <!-- multiple selections possible -->
  <ng-template #multiplepart_sets>
    <mat-selection-list #part_set_values class="container">
      <div class="row">
        <div class="col-6 mb-3" *ngFor="let part_set_value of part_set.products">
          <mat-list-option
                    class="{{ template }} {{template}}_mat-list-option part-set-option invisible-checkbox"
                    [ngStyle]="{'border-color': selected_part_adds_ids.includes(part_set_value.id) ? primary_color : '#D3D3D3'}"
                    [value]="part_set_value.id"
                    [disabled]="!part_set_value.is_available"
                    [hideSingleSelectionIndicator]="true"
                    (click)="togglepart_setValue(part_set_value)">
            <fa-icon [icon]="faCircleCheck"
                  style="position:absolute;top:10px;right:10px;"
                  class="fa-2x"
                  [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
                  *ngIf="selected_part_adds_ids.includes(part_set_value.id)"></fa-icon>
            
            <div  class="d-flex" [ngClass]="{'w-100 justify-content-between': merchantService.pos, 'flex-column align-items-center': merchantService.kiosk}">

            <div class="text-center d-flex align-content-center" style="flex-wrap:wrap;">
              <img  class="pe-2"
                    *ngIf="part_set_value.main_image?.small_url"
                    src="{{part_set_value.main_image.small_url }}" 
                  [ngStyle]="{'max-height': merchantService.pos ? '60px' : '120px'}"
                    style="border-radius: 5px;width:auto;max-width:260px;">
            </div>
            
            <div class="d-flex flex-column justify-content-center" [ngClass]="{'align-items-end': merchantService.pos}">
              
              <div class="d-flex flex-row flex-wrap align-items-center option-info" 
                  style="white-space: normal; line-height: normal;height:fit-content"
                  [ngClass]="{'justify-content-end': merchantService.pos, 'mt-2 justify-content-center': merchantService.kiosk}">
                <div [ngStyle]="{'text-align': merchantService.pos ? 'right' : merchantService.kiosk ? 'center' : ''}">{{ part_set_value.name }}</div>
                <div class="ms-2" *ngIf="part_set_value.price_adapt_method == 'abs' && part_set_value.price_adapt_abs > 0">
                  (+{{ part_set_value.price_adapt_abs | currency:'EUR' }})
                </div>
                <div class="ms-2" *ngIf="part_set.price_adapt_level == 'value' && part_set_value.price_with_modifiers > 0">
                  (+{{ part_set_value.price_with_modifiers | currency:'EUR' }})
                </div>
                
              </div>
              <div *ngIf="!part_set_value.is_available" class="d-flex justify-content-center">({{ 'product.out_of_stock' | translate }})</div>

            </div>

            </div>


          </mat-list-option>
        </div>
      </div>
    </mat-selection-list>
  </ng-template>
</ng-container>

<ng-template #without_image>
  <div *ngIf="part_set.min_select == 1 && part_set.max_select == 1; else multiplepart_sets">
    <div style="padding-top:8px">
      <mat-radio-group>
        <mat-radio-button *ngFor="let part_set_value of part_set.products; let i = index" 
            class="{{ template }} font-dosis"
            style="height:30px;"
            [value]="part_set_value.id" 
            [checked]="part_set_value.is_default"
            [disabled]="!part_set_value.is_available"
            (change)="switchpart_setValue(part_set_value)">
            {{ part_set_value.name }}
            <ng-container *ngIf="!part_set_value.is_available">({{ 'product.out_of_stock' | translate }})</ng-container>
            <ng-container *ngIf="part_set_value.price_adapt_method == 'abs' && part_set_value.price_adapt_abs > 0">
              (+{{ part_set_value.price_adapt_abs | currency:'EUR' }})
            </ng-container>
            <ng-container *ngIf="part_set.price_adapt_level == 'value' && part_set_value.price_with_modifiers > 0">
              (+{{ part_set_value.price_with_modifiers | currency:'EUR' }})
            </ng-container>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <ng-template #multiplepart_sets>
    <mat-selection-list #part_set_values>
      <mat-list-option 
                class="{{ template }} mat-list-option font-dosis"
                style="height:30px !important;"
                *ngFor="let part_set_value of part_set.products"
                [value]="part_set_value.id"
                [disabled]="!part_set_value.is_available"
                [checkboxPosition]="'before'"
                (click)="togglepart_setValue(part_set_value)">
        {{ part_set_value.name }}
        <ng-container *ngIf="!part_set_value.is_available">({{ 'product.out_of_stock' | translate }})</ng-container>
        <ng-container *ngIf="part_set_value.price_adapt_method == 'abs' && part_set_value.price_adapt_abs > 0">
              (+{{ part_set_value.price_adapt_abs | currency:'EUR' }})
            </ng-container>
        <ng-container *ngIf="part_set.price_adapt_level == 'value' && part_set_value.price_with_modifiers > 0">
              (+{{ part_set_value.price_with_modifiers | currency:'EUR' }})
            </ng-container>
      </mat-list-option>
    </mat-selection-list>
  </ng-template>
</ng-template>