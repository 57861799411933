export class OrderPart {

    id?: number;
    name?: string;
    price_adapt = 0.0;
    product_id?: number;
    type?: string;

    constructor(product_id, type) {
        this.product_id = product_id;
        this.type = type;
    }
  }
