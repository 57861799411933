import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-timing-sheet',
  templateUrl: './timing-sheet.component.html',
  styleUrls: ['./timing-sheet.component.scss']
})
export class TimingSheetComponent implements OnInit {

  public timingSelected = new EventEmitter();
 
  constructor(	public userService: UserService,
  				@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
  }

  selectTiming(timing) {
  	this.timingSelected.emit(timing);
  }

}
