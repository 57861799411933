import { Order } from '../models/order';

export class Basket {

	order: Order;
	checkoutFields?: any;
	advisories?: any;
	recommendations?: any;
	updateOngoing: boolean;
	paymentOngoing: boolean = false;
	orderingPossible: boolean;
	validDeliveryAddress: number;
	coupons: Array<any>;
	age = 0;

	constructor(merchant_id: number, scenario?: string) {
		this.order = new Order(merchant_id);
		if (scenario) { this.order.scenario = scenario; }
		this.updateOngoing = false;
		this.validDeliveryAddress = -1;
		this.coupons = new Array();
	}

}
