/* src/app/pos/components/pos-cash-dialog/pos-cash-dialog.component.scss */
.mat-dialog-container {
  padding: 20px !important;
  min-width: 400px;
  border-radius: 15px;
}
.mat-dialog-actions {
  margin-bottom: -14px !important;
}
.mat-mdc-dialog-title,
.mat-mdc-dialog-content,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button {
  color: #ffffff !important;
}
.row {
  display: flex;
  flex-direction: row;
}
.row > button {
  width: 120px;
  height: 80px;
  font-size: 3rem;
  background-color: rgb(111, 111, 111);
  border: solid 0.5px black;
}
button {
  color: white;
}
.row1 :first-child {
  border-top-left-radius: 20px;
}
.row1 :last-child {
  border-top-right-radius: 20px;
}
.row4 :first-child {
  border-bottom-left-radius: 20px;
}
.row4 :last-child {
  border-bottom-right-radius: 20px;
}
.data {
  font-size: 2em;
}
/*# sourceMappingURL=pos-cash-dialog.component.css.map */
