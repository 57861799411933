<mat-nav-list>
  <div class="scenario d-flex" *ngFor="let scenario of data.scenarios; let i = index">
  	<div class="marker" *ngIf="scenario.value == data.compareWith; else noMarker">
    </div>
    <ng-template #noMarker>
      <div class="noMarker"></div>
    </ng-template>
  	<div style="width:100%;">
  		<a (click)="selectScenario(scenario)" mat-list-item>
    	<span mat-line>
        <div class="d-flex">
          <div style="width:50px;text-align:center">
            <img class="scenario_img" src='./assets/scenarios/{{ whitelabel }}/inverted/{{ scenario.value }}.png'>
          </div>
          <div>{{ 'scenario.' + scenario.value | translate | capitalizeFirst }}</div>
        </div>
      </span>
  		</a>
  	</div>
  </div>
  
</mat-nav-list>