/* src/app/location/location.component.scss */
.logo {
  max-height: 60px;
  max-width: 140px;
}
.map-wrapper {
  height: 175px;
  width: 100%;
}
google-map {
  height: 100%;
}
mat-form-field {
  width: 100%;
}
.mat-input-element:disabled {
  color: black;
}
.place {
  min-height: 60px;
  border-bottom: 1px solid darkgrey;
  padding: 10px;
}
.footer-unavailable {
  opacity: 0.5;
}
.add_street_number {
  border-radius: 5px;
  border: 1px solid darkgrey;
  padding-right: 10px;
  font-size: 0.9em;
  padding-left: 30px;
}
/*# sourceMappingURL=location.component.css.map */
