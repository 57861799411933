/* src/app/navbar/navbar.component.scss */
mat-icon {
  vertical-align: middle;
}
.img {
  height: 20px;
}
video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 150%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
ion-content {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.main {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.app-toolbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
}
.logo {
  max-height: 41px;
  max-width: 120px;
}
.logo-big {
  max-height: 41px;
  max-width: 120px;
}
.container {
  padding-top: 4px;
  padding-bottom: 4px;
}
.faded-bg {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.lang-selection {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px 20px 0 0;
  font-size: 1.4rem;
}
.fol-header {
  background:
    linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.3),
      hsla(0, 0%, 100%, 0.3)),
    url(https://www.frietjesonline.be/uploads/backgrounds/background.jpg?h=500&mode=max);
  background-size: auto, cover;
  background-repeat: repeat;
}
.menu-button {
  width: 2.5em;
  height: 2.25em;
  border: none;
  font-size: 1.5em;
  background-color: #f0513f;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.fol-logo {
  width: 270px;
  height: auto;
}
@media (max-width: 374px) {
  .logo {
    max-height: 40px;
    max-width: 120px;
  }
  .logo-big {
    max-height: 40px;
    max-width: 120px;
  }
}
@media (max-width: 767px) {
  .fol-logo {
    max-height: 100px;
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .logo-big {
    max-height: 140px;
    max-width: 220px;
  }
  .menu-big {
    font-size: 30px;
  }
  .img-big {
    height: 35px;
  }
  .lang-selection {
    font-size: 1.6rem;
  }
}
@media (min-width: 1280px) {
  .logo-big {
    max-height: 280px;
    max-width: 440px;
  }
  .menu-big {
    font-size: 30px;
  }
  .img-big {
    height: 35px;
  }
  .lang-selection {
    font-size: 1.6rem;
  }
}
/*# sourceMappingURL=navbar.component.css.map */
