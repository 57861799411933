import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { OrderService } from '../../services/order.service';

import { faUtensils } from '@fortawesome/pro-solid-svg-icons';

import { trigger, state, style, animate, transition } from '@angular/animations';

export interface Coords {
  latitude: Number;
  longitude: Number;
}

@Component({
  selector: 'delivery',
  templateUrl: './delivery-component.component.html',
  styleUrls: ['./delivery-component.component.css'],
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(350)),
    ]),
  trigger('slideUp', [
    state('void', style({
      transform: 'translateY(150%)'
    })),
    transition('void <=> *', animate('0.5s ease-in-out')),
    ])
 ] 
})
export class DeliveryComponentComponent implements OnInit, OnDestroy {

	@Input() order: any;
	@Output() delivery_unselected = new EventEmitter();
	private timer;

	faUtensils = faUtensils;

	public delivery_latitude: any;
	public delivery_longitude: any;
	public delivery_timestamp:any;
	public delivery_coords: Coords[] = [];
	private deliveryTimer;

  constructor(private orderService: OrderService,
  			  private cd: ChangeDetectorRef) { }

  ngOnInit(): void {

  	this.delivery_latitude = this.order.order_delivery.latitude;
	this.delivery_longitude = this.order.order_delivery.longitude;
	this.delivery_timestamp = Date.now();
	this.delivery_coords.push({ latitude: this.delivery_latitude, longitude: this.delivery_longitude});

  	this.repeatOrders();
  	this.shiftDeliveryCoords();
  }

  ngOnDestroy() {
  	if (this.timer) clearTimeout(this.timer);
  	if (this.deliveryTimer) clearTimeout(this.deliveryTimer);
  }

  backClicked(event) {
  	this.delivery_unselected.emit();
  	event.stopPropagation();
  }

  shiftDeliveryCoords() {
			this.deliveryTimer = setTimeout(() => {
				if (this.delivery_coords.length > 1) this.delivery_coords.shift();
				this.cd.detectChanges();
				this.shiftDeliveryCoords();
 			}, 100);
  }

  getOrderForDelivery() {
			this.orderService.getOrderV1(this.order.id).subscribe(
				response => {
					this.order = response;

					if (!this.delivery_latitude) {
						this.delivery_latitude = this.order.order_delivery.latitude;
						this.delivery_longitude = this.order.order_delivery.longitude;
						this.delivery_timestamp = Date.now();
						this.delivery_coords.push({ latitude: this.delivery_latitude, longitude: this.delivery_longitude});
					} else {

						let location2 = { latitude: this.order.order_delivery.latitude, longitude: this.order.order_delivery.longitude };
						let location1 = { latitude: this.delivery_latitude, longitude: this.delivery_longitude };

						let t1 = this.delivery_timestamp;
						let t2 = Date.now();
						let deltaLat = location2.latitude - location1.latitude;
						let deltaLon =  location2.longitude- location1.longitude;

						this.delivery_coords = [];
						let step = 1; 
						for (let t = t1; t < t2; t+= step*100) {

							// t0_1 shall run from 0.0 to (nearly) 1.0 in that loop
							let t0_1 = (t - t1) / (t2 - t1);

							let latInter = location1.latitude + deltaLat  * t0_1;
							let lonInter = location1.longitude + deltaLon  * t0_1;

							this.delivery_coords.push({ latitude: latInter, longitude: lonInter});
						}

						this.delivery_latitude = this.order.order_delivery.latitude;
						this.delivery_longitude = this.order.order_delivery.longitude;
						this.delivery_timestamp = Date.now();

					}

				},
				error => {
					console.log(error);
				});
		}

  repeatOrders() {
			this.getOrderForDelivery();

			this.timer = setTimeout(() => {
					this.repeatOrders();

	 			}, 10000);
  		}

}
