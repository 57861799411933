<div class="loyalty-card flipstyle font-dosis" 
    [class.flipped]="user_loyalty_point?.flipped" 
    style="height:250px;"
    *ngIf="large_screen || !flipped || user_loyalty_point?.flipped"
    [@fadeInOut]>

    <div    class="front loyalty-card-background-2"
            [style.background]="'url(' + background_url + ')'"
            [style.background-position]="'center'"
            [style.background-size]="'cover'">

        <div class="loyalty-card-background d-flex flex-column justify-content-between" style="height:100%;">
            
            <div class="d-flex flex-row justify-content-between p-2 shady"
                (click)="flipIt(user_loyalty_point, large_screen)">
                <div></div>
                <div><img style="max-height: 50px;max-width: 60px;" src='{{ logo }}'></div>
                <div>
                    
                    <fa-icon
                        class="pe-1" 
                        [icon]="faInfoCircle"
                        *ngIf="!user_loyalty_point?.empty">
                    </fa-icon>
                </div>
            </div>

            <div (click)="onClick()">
                <ng-container *ngIf="user_loyalty_point?.empty">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <div class="w-100" style="text-align: center;">{{ 'loyalty_points.none_saved' | translate }}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!user_loyalty_point?.empty">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex flex-row justify-content-between">
                                <div style="background-color:white; padding: 5px; border:1px solid darkgray; border-radius: 5px;margin-left: 20px;">
                                    <qrcode [qrdata]="user_loyalty_point?.qr_code" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="userService.basket && userService.basket.order && userService.basket.order.id && loyalty_points">
                            <div class="col-8">
                                <div class="plus-points d-flex justify-content-center m-auto bg_secondary"
                                    [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
                                    +{{ loyalty_points }}
                                </div>
                                <div class="text-center">
                                    <ng-container *ngIf="loyalty_points > 1; else earn_one">{{ 'loyalty_points.earn_multiple' | translate:{ loyalty_points: loyalty_points } }}</ng-container>
                                    <ng-template #earn_one>
                                    {{ 'loyalty_points.earn_one' | translate:{ loyalty_points: loyalty_points } }}
                                    </ng-template>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="d-flex flex-row justify-content-between p-2">
                    <ng-container *ngIf="!user_loyalty_point?.empty">
                        <div style="width:120px;font-size: 1.2em; font-weight:bold;">
                            <div *ngIf="user_loyalty_point?.points > 0">
                                <fa-icon
                                    class="pe-1"
                                    [icon]="faCoin">
                                </fa-icon>
                                {{ user_loyalty_point?.points}}
                            </div>
                            <div *ngIf="user_loyalty_point?.credit > 0">
                                <fa-icon
                                    class="pe-1"
                                    [icon]="faWallet">
                                </fa-icon>
                                {{ user_loyalty_point?.credit | currency:'EUR' }}
                            </div>
                        </div>
                        <div style="font-size: 1.2em;">{{ user_loyalty_point?.merchant.name }}</div>
                        <div style="width: 100px; text-align: right;">
                            <img style="border-radius: 5px;" height="35px;" src='{{ user_loyalty_point?.merchant.image.medium_url }}'>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="back loyalty-card-background-2"
        [style.background]="'url(' + background_url + ')'"
        [style.background-position]="'center'"
        [style.background-size]="'cover'"
        (click)="flipIt(user_loyalty_point, large_screen)">
        <div class="loyalty-card-background-hard p-3" style="height:100%">
            <div class="row">
                <div class="col"><strong>{{ 'loyalty_points.latest_changes' | translate }}</strong></div>
            </div>
            <div    class="row" 
                    style="font-size:1.0em;" 
                    *ngFor="let loyalty_point of user_loyalty_point?.loyalty_points; let i = index">
                
                <ng-container *ngIf="i < 9">
                    <div class="col-6"><a style="text-decoration: none" [routerLink]="'/my-orders'" [queryParams]="{ id: loyalty_point.order_id }">{{ loyalty_point.updated_at | date:'mediumDate':'':userService.user.locale}}</a></div>
                    <div class="col-3">
                    </div>
                    <div class="col-3 align-right d-flex flex-row">
                        <fa-icon
                            *ngIf="loyalty_point.status == 'earned'"
                            class="pe-1" 
                            [icon]="faPlus">
                        </fa-icon>
                        <fa-icon
                            *ngIf="loyalty_point.status == 'spent'"
                            class="pe-1" 
                            [icon]="faMinus">
                        </fa-icon>
                
                        <div class="pe-1" style="width:50px">{{ loyalty_point.amount }}</div>

                        <fa-icon
                            class="pe-1" 
                            [icon]="faCoin">
                        </fa-icon>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</div>