import { Component, Input, Output, OnInit, OnChanges, OnDestroy,EventEmitter, AfterViewInit, ElementRef, ViewChild, SimpleChanges, ChangeDetectorRef } from '@angular/core';

import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { MerchantService } from '../services/merchant.service';

import { NgxSpinnerService } from 'ngx-spinner';

import { distinctUntilChanged, debounceTime, filter, map, tap, switchMap, catchError } from 'rxjs/operators';
import { fromEvent, Subject, Observable, of } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';

import { AppHelper } from '../helpers/app-helper';

import { ProductService } from '../services/product.service';

import { environment } from '../../environments/environment';

import { faCoin,faMinusSquare,faPlusSquare, faMinus, faPlus, faMessagePlus, faEdit, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { ReferenceDialogComponent } from '../pos/components/reference-dialog/reference-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomPriceDialogComponent } from '../pos/components/custom-price-dialog/custom-price-dialog.component';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnDestroy {

  @Input() editable = true;
  @Input() shown_as_tab = false;
  @Input() showTotal = false;
  @Input() condensed = false;
  @Input() taxons_shown = true;
  @Input() prices_shown = true;
  @Input() mode = "normal";
  @Input() order: any;
  @Input() not_shown_ids = [];
  @Input() comment_editable = false;
  @Input() tab_order_line_modifiers = [];
  @Output() updated = new EventEmitter<boolean>();
  @Output() orderLineUpdated = new EventEmitter<boolean>();

  private timer;
  private tabs_timer;

  public faCoin = faCoin;
  public faMinusSquare = faMinusSquare;
  public faPlusSquare = faPlusSquare;
  public faMinus = faMinus;
  public faPlus = faPlus;
  public faMessagePlus = faMessagePlus;
  public faEdit = faEdit;
  public faXMark = faXmark;
  public template = "template_default";
  public font_size: number;

  public whitelabel = environment.whitelabel;
  public fol = false;

  constructor(
    private orderService: OrderService,
    public userService: UserService,
    public merchantService: MerchantService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private appHelper: AppHelper,
    private productService: ProductService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit() {

    if (this.merchantService.pos) { this.template = 'template_pos' };
    this.fol = this.appHelper.isFol();

    this.getTheCorrectFontSize();

    if (this.shown_as_tab) {
      this.checkForTabOrderLineModifiersAfterInit();
      this.setOrderOrderPriceModifiersToEmptyArray();
      if (this.order.order_lines) {
        for (let order_line of this.order.order_lines) {
          order_line.new_unit_price = order_line.unit_price;
          order_line.new_amount = order_line.amount;
        }
      }
    }
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.order && this.order.order_lines) { this.appHelper.formatOrderLines(this.order, this.merchantService.merchant)}
  }

  setCustomPrice(order_line) {
    let dialogRef = this.dialog.open(CustomPriceDialogComponent, { data: { order_line: order_line } });

    dialogRef.afterClosed().subscribe(
      result => {
      if (result?.custom_price) {
        order_line.unit_price = result.custom_price;
        order_line.custom_price_comment = result.comment;
        this.doUpdate();
      }
    });

    // this.cd.detectChanges();
  }

  doUpdate() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      
      this.updated.emit(false);

      //FUTURE REWORK NEEDED! INSTEAD OF EMIT and then update,
      //THE UPDATE SHOULD BE PERFORMED BY THE PARENT, NOT THE USERSERVICE!
      //if (!this.order.id || !(this.userService.basket && this.userService.basket.order) || (this.userService.basket.order.id === this.order.id)) {
        this.userService.orderUpdate.emit(false);
      //};

    }, 1000);
  }

  openReferenceDialog(order_line) {
    let dialogRef = this.dialog.open(ReferenceDialogComponent, {
      data: {
        comment: order_line.comment
      }
    });

    dialogRef.afterClosed().subscribe(
      result => {
      if (result?.comment) {
        order_line.comment = result.comment;
        this.doUpdate();
      }
    });
  }

  updateAmount(event, orderLine, positive) {

    let order_option = orderLine.order_options.find(order_option => {
       let found = false;
       for(let oov of order_option.order_option_values) {
         found = oov.unit == orderLine.order_unit;
         if (found) break;
       }
       return found;
    });

    let unit_conversion = null;
    let order_option_value = null;
    if (order_option) {
      order_option_value = order_option.order_option_values.find(oov => oov.unit == orderLine.order_unit);
      let option = this.productService.getStoredOption(order_option.option_id); 
      let option_value = option.option_values.find(option_value => option_value.unit == orderLine.order_unit);
      unit_conversion = 1.00 / option_value.unit_proportion;
    }

    let change = this.appHelper.getAlterationAmount(orderLine.order_unit, unit_conversion);
    if (!positive) change *= -1;

    if (this.mode === "refund" || orderLine.amount > 0 || (change > 0)) {
      orderLine.amount += change;
      orderLine.total_price = orderLine.unit_price * orderLine.amount;
      if (this.mode === "normal" && orderLine.amount <= 0) orderLine._destroy = true;
      else if (this.mode == "refund" && orderLine.amount >= 0) orderLine._destroy = true;
      //if (!this.merchantService.pos && orderLine.amount <= 0) orderLine._destroy = true;

      //update the order_amount
      orderLine.order_amount = orderLine.amount * unit_conversion;
      orderLine.order_amount = this.appHelper.roundAmount(orderLine.order_unit, orderLine.order_amount);
      if (order_option_value) order_option_value.amount = orderLine.order_amount;

      if (this.shown_as_tab) {
        orderLine.new_amount = orderLine.amount;
        this.onSaveTabOrderLineModifierInASecond(orderLine);
      } else {
        this.doUpdate();
      }
    }
    event.stopPropagation();
  }

  setOrderLineAmountToZero(order_line) {
    order_line.new_amount = 0;
    this.onSaveTabOrderLineModifier(order_line);
  }

  goToMenu(event) {
    this.router.navigate(['../menu'], { relativeTo: this.route });
  }

  categoryAmount(taxon_id) {
    let amount = 0;
    for (const order_line of this.order.order_lines) {
      if (taxon_id == order_line.taxon_id) { amount += order_line.amount; }
    }
    return amount;
  }

  notToBeShown(product) {

    if (product === undefined) return;

    let not_shown = true;
    for (let product_taxon of product.product_taxons) {
      let mainCategory = this.merchantService.merchant.mainCategories.find(category => category.id == product_taxon.taxon_id);
      let index = this.not_shown_ids.indexOf(product_taxon.taxon_id);
      if (mainCategory && index == -1) not_shown = false;     
    }
     return not_shown;
  }

  getTheCorrectFontSize() {
    if (this.router.url.indexOf('pos-consumer') > -1) {
      /*this.font_size = this.userService.fontSizeSecondScreen;
      if (window.addEventListener) {
        window.addEventListener("storage", this.updateFontSize, false);
      }*/
        this.font_size = 18;
        this.cd.detectChanges();
      } else {
      this.font_size = this.userService.fontSize;
      this.userService.getFontSize().subscribe(
        font_size => {
          if (font_size) { this.font_size = font_size; }
        }
      );
    }
  }

  updateFontSize = () => {
    this.font_size = JSON.parse(localStorage.getItem('fontSizeSecondScreen'))?.fontSizeSecondScreen 
    this.cd.detectChanges();
  
  }
  
  checkForTabOrderLineModifiersAfterInit() {
    if (this.tab_order_line_modifiers?.length > 0) {
      let price = 0;
      for (let order_line of this.order.order_lines) {
        let modifier = this.tab_order_line_modifiers.find(modifier => modifier.order_line_id == order_line.id);
        if (modifier) {
          order_line.original_amount = order_line.amount;          
          order_line.amount = modifier.new_amount;
          order_line.original_unit_price = order_line.unit_price;
          order_line.unit_price = modifier.unit_price;
          order_line.modified = true;
          
          price += order_line.amount * order_line.unit_price;
        } else {
          price += order_line.amount * order_line.unit_price; 
        }
      }

      this.order.price_before_modifiers = price;
      this.order.price = price;
    }
  }


  recheckForTabOrderLineModifiers() {
    if (this.tab_order_line_modifiers?.length > 0) {
      let price = 0;
      for (let order_line of this.order.order_lines) {
        let modifier = this.tab_order_line_modifiers.find(modifier => modifier.order_line_id == order_line.id);
        if (modifier) {
          order_line.amount = modifier.new_amount;
          order_line.unit_price = modifier.unit_price;
          order_line.modified = true;
          
          price += order_line.amount * order_line.unit_price;
        } else {
          price += order_line.amount * order_line.unit_price; 
        }
      }

      this.order.price_before_modifiers = price;
      this.order.price = price;
    }
  }

  onSaveTabOrderLineModifierInASecond(order_line) {
    clearTimeout(this.tabs_timer);

    this.tabs_timer = setTimeout(() => {
      this.onSaveTabOrderLineModifier(order_line);
    }, 1000);
  }

  onSaveTabOrderLineModifier(order_line) {
    if (order_line.new_unit_price || order_line.new_amount) {
      let modifier = this.tab_order_line_modifiers.find(modifier => modifier.order_line_id == order_line.id);
      if (modifier) {
        if (order_line.new_unit_price != order_line.original_unit_price || order_line.new_amount != order_line.original_amount) {
          modifier.unit_price = order_line.new_unit_price;
          modifier.new_amount = order_line.new_amount;
          this.updateTabOrderLineModifier(modifier);
        } else {
          this.deleteTabOrderLineModifier(modifier);
        }
      } else {
        if (order_line.new_unit_price != order_line.original_unit_price || order_line.new_amount != order_line.original_amount) {
          
          let modifier = {
            order_line_id: order_line.id,
            unit_price: order_line.new_unit_price,
            new_amount: order_line.amount,
            tab_id: this.order.tab_id
          }
          this.createNewTabOrderLineModifier(modifier);
        }
      }
    }
  }

  createNewTabOrderLineModifier(modifier) {
    this.merchantService.createTabOrderLineModifier(modifier).subscribe(
      data => {
        console.log(data);
        this.tab_order_line_modifiers = data.tab_order_line_modifiers;
        this.recheckForTabOrderLineModifiers();
        this.orderLineUpdated.emit()
      },
      error => {
        console.log(error);
      }
    );
  }

  updateTabOrderLineModifier(modifier) {
    this.merchantService.updateTabOrderLineModifier(modifier).subscribe(
      data => {
        console.log(data);
        this.recheckForTabOrderLineModifiers();
        this.orderLineUpdated.emit()
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteTabOrderLineModifier(modifier) {
    this.merchantService.deleteTabOrderLineModifier(modifier).subscribe(
      data => {
        console.log(data);
        this.tab_order_line_modifiers.splice(this.tab_order_line_modifiers.indexOf(modifier), 1);
        this.recheckForTabOrderLineModifiers();
        this.orderLineUpdated.emit()
      },
      error => {
        console.log(error);
        this.tab_order_line_modifiers.splice(this.tab_order_line_modifiers.indexOf(modifier), 1);
        this.recheckForTabOrderLineModifiers();
        this.orderLineUpdated.emit()
      }
    );
  }

  setOrderOrderPriceModifiersToEmptyArray() {
    this.order.order_order_price_modifiers = [];
  }

  focusOnInput(order_line) {
    order_line.edit_comment = true; 
    setTimeout(() => {
      document.getElementById(`${order_line.id}`).focus();
    }, 50);
  }

  hideInputAndSave(order_line) {
    this.spinner.show('spinner_' + order_line.id);
    order_line.edit_comment = false;
    this.doUpdate();
  }

  onEnter(order_line) {
    this.hideInputAndSave(order_line);
  }
}
