import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationStart, RouterModule } from '@angular/router';

import { MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';

import { ConfirmationComponent } from '../order/confirmation/confirmation.component';
import { NavigationComponent }      from '../navigation/navigation.component';
import { OrderService } from '../services/order.service';
import { UserService} from '../services/user.service';

import { Address} from '../models/address';

import { filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { trigger, state, transition, animate, style } from '@angular/animations';

import { AppHelper } from '../helpers/app-helper';

import { faBars, faMapMarkedAlt, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-navbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(+500%)'}),
        animate('1000ms 1500ms ease-out', style({transform: 'translateY(0%)'}))
      ])
    ]),
    trigger('slideInOut2', [
      transition(':enter', [
        style({transform: 'translateY(+500%)'}),
        animate('1000ms 2000ms ease-out', style({transform: 'translateY(0%)'}))
      ])
    ]),
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
    transition('void => *', animate('350ms')),
    ])
  ]
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Input() frontColor = '#333333';
  @Input() logoShown = true;
  @Input() menuShown = true;
  @Input() logoSize = 'normal';
  @Input() background = false;
  @Input() locationShown = true;
  @Input() highlightLanguageSelection = false;
  @Output() locationChanged = new EventEmitter<boolean>();
  @Input() fol_bg = false;

  faMapMarkedAlt = faMapMarkedAlt;
  faBars = faBars;
  faChevronDown = faChevronDown;

  public showVideo = false;
  public logo: string;
  public fol = false

  private dialogRef: any;
  private _routerSubscription: any;

  public city: string;
  public addressUpdateSubscription: any;
  public userUpdateSubscription: any;
  public warningsUpdateSubscription: any;

  public navigation = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    public userService: UserService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public appHelper: AppHelper
  ) {}

  ngOnInit() {

   if (this.locationShown && typeof environment.gps_requested !== 'undefined')  { this.locationShown = environment.gps_requested; }

   this._routerSubscription = this.router.events
     .pipe(
       filter(event => event instanceof NavigationStart),
       filter(() => !!this.dialogRef)
     )
     .subscribe(() => {
       this.dialogRef.close();
     });

    setTimeout(() => {
        this.showVideo = true;
      }, 100);

    this.logo = './assets/logos/' + environment.whitelabel + '.png';

    if (this.userService.address) { this.city = this.userService.address.city }
    else if (this.userService.geoAddress) { this.city = this.userService.geoAddress.city };

    this.addressUpdateSubscription = this.userService.addressUpdate.subscribe((address) => {
          if (address) this.city = address.city;
          this.cd.detectChanges();
     });

    this.userUpdateSubscription = this.userService.userUpdate.subscribe((user) => {
          this.cd.detectChanges();
     });

    this.warningsUpdateSubscription = this.userService.warningsUpdate.subscribe((warnings) => {
          this.cd.detectChanges();
     });

     if (environment.whitelabel.startsWith("fol")) {
      this.fol = true
     }

  }

  ngOnDestroy() {
    this.addressUpdateSubscription.unsubscribe();
    this.userUpdateSubscription.unsubscribe();
    this.warningsUpdateSubscription.unsubscribe();
    this._routerSubscription.unsubscribe();
  }

  openMenu() {

    //reload the list of pages user can see
    if (this.userService.user) this.userService.setPages();

    const dialogConfig = new MatDialogConfig();

        const dialogPosition: DialogPosition = {
          top: '0px',
          left: '0px'
        };

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.position = dialogPosition;
        dialogConfig.height = '100vh';
        dialogConfig.panelClass = 'navigation-dialog-panel-class';
        if (window.innerWidth < 500) {
          dialogConfig.panelClass = ['full-width-dialog','navigation-dialog-panel-class'];
        } else { dialogConfig.width = '80vw'; }

        //dialogConfig.data = response;
        this.dialogRef = this.dialog.open(NavigationComponent, dialogConfig);

  }

  openNavigation() {
     this.navigation = true;
  }

  locationCompleted() {
    this.locationChanged.emit(true)
    this.navigation = false
  }

  backgroundClicked() {
    this.navigation = false;
  }

  toggleClass() {
    const nav = document.getElementById('nav-menu');

    if (nav.classList.contains('show-menu')) {
      nav.classList.remove('show-menu');
    } else {
      nav.classList.add('show-menu')
    }
  }

  navigateToMainPage() {
    this.router.navigate(['/']);
  }
}
