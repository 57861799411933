/* src/app/components/delivery-component/delivery-component.component.css */
.delivery-dialog {
  position: fixed;
  width: 100vw;
  padding: 0px;
  z-index: 999;
  top: 0;
  left: 0;
}
.title {
  font-weight: bold;
}
.shadowed {
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.map-wrapper {
  height: 100%;
  width: 100%;
}
.delivery-title {
  font-weight: bolder;
  text-size: 1.2em;
}
.delivery-content {
  height: 200px;
  position: absolute;
  bottom: 0px;
  background-color: white;
  z-index: 9998;
  width: 100%;
}
.delivery-content-part {
  text-align: center;
  margin-top: 80px;
}
.dot {
  position: absolute;
  top: -60px;
  left: calc(50% - 60px);
  height: 120px;
  width: 120px;
  border-radius: 10%;
  display: inline-block;
  text-align: center;
  padding-top: 35px;
  color: white;
}
.faded-bg {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
@media (min-width: 768px) {
  .map-wrapper {
    height: 70vh;
  }
  .delivery-content {
    width: 85vw;
  }
  .dot {
    left: calc(85vw / 2 - 60px);
  }
}
@media (max-width: 767px) {
  .delivery-dialog {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .delivery-dialog {
    position: fixed;
    width: 85vw;
    min-height: fit-content;
    max-height: 85vh;
    margin-left: 7vw;
    margin-top: 7vh;
    border-radius: 0px;
  }
}
/*# sourceMappingURL=delivery-component.component.css.map */
