{
  "pubnub_prefix": "pubnub",
  "default_instance_name": "default",
  "attributes_to_delegate":[
    "channelGroups",
    "push"
  ],
  "methods_to_delegate": [
    "addListener",
    "removeListener",
    "removeAllListeners",
    "hereNow",
    "whereNow",
    "getState",
    "setState",
    "grant",
    "audit",
    "publish",
    "fire",
    "history",
    "deleteMessages",
    "fetchMessages",
    "time",
    "reconnect",
    "stop",
    "unsubscribeAll",
    "getSubscribedChannels",
    "getSubscribedChannelGroups",
    "encrypt",
    "decrypt",
    "getAuthKey",
    "setAuthKey",
    "setCipherKey",
    "getUUID",
    "setUUID",
    "getFilterExpression",
    "setFilterExpression"
  ],
  "common_callbacks_to_wrap": [
    "callback",
    "error"
  ],
  "subscribe_listener_events_to_broadcast": [
    "message",
    "presence",
    "status"
  ],
  "history_sort_attribute": "timetoken"
}