import { Component, OnInit, OnDestroy, Input, Output, Inject, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, Optional } from '@angular/core';
import { DatePipe } from '@angular/common';

import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { DateSheetComponent } from './date-sheet/date-sheet.component';
import { TimeSheetComponent } from './time-sheet/time-sheet.component';
import { TimingSheetComponent } from './timing-sheet/timing-sheet.component';

import { UserService} from '../services/user.service';
import { MerchantService} from '../services/merchant.service';

import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../services/utility.service';

import { AppHelper } from '../helpers/app-helper';

import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { faPaperPlane, faClock } from '@fortawesome/pro-regular-svg-icons';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { environment } from '../../environments/environment';

@Component({
  selector: 'timing',
  templateUrl: './timing.component.html',
  styleUrls: ['./timing.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimingComponent implements OnInit, OnDestroy {

  @Input() condensed = false;
  @Output() emitUpdate = new EventEmitter<boolean>();

  faEdit = faEdit;
  faPaperPlane = faPaperPlane;
  faClock = faClock;

  //public dist_timing_target_index = -1;
  public asap_enabled = false;
  public timed_enabled = false;

  public timeChosen: any;

  private pipe;

  public checkoutFieldsSubscription: any;
  private orderUpdateSubscription: any;

  public dateFormatted: any;
  public dist_timing_target: any;
  public dist_timing_target_end: any;

  public template = "template_default";

  public kiosk = false;
  public pos_dialog = false;
  public posDialogRef: MatDialogRef<TimingComponent>;

  public block_display = 'TIME';
  public block_duration = 15;

  public whitelabel = "guide";
  public fol = false;


  constructor(	public userService: UserService,
                private merchantService: MerchantService,
  				      private bottomSheet: MatBottomSheet,
                private translate: TranslateService,
                private util: UtilityService,
                private cd: ChangeDetectorRef,
                private appHelper: AppHelper,
                public dialog: MatDialog,
                @Optional() public dialogRef: MatDialogRef<TimingComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: any
              ) { 
                

      if (this.data) {
        this.pos_dialog = this.data.pos_dialog;
        this.setBlocks();
      }

      this.whitelabel = environment.whitelabel;
      this.fol = this.appHelper.isFol();
      
  }

  openDateSheet(): void {
    if (this.merchantService.pos) {
      this.openDialog();
    } else {
      const sheetRef = this.bottomSheet.open(DateSheetComponent, { data: { locale: this.userService.user.locale, dist_timing_targets: this.userService.basket.checkoutFields.dist_timing_target }, });
      const dateSubscription = sheetRef.instance.dateSelected.subscribe((index) => {
        this.userService.basket.order.dist_timing_target_index = index;
        this.userService.basket.order.dist_timing_target = null;
        this.dateFormatted = this.getDateSelectedFormatted();
        this.dist_timing_target = null;
        this.dist_timing_target_end = null;
        sheetRef.dismiss();
        this.openTimeSheet();
      });
      sheetRef.afterDismissed().subscribe(() => {
          dateSubscription.unsubscribe();
      });
    }
  }

  setTime(slot) {
    console.log("UPDATE TIMING TO TIMED");
    this.updateTiming("timed");
    console.log("SELECT THE SLOT");
    this.selectTiming(slot);
    console.log("SET TIMING");
    this.setTiming();
    console.log("SET BLOCKS");
    this.setBlocks();
    
    if (this.dialogRef && this.pos_dialog) {
      setTimeout( () => {
        this.dialogRef.close();
      }, 333 );
      
    }
  }

  openTimeSheet(): void {
    if (this.merchantService.pos) {
      this.openDialog();
    } else {
      const sheetRef = this.bottomSheet.open(TimeSheetComponent, { data: { slots: this.userService.basket.checkoutFields.dist_timing_target[this.userService.basket.order.dist_timing_target_index].slots }, });
      const timeSubscription = sheetRef.instance.timeSelected.subscribe((slot) => {
        this.selectTiming(slot);
        this.setTiming();
        this.setBlocks();
        sheetRef.dismiss();
      });
      sheetRef.afterDismissed().subscribe(() => {
          timeSubscription.unsubscribe();
      });
    }
  }

  openDialog() {
    this.posDialogRef = this.dialog.open(TimingComponent, { data: { pos_dialog: true }, maxHeight: '90vh', maxWidth: '90vw'});
    this.posDialogRef.afterClosed().subscribe(result => {
      if (this.userService.basket.checkoutFields.dist_timing_target[this.userService.basket.order.dist_timing_target_index]) {
        this.dateFormatted = this.userService.basket.checkoutFields.dist_timing_target[this.userService.basket.order.dist_timing_target_index].label;
        this.dateFormatted += ' (' + this.pipe.transform(this.userService.basket.order.dist_timing_target, 'dd MMM yyyy') + ')';
        this.setTiming();
      }
      this.cd.detectChanges();
    });
  }
  
  selectTime(timing_value) {
    this.updateTiming(timing_value);
    if (timing_value == 'timed') { 
      this.appHelper.getCheckoutFieldsForOrder().subscribe(response => {
        this.openDialog();
      });
    }
  }

  openTimingSheet(toDo): void {
    if (toDo) {
      const sheetRef = this.bottomSheet.open(TimingSheetComponent, { data: { dist_timings: this.userService.basket.checkoutFields.dist_timing }, });
      const timingSubscription = sheetRef.instance.timingSelected.subscribe((timing) => {
        this.updateTiming(timing.value);
        sheetRef.dismiss();
        if (timing.value == 'timed') { 
          this.appHelper.getCheckoutFieldsForOrder().subscribe(response => {
            this.openDateSheet(); 
          });
        }
      });
      sheetRef.afterDismissed().subscribe(() => {
        timingSubscription.unsubscribe();
      });
    }
  }

  verifyEnabledTimings() {

    this.asap_enabled = false;
    this.timed_enabled = false;

    for (const order_method of this.merchantService.merchant.settings.s_order_method) {
      if (order_method == 'asap') { 

        let filtered_constraints = null;

        if (this.merchantService.merchant.order_constraints) {

         filtered_constraints = this.merchantService.merchant.order_constraints.filter(constraint => {

            return constraint.category.length == 0 &&
            constraint.catering_locations.length == 0 &&
            !constraint.current_week_scheme &&
            !constraint.cut_off &&
            !constraint.cut_off_wday &&
            constraint.dist_location_types.length == 0 && 
            constraint.dist_timings.includes('asap') && 
            constraint.enabled == true &&
            constraint.group_types.length == 0 &&
            constraint.order_location_types.length == 0 && 
            constraint.payment_methods.length == 0 &&
            constraint.price_max == "0.0" &&
            constraint.price_min == "0.0" && 
            constraint.scenarios.includes(this.userService.basket.order.scenario)
          });

          if (!filtered_constraints || filtered_constraints.length == 0) this.asap_enabled = true;

        } else {
          this.asap_enabled = true;
        } 

      }
      
      else if (order_method == 'timed') { 
        

        let filtered_constraints = null;

        if (this.merchantService.merchant.order_constraints) {

          filtered_constraints = this.merchantService.merchant.order_constraints.filter(constraint => {

            return constraint.category.length == 0 &&
            constraint.catering_locations.length == 0 &&
            !constraint.current_week_scheme &&
            !constraint.cut_off &&
            !constraint.cut_off_wday &&
            constraint.dist_location_types.length == 0 && 
            constraint.dist_timings.includes('timed') && 
            constraint.enabled == true &&
            constraint.group_types.length == 0 &&
            constraint.order_location_types.length == 0 && 
            constraint.payment_methods.length == 0 &&
            constraint.price_max == "0.0" &&
            constraint.price_min == "0.0" && 
            constraint.scenarios.includes(this.userService.basket.order.scenario)
          });

          if (!filtered_constraints || filtered_constraints.length == 0) this.timed_enabled = true; 

        } else {
          this.timed_enabled = true;
        }

      } 
    }  
  }

  ngOnInit() {

    if (this.merchantService.kiosk) { this.kiosk = true; };
    if (this.merchantService.pos) { this.template = 'template_pos' };
    if (this.fol) { this.template = 'template_fol' };

    this.pipe = new DatePipe(this.userService.user.locale);

    this.verifyEnabledTimings();
    this.setDistTimingTargetIndex();
    this.userService.saveBasketInStorage();

    this.checkoutFieldsSubscription = this.userService.checkoutFieldsUpdate.subscribe((field_values) => {
      this.verifyEnabledTimings();
      this.setTiming();
      this.setBlocks();
      this.cd.detectChanges();   
    });

    this.dateFormatted = this.getDateSelectedFormatted();
    this.setTiming();
    this.cd.detectChanges();   

    this.timeChosen = this.appHelper.timeChosenEmitter.subscribe(() => {
      this.setTiming();
      this.cd.detectChanges();   
    });

    this.orderUpdateSubscription = this.userService.orderUpdate.subscribe((updateOngoing) => {
      this.setDistTimingTargetIndex();    
      this.cd.detectChanges();   
    });

  }

  setBlocks() {

    let scenario = this.userService.basket.order.scenario;
    let block_duration = 15;

    if (this.merchantService.merchant.settings['s_block_display_scenario_' + scenario] && this.merchantService.merchant.settings['s_block_display_scenario_' + scenario] == 'BLOCK') {
      this.block_display = 'BLOCK';
      this.block_duration = this.merchantService.merchant.settings['s_block_duration_scenario_' + scenario];
    }

    if (this.block_display == 'BLOCK') {
      for (let target of this.userService.basket.checkoutFields.dist_timing_target) {
        for (let slot of target.slots) {
          let begin = new Date(slot.value);
          let end = new Date(slot.value);
          end.setMinutes(end.getMinutes() + this.block_duration);
          slot.begin = begin;
          slot.end = end;
        }
      }
    }
  }

  setDistTimingTargetIndex() {

     if (this.userService.basket &&
         this.userService.basket.order &&
         this.userService.basket.order.dist_timing == 'timed' && 
         this.userService.basket.order.dist_timing_target) {
        let index = 0;
        for (let dist_timing_target of this.userService.basket.checkoutFields.dist_timing_target) {
          dist_timing_target.slots_available = false;
          for (const slot of dist_timing_target.slots) {
            if (slot.value == this.userService.basket.order.dist_timing_target) { 
              this.userService.basket.order.dist_timing_target_index = index; 
              if (dist_timing_target.slots_available == true) { break; };
            }
            if (slot.is_enabled) { dist_timing_target.slots_available = true }; 
          }
          index++;
        }
    }

  }

  setTiming() {
    this.dist_timing_target = this.userService.basket.order.dist_timing_target;
    this.dist_timing_target_end = new Date(this.userService.basket.order.dist_timing_target);
    this.dist_timing_target_end.setMinutes(this.dist_timing_target_end.getMinutes() + this.merchantService.merchant.settings['s_block_duration_scenario_' + this.userService.basket.order.scenario]);

    this.setDistTimingTargetIndex();

    if (this.userService.basket.order.dist_timing_target && this.userService.basket.checkoutFields.dist_timing_target && this.userService.basket.checkoutFields.dist_timing_target[this.userService.basket.order.dist_timing_target_index]) {
        this.dateFormatted = this.userService.basket.checkoutFields.dist_timing_target[this.userService.basket.order.dist_timing_target_index].label;
        this.dateFormatted += ' (' + this.pipe.transform(this.userService.basket.order.dist_timing_target, 'dd MMM yyyy') + ')';
    }

  }

  ngOnDestroy() {
    if (this.checkoutFieldsSubscription) this.checkoutFieldsSubscription.unsubscribe();
    if (this.orderUpdateSubscription) this.orderUpdateSubscription.unsubscribe();
    if (this.timeChosen) this.timeChosen.unsubscribe();
  }

  timingEnabled() {
    for (const dist_timing of this.userService.basket.checkoutFields.dist_timing) {
      if (this.userService.basket.order.dist_timing == dist_timing.value) {
         return dist_timing.is_enabled;
     }
    }
    return false;
  }

  selectTiming(slot) {
  	this.userService.basket.order.dist_timing_target = slot.value;
  	this.emitToParent();
  }

  updateTiming(timing) {
      this.userService.basket.order.dist_timing = timing;
  		this.userService.basket.order.dist_timing_target = null;
      this.userService.basket.order.dist_timing_target_index = -1;
      this.dateFormatted = this.getDateSelectedFormatted();
  		if (this.userService.basket.order.dist_timing == 'asap') { this.emitToParent(); }
  }

  emitToParent() {
    this.emitUpdate.emit(true);
  }

  getDateSelectedFormatted() {

    if (this.userService.basket.checkoutFields.dist_timing && 
        this.userService.basket.checkoutFields.dist_timing == 'timed' && 
        this.userService.basket.checkoutFields.dist_timing_target) {
  
    if (  this.userService.basket.order.dist_timing_target_index == -1 ||
  	      !this.userService.basket.checkoutFields.dist_timing_target[this.userService.basket.order.dist_timing_target_index]) { 
      
      this.userService.basket.order.dist_timing_target_index = -1;
      this.userService.basket.order.dist_timing_target = null;
      return this.translate.instant('timing.day_title');
    
    } else {
      let toReturn = this.userService.basket.checkoutFields.dist_timing_target[this.userService.basket.order.dist_timing_target_index].label;
      if (this.userService.basket.order.dist_timing_target) {
        toReturn += ' (' + this.pipe.transform(this.userService.basket.order.dist_timing_target, 'dd MMM yyyy') + ')';
      }
      return toReturn;
    }

    } else {
      return "";
    }
  
  }

}
