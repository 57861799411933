import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { ProductService } from '../services/product.service';
import { TaxonService } from '../services/taxon.service';
import { UserService } from '../services/user.service';

import { AppHelper } from '../helpers/app-helper';
import { StylingHelper } from '../helpers/styling-helper';


import { Product } from '../models/product';

import { UtilityService } from '../services/utility.service';

import { environment } from '../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { animate as animateMotion } from "motion";

@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1500)),
    ]),
    trigger('fadeInOut2', [
      state('invisible', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('visible <=> *', animate(350)),
    ]),
    trigger('fadeIn', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *', animate(350)),
    ])
  ]
})
export class CategoryComponent implements OnInit, OnDestroy, OnChanges {

  @Input() taxon: any;
  @Input() index: any;
  @Input() view = "full";
  @Output() productSelected = new EventEmitter<Product>();
  @Input() searchText = "";

  public template: string;

  public primary_color;

  public catBackground;
  public product_display: string = environment.product_display;
  public whitelabel = environment.whitelabel;

  public icon_data: SafeHtml;

  public products = [];

  private stateUpdateSubscription: any;
  private basketPushedSubscription: any;
  private orderUpdateSubscription: any;
  private sortNormalSubscription: any;

  public fol = false;

  constructor(
    private merchantService: MerchantService,
    private taxonService: TaxonService,
    private productService: ProductService,
    public userService: UserService,
    private orderService: OrderService,
    private util: UtilityService,
    private translate: TranslateService,
    public appHelper: AppHelper,
    private stylingHelper: StylingHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef) {

    this.fol = this.appHelper.isFol();
  }

  ngOnInit() {

    if (this.fol) this.template = 'template2'
    else this.template = this.stylingHelper.getTemplate(this.merchantService.merchant.settings["s_template"]);
    
    if (this.merchantService.kiosk) { this.template = 'template_kiosk'; };
    if (this.merchantService.pos) { this.template = 'template_pos' };

    this.filterProducts();

    //define main image of the category
    for (const product of this.products) {
      if (product.main_image) {
        this.catBackground = product.main_image.large_url;
      }
      //possible correction to the description of the product
      product.description = product.description.replace(/ /g, " ");
      let space_index = product.description.indexOf(" ");
      if (product.description.length > 30 && (space_index <= 0 || space_index > 30)) {
        product.description = product.description.substring(0, 30) + ' ' + product.description.substring(30);
      }
    }

    this.primary_color = this.appHelper.getMerchantPrimaryColor();

    //read the svg
    if (this.taxon && this.taxon.icon) {
      this.http.get(this.taxon.icon.svg_url, { responseType: 'text' })
        .subscribe(data => {
          data = data.replace("width", "xidth");
          data = data.replace("height", "xeight");
          data = data.replace(/fill/g, "xill");
          let start_index = data.indexOf("<svg");
          this.icon_data = this.sanitizer.bypassSecurityTrustHtml(data.substring(start_index));
          this.cd.detectChanges();
        });
    }

    this.setValues();

    this.sortNormalSubscription = this.userService.sortNormalSubject.subscribe(sortNormal => {
      this.filterProducts();
    });

    this.basketPushedSubscription = this.userService.basketPushed.subscribe((basket) => {
      this.setValues();
    });


    this.stateUpdateSubscription = this.userService.stateUpdate.subscribe((updateOngoing) => {
      if (!updateOngoing) {
        this.setValues();
      }
    });

    this.orderUpdateSubscription = this.userService.orderUpdate.subscribe((updateOngoing) => {
      this.setValues();
    });
  }

  setValues() {
    for (const product of this.products) {
      product.basket_count = this.productBasketCount(product);
    }
  }

  ngOnDestroy() {
    if (this.sortNormalSubscription) { this.sortNormalSubscription.unsubscribe(); }
    if (this.stateUpdateSubscription) { this.stateUpdateSubscription.unsubscribe(); }
    if (this.basketPushedSubscription) { this.basketPushedSubscription.unsubscribe(); }
    if (this.orderUpdateSubscription) { this.orderUpdateSubscription.unsubscribe(); }
  }

  filterProducts() {

    this.products = this.taxon.products.filter(product => {
      return product.name.toLowerCase().includes(this.searchText.toLowerCase()) || product.description.toLowerCase().includes(this.searchText.toLowerCase());
    });

    for (const product of this.products) {
      for (const product_taxon of product.product_taxons) {
        if (product_taxon.taxon_id == this.taxon.id) { product.ordering = product_taxon.ordering; }
      }
      
    }

    if (this.userService.sortNormal === false) {
      this.products.sort((a, b) => { return a.name.localeCompare(b.name) });
    } else {
      this.products.sort((a, b) => { return a.ordering - b.ordering; });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.filterProducts();
  }

  productBasketCount(product) {
    let count = 0;
    if (this.userService.user && this.userService.basket) {
      for (const order_line of this.userService.basket.order.order_lines) {
        if (order_line.product_id == product.id) { count += order_line.amount; }
      }
    }
    return count;
  }

  openProduct(event,product) {

    animateMotion(
      `#pos-box-${product.id}`,
      { transform: ["scale(1.2)", "scale(1.0)"] },
      {
        duration: 0.3,
        easing: "ease-in-out"
      }
    );

   this.productSelected.emit(product);
  
  }
}
