/* src/app/sheets/scenario-sheet/scenario-sheet.component.scss */
.scenario_img {
  max-height: 25px;
  max-width: 25px;
}
.scenario {
  border-bottom: 1px solid lightgray;
}
.noMarker {
  width: 10px;
}
/*# sourceMappingURL=scenario-sheet.component.css.map */
