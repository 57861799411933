/* src/app/payment-method/payment-method-sheet/payment-method-sheet.component.scss */
.title {
  font-weight: bolder;
}
.payment-method_img {
  max-height: 25px;
  max-width: 80px;
}
.payment-method {
  border-bottom: 1px solid lightgray;
}
/*# sourceMappingURL=payment-method-sheet.component.css.map */
