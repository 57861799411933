<div class="title">
  {{ 'timing.day_title' | translate }}
</div>

<mat-nav-list>
  <div style="width:100%;" *ngFor="let dist_timing_target of data.dist_timing_targets; let i = index">
  <a 	(click)="selectDate($event,dist_timing_target,i)" 
  		mat-list-item
  		*ngIf="dist_timing_target.is_enabled">
    <span mat-line>{{ dist_timing_target.label }} ({{ dist_timing_target.value | date:'dd MMM yyyy':'':data.locale }})</span>
  </a>
	</div>
</mat-nav-list>