<div
 style="background-color: #ececec; z-index:9999;width: 100vw;max-width:1200px;position:fixed; bottom:30px;left: 50%;transform: translate(-50%);">
  <div class="simple-keyboard"></div>
</div>

<!--
<div mat-dialog-title class="mb-3">{{ 'pos.please_add_reference_comment' | translate }}</div>
-->

<div mat-dialog-content class="d-flex flex-column">
  <ng-container *ngIf="show_reference">
    <mat-form-field [style.width.px]=527>
      <mat-label>{{ 'settings.reference' | translate }}</mat-label>
      <input (focus)="setKeyboardInput('reference')"
            matInput [(ngModel)]="reference"
            tabindex="1"/>
    </mat-form-field>
  </ng-container>

  <mat-form-field [style.width.px]=527>
    <mat-label>{{ 'settings.comment' | translate }}</mat-label>
    <input (focus)="setKeyboardInput('comment')"
          matInput [(ngModel)]="comment" 
          tabindex="2"/>
  </mat-form-field>
</div>

<div mat-dialog-actions [align]="'center'">
  <button mat-stroked-button mat-dialog-close tabindex="4">{{ 'cancel' | translate }}</button>
  <button mat-raised-button
          (click)="onSave()"
          [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
          tabindex="3">{{ 'ok' | translate }}</button>
</div>