import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';

interface FDMResponse {
  type?: string;
  data?: DeciferedFDMResponse;
}

interface DeciferedFDMResponse {
  fdm_identifier?: string;
  fdm_error_1?: string;
  fdm_error_2?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FdmService {

  public port: any;
  private ports: any;
  private reader: any;
  private writer: any;
  public request_user = false;
  public retry_count = 0;
  public sequence_number = 0;

  public transactions = [];
  private errors = [];
  private dumps = [];

  public statistics = null;
  public identification = null;

  public fdm_requests = [];
  public fdm_response = null;

  private transactions_requested = [];
  private dumps_requested = [];
  private errors_requested = [];

  public fdm_data = { ticket_counter: 0 };

  public status: any;

  private timer_get_orders: any;
  private timer_send_orders: any;
  private timer_send_request: any;
  private timer_no_port: any;
  private timeout_counter = 0;
  private last_50_orders_send_to_fdm = [];
  private current_request = '';
  public userLoggedOutSubject = new BehaviorSubject(false);
  public fdmDisconnected = new BehaviorSubject(true);
  public fdmErrorSubject = new BehaviorSubject(false);
  public fdmErrorMessageSubject = new BehaviorSubject('');
  public fdmWarningSubject = new BehaviorSubject(false);
  public fdmTransactionsSubject = new BehaviorSubject([]);
  public fdmStatusSubject = new BehaviorSubject('');
  public fdmStatisticsSubject = new BehaviorSubject({});

  public all_errors = {
    0: {
      0: "ok",
      1: "pin ok"
    },
    1: {
      1: "FDM geheugen reeds 90% vol",
      2: "Request reeds verwerkt",
      3: "Geen record",
      99: "Ander"
    },
    2: {
      1: "geen VSC of VSC defect",
      2: "VSC niet geïnitaliseerd met PIN",
      3: "VSC geblokkeerd",
      4: "PIN niet geldig",
      5: "FDM geheugen VOL",
      6: "Ongekende boodschap (identifier)",
      7: "Ongeldige data in boodschap",
      8: "FDM niet operationeel",
      9: "FDM Real Time Clock corrupt",
      10: "VSC-versie niet compatibel met FDM",
      99: "Ander"
    }
  }

  constructor(
    private userService: UserService,
    private orderService: OrderService,
  ) {

    this.fdmDisconnected.next(true);
    this.tryToOpenPreviousPort();
    this.addListenersToConnectAndDisconnectEvents();

    if (localStorage && localStorage.getItem('fdm_data')) { this.fdm_data = JSON.parse(localStorage.getItem('fdm_data')); };

    console.log("LOCAL STORAGE");
    console.log(this.fdm_data);
  }

  async resetPort() {
    this.port = null;
    this.status = null;
    this.transactions = null;
    this.errors = null;
    this.dumps = null;
    this.statistics = null;
    this.identification = null;
  }

  async tryToOpenPreviousPort() {
    const serial = (navigator as any).serial
    let ports = null;
    if (serial) ports = await (navigator as any).serial.getPorts();
    if (ports && ports.length > 0) {
      this.port = ports[0];
      if (this.port && this.port.writable) {
        console.log('the port is already open')
      }
      await this.port.open({ baudRate: 19200, dataBits: 8, stopBits: 1, parity: 'none' });
      if (this.fdm_requests.length == 0) this.addRequestStatusToQueue();
      console.log('port open');
    } else {
      setTimeout(() => {
        this.tryToOpenPreviousPort();
      }, 10000);
    }

  }

  addListenersToConnectAndDisconnectEvents() {

    try {

      (navigator as any).serial.addEventListener('connect', async (e) => {
        console.log('port connected')
        this.tryToOpenPreviousPort();
      });

      (navigator as any).serial.addEventListener('disconnect', async (e) => {
        console.log('port disconnected')
        if (this.port) {
          if (this.writer) { this.writer.releaseLock(); }
          if (this.reader) { this.reader.releaseLock(); }
          await this.port.close();
        }
        this.port = null;
        this.request_user = true;
        this.fdmDisconnected.next(true);
        this.fdmErrorSubject.next(true);
      });

    } catch (e) {
      //console.log(e);
    }

  }

  async openPort(port) {
    console.log(port);
    this.port = port;
    await this.port.open({ baudRate: 19200, dataBits: 8, stopBits: 1, parity: 'none' });
    if (this.fdm_requests.length == 0) this.addRequestStatusToQueue();

  }

  activateFDM() {
    if (this.fdm_requests.length == 1) this.sendNextRequestToFDM();
  }

  async sendNextRequestToFDM() {

    if (this.port && this.port.writable) {

      if (this.fdm_requests.length > 0) {

        let request = this.fdm_requests[0];

        let decifered_fdm_response = null;
        let array = null;
        let date = null;
        let index = null;

        if (request.type == 'status') {
          decifered_fdm_response = await this.getFDMStatus();

        } else if (request.type == 'errors') {
          if (request.index == 1) this.errors = [];
          date = request.date;
          index = request.index;
          decifered_fdm_response = await this.sendDataAndReadResponseV2('E', `${date}` + index.toString().padStart(6, ' '));
          array = this.errors;

        } else if (request.type == 'transactions') {
          if (request.index == 1) this.transactions = [];
          date = request.date;
          index = request.index;
          decifered_fdm_response = await this.sendDataAndReadResponseV2('T', `${date}` + index.toString().padStart(6, ' '));
          array = this.transactions;

        } else if (request.type == 'dumps') {
          if (request.index == 1) this.dumps = [];
          date = request.date;
          index = request.index;
          decifered_fdm_response = await this.sendDataAndReadResponseV2('D', index.toString().padStart(6, ' '));
          array = this.dumps;

        } else if (request.type == 'statistics') {
          decifered_fdm_response = await this.sendDataAndReadResponseV2('O');
          this.statistics = decifered_fdm_response.data.message;

        } else if (request.type == 'identification') {
          decifered_fdm_response = await this.sendDataAndReadResponseV2('I');
          this.identification = decifered_fdm_response.data.message;
        } else if (request.type == 'start_shift') {
          decifered_fdm_response = await this.startShift(request.merchant_id, request.user, request.local_location_id, request.ticket_counter);
        } else if (request.type == 'end_shift') {
          decifered_fdm_response = await this.endShift(request.merchant_id, request.user, request.local_location_id, request.ticket_counter);
        } else if (request.type == 'send_pin_code') {
          decifered_fdm_response = await this.sendPinCode(request.pin_code);
        } else if (request.type == 'order') {
          decifered_fdm_response = await this.sendOrderToFDM(request.event_label, request.order, request.user, request.local_location_id, request.ticket_counter, request.pos_handled);
        }

        if (decifered_fdm_response) {

          this.fdm_response = decifered_fdm_response.data
          console.log("FDM RESPONSE ==>");
          console.log(decifered_fdm_response);
          console.log(this.fdm_response);

          if (decifered_fdm_response.type == "success") {

            //this.fdmErrorSubject.next(false);

            console.log("SUCCESSSSSS!");

            let data = decifered_fdm_response.data;
            let message = decifered_fdm_response.data.message;

            if (request.type == 'end_shift') {
              console.log("REQUEST WAS TO END THE SHIFT, SO WE CONFIRM THIS IS OK!!!");
              console.log("SETTING TO TRUE!!!!");
              this.userLoggedOutSubject.next(true);
            } else if (array && data.fdm_error_1 == '0' || (data.fdm_error_1 == '1' && data.fdm_error_2 == '01')) {
              console.log("PUSHING THE MESSAGE TO THE ARRAY!!!!");
              console.log(this.transactions);
              array.push(message);
              console.log("ARRAY =====>");
              console.log(array);
              if (index == 1) {
                console.log("INDEX = 1");
                let number_of_requests = parseInt(message.total_requests);
                console.log("TOTAL NUMBER OF REQUESTS: ");
                console.log(number_of_requests);
                for (let i = 2; i <= number_of_requests; i++) {
                  this.fdm_requests.push(({ type: request.type, date: date, index: i }));
                }
              }
            } else if (data.fdm_error_1 == '1') {
              if (data.fdm_error_2 == '02') {
                console.log('this has already been requested')
              } else if (data.fdm_error_2 == '03') {
                console.log('there were no records for this day')
              }
            }

          } else {

            console.log("THIS IS THE REQUEST =>");
            console.log(request);
            this.fdmErrorSubject.next(true);

          }

        }

        await this.basicErrorHandling(request, decifered_fdm_response);

        clearTimeout(this.timer_send_request);

        this.timer_send_request = setTimeout(() => {
          this.sendNextRequestToFDM();
        }, 5);

      } else {
        this.timer_send_request = setTimeout(() => {
          if (this.fdm_requests.length == 0) this.addRequestStatusToQueue();
        }, 5000);
      }

    } else {
      this.fdmErrorSubject.next(true);
      console.log("No port, or port not writable");
      this.timer_no_port = setTimeout(() => {
        this.sendNextRequestToFDM();
      }, 5000);
    }

  }

  getTicketCounter() {
    this.fdm_data.ticket_counter++;
    if (localStorage) { localStorage.setItem('fdm_data', JSON.stringify(this.fdm_data)); }
    return this.fdm_data.ticket_counter;
  }

  needsProForma(order, user, local_location_id, pos_handled) {
    let needsProForma = false;

    order.order_lines.forEach(ol => {
      if (order.order_type == 'personal' && ol.amount !== ol.plus) {
        needsProForma = true;
      } else if (order.order_type == 'refund' && Math.abs(ol.amount) !== ol.minus) {
        needsProForma = true;
      }
    });

    let event_label = order.order_type == 'refund' ? 'PR' : 'PS';
    if (needsProForma) { this.addOrderToQueue(event_label, JSON.parse(JSON.stringify(order)), user, local_location_id, pos_handled); };
  }


  addOrderToQueue(event_label, order, user, local_location_id, pos_handled) {
    this.fdm_requests.push({ type: 'order', event_label: event_label, pos_handled: pos_handled, order: order, user: user, local_location_id: local_location_id, ticket_counter: this.getTicketCounter() });
    this.activateFDM();
  }

  resetErrorNotifications() {
    this.fdmErrorSubject.next(false);
    this.fdmWarningSubject.next(false);
  }

  async sendOrderToFDM(event_label, order, user, local_location_id, ticket_counter, pos_handled) {

    let vat_tariff_amount_1 = '2100';
    let vat_tariff_amount_2 = '1200';
    let vat_tariff_amount_3 = ' 600';
    let vat_tariff_amount_4 = ' 000';

    let ticket_number = ("000000" + ticket_counter).slice(-6);

    console.log("Following order will be sent =>");
    console.log(order);

    let date = order.updated_at.slice(0, 10).replace(/-/g, '');
    let time = new Date(order.updated_at).toLocaleTimeString('nl-BE', { hour12: false }).replace(/:/g, '');

    let user_insz = pos_handled ? user.insz : '00000000097';

    let production_number_registry = this.userService.posCounter.identifier;

    let order_price = order.price;
    if (order.order_type == 'refund') { order_price *= -1; }

    let total_price = (order_price * 100).toFixed(0).toString().padStart(3, '0').padStart(11, ' ');
    let tariffs = this.setTaxAmounts(order);

    let vat_1_amount = tariffs[0].vat_amount;
    let vat_2_amount = tariffs[1].vat_amount;
    let vat_3_amount = tariffs[2].vat_amount;
    let vat_4_amount = tariffs[3].vat_amount;

    let plu_hash = await this.generatePLUCode(order, event_label);

    console.log("ticket_number: " + ticket_number);
    console.log("event_label: " + event_label);
    console.log("total_price: " + total_price);
    console.log("vat_tariff_amount_1: " + vat_tariff_amount_1);
    console.log("vat_1_amount: " + vat_1_amount);
    console.log("vat_tariff_amount_2: " + vat_tariff_amount_2);
    console.log("vat_2_amount: " + vat_2_amount);
    console.log("vat_tariff_amount_3: " + vat_tariff_amount_3);
    console.log("vat_3_amount: " + vat_3_amount);
    console.log("vat_tariff_amount_4: " + vat_tariff_amount_4);
    console.log("vat_4_amount: " + vat_4_amount);
    console.log("plu_hash: " + plu_hash);

    console.log(order.vat_hash);
    console.log(order.vat_hash["string"]);
    console.log(order.vat_hash["sha"]);
    console.log(order.pro_forma_hash);
    console.log(order.pro_forma_hash["string"]);
    console.log(order.pro_forma_hash["sha"]);

    if (event_label.startsWith("N")) plu_hash = order.vat_hash["sha"];
    else plu_hash = order.pro_forma_hash["sha"];

    let message = date + time + user_insz + production_number_registry + ticket_number + event_label + total_price + vat_tariff_amount_1 + vat_1_amount + vat_tariff_amount_2 + vat_2_amount + vat_tariff_amount_3 + vat_3_amount + vat_tariff_amount_4 + vat_4_amount + plu_hash

    console.log("THE FINAL MESSAGE SENT TO THE FDM!!! ====> ");
    console.log(message);

    let result = await this.sendDataAndReadResponseV2('H', message);
    let data = result.data;

    if (data.fdm_identifier == 'H' && data.fdm_error_1 == '0' || (data.fdm_error_1 == '1' && data.fdm_error_2 == '01')) {
      this.sendConfirmationToBE(order.merchant_id, order, plu_hash, pos_handled, ticket_number, user, user_insz, local_location_id, data);
    }

    return result;

  }


  async sendConfirmationToBE(merchant_id, order, plu_hash, pos_handled, ticket_number, user, user_insz, local_location_id, data, start_shift = false, end_shift = false) {

    console.log("############sendConfirmationToBE###############");
    console.log(data);

    let event_label = null;
    let vsc_ticket_counter = null;
    let vsc_total_counter = null;
    let fdm_rest_of_message = null;
    let fdm_productnumber = null;
    let vsc_identification_number = null;
    let fdm_date = null;
    let fdm_time = null;

    if (data) {
      event_label = data.message.event_label
      vsc_ticket_counter = data.message.vsc_ticket_counter;
      vsc_total_counter = data.message.vsc_total_counter;
      fdm_rest_of_message = data.fdm_rest_of_message;
      fdm_productnumber = data.fdm_production_number;
      vsc_identification_number = data.message.vsc_identification_number;
      fdm_date = data.message.date;
      fdm_time = data.message.time;
    } else {
      event_label = "NS";
    }

    if (event_label[0] == 'N' || event_label[0] == "P") {

      let signature = null;
      if (fdm_rest_of_message) { signature = fdm_rest_of_message.slice(-40).trim(); };

      let order_id = null;
      let user_id = null;
      if (order) { order_id = order.id; };

      if (pos_handled) {
        user_id = user.id;
        user_insz = user.insz;
      }

      if (user.insz == user_insz) {
        user_id = user.id;
      } else {

      }

      let fdm_event = { event_data: null, user_id: user_id, plu_hash: plu_hash, user_insz: user_insz, order_id: order_id, ticket_number: ticket_number, fdm_productnumber: fdm_productnumber, vsc_id: vsc_identification_number, fdm_date: fdm_date, fdm_time: fdm_time, label: event_label, vsc_ticket_counter: vsc_ticket_counter, vsc_total_counter: vsc_total_counter, signature: signature, local_location_id: local_location_id, start_shift: start_shift, end_shift: end_shift, merchant_id: merchant_id };

      if (order) { fdm_event.event_data = JSON.stringify(order) };

      console.log(fdm_event);

      this.orderService.createFdmEvent(fdm_event).subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );

    }

  }


  async sendPinCode(pin_code) {
    console.log("WILL NOW SEND THE PIN CODE ====>");
    console.log(pin_code);
    let result = await this.sendDataAndReadResponseV2('P', pin_code);
    let data = result.data;
    return result;
  }

  /*
  async sendNextDump() {
    this.current_request = 'dump';
    let date = 'false'
    let index = this.dumps_to_request[0].index;
    await this.sendDataAndReadResponseV2('D',index.toString().padStart(6, ' '));
    //await this.basicErrorHandling();

    let response = {};

    if (this.fdm_rest_of_message != '') {
      
      response = {
        index: this.fdm_rest_of_message.slice(0, 6),
        total_requests: this.fdm_rest_of_message.slice(6, 12),
        date: this.fdm_rest_of_message.slice(12, 20),
        time: this.fdm_rest_of_message.slice(20, 26),
        start_date_in_FDMserltxt: this.fdm_rest_of_message.slice(26, 34),
        end_date_in_FDMserltxt: this.fdm_rest_of_message.slice(34, 42)
      }
    }

    this.extraErrorHandling(date, index, response, this.dumps_to_request, this.dumps_requested);
  }
  */

  addExtraRequestToTheArray(date, index, response, array_to_request, array_requested) {
    if (index == 1) {
      let number_of_requests = parseInt(response.total_requests);
      for (let i = 2; i <= number_of_requests; i++) {
        array_to_request.push({ date: date, index: i })
      }
    } else if (index == array_to_request.at(-1).index) {
      let number_of_requests = parseInt(response.total_requests);
      if (index != number_of_requests) {
        for (let i = index + 1; i <= number_of_requests; i++) {
          array_to_request.push({ date: date, index: i })
        }
      } else {
        console.log(array_requested)
      }
    }
  }

  hex2a(hex) {
    var str = '';
    for (var i = 0; i < hex.length; i += 2) {
      var v = parseInt(hex.substr(i, 2), 16);
      if (v) str += String.fromCharCode(v);
    }
    return str;
  }

  /*
  async sendNextOtherRequestToFDM() {
    this.current_request = 'other'
    if (this.other_requests.indexOf('end_shift') > -1) {
      await this.endShift();
      this.userLoggedOutSubject.next(true);
    } else {
      let request = this.other_requests[0];
      if (request == 'status') {
        await this.getFDMStatus();
      } else if (request == 'statistics') {
        //await this.getStatistics();
      } else if (request == 'send_pin_code') {
        await this.sendPinCode();
      } else if (request == 'start_shift') {
        await this.startShift();
      }
    }
  }*/

  addRequestStartShiftToQueue(merchant_id, user, local_location_id) {

    console.log("fdmservice addRequestStartShiftToQueue");
    console.log(user);
    console.log(local_location_id);

    this.fdm_requests.push({ type: 'start_shift', merchant_id: merchant_id, user: user, local_location_id: local_location_id, ticket_counter: this.getTicketCounter() });
    console.log('adding start shift to queue');
    this.activateFDM();
  }

  addRequestEndShiftToQueue(merchant_id, user, local_location_id) {
    console.log('adding end shift to queue')
    this.fdm_requests.push({ type: 'end_shift', merchant_id: merchant_id, user: user, local_location_id: local_location_id, ticket_counter: this.getTicketCounter() });
    console.log("activating the FDM....");
    this.activateFDM();
  }

  async startShift(merchant_id, user, local_location_id, ticket_counter) {

    console.log("startShift");
    console.log(user);
    console.log(local_location_id);

    let vat_tariff_amount_1 = '2100'
    let vat_tariff_amount_2 = '1200'
    let vat_tariff_amount_3 = ' 600'
    let vat_tariff_amount_4 = ' 000'

    let date = new Date().toLocaleDateString('af-ZA', { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/-/g, '');
    console.log(date);
    let time = new Date().toLocaleTimeString('nl-BE', { hour12: false }).replace(/:/g, '');
    console.log(time);
    let production_number_registry = this.userService.posCounter.identifier;
    let ticket_number = ("000000" + ticket_counter).slice(-6);
    let event_label = 'NS'
    let total_price = '        000'

    let vat_1_amount = '        000';
    let vat_2_amount = '        000';
    let vat_3_amount = '        000';
    let vat_4_amount = '        000';
    let plu_hash = '13b6fa41'.padStart(40, '0')

    let message = date + time + user.insz + production_number_registry + ticket_number + event_label + total_price + vat_tariff_amount_1 + vat_1_amount + vat_tariff_amount_2 + vat_2_amount + vat_tariff_amount_3 + vat_3_amount + vat_tariff_amount_4 + vat_4_amount + plu_hash

    let result = await this.sendDataAndReadResponseV2('H', message);
    let data = result.data;

    if (data.fdm_identifier == 'H' && data.fdm_error_1 == '0' || (data.fdm_error_1 == '1' && data.fdm_error_2 == '01')) {
      this.sendConfirmationToBE(merchant_id, null, plu_hash, true, ticket_number, user, user.insz, local_location_id, data, true);
    }


    return result;

    //await this.basicErrorHandling();

    /*if (this.fdm_error_1 == '0' || (this.fdm_error_1 == '1' && this.fdm_error_2 == '01')) { 
      this.other_requests.shift();
    } else if (this.fdm_error_1 == '1') {
      if (this.fdm_error_2 == '02') {
        console.log('the login has already been requested')
        this.other_requests.shift();
      } else if (this.fdm_error_2 == '03') {
        console.log('there was no login found? That makes no sense') 
        this.other_requests.shift();
      }
    }*/

  }

  async endShift(merchant_id, user, local_location_id, ticket_counter) {
    let vat_tariff_amount_1 = '2100'
    let vat_tariff_amount_2 = '1200'
    let vat_tariff_amount_3 = ' 600'
    let vat_tariff_amount_4 = ' 000'

    let date = new Date().toLocaleDateString('af-ZA', { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/-/g, '');
    console.log(date);
    let time = new Date().toLocaleTimeString('nl-BE', { hour12: false }).replace(/:/g, '');
    console.log(time);
    let production_number_registry = this.userService.posCounter.identifier;
    let ticket_number = ("000000" + ticket_counter).slice(-6);
    let event_label = 'NS'
    let total_price = '        000'

    let vat_1_amount = '        000';
    let vat_2_amount = '        000';
    let vat_3_amount = '        000';
    let vat_4_amount = '        000';
    let plu_hash = '7e06b614'.padStart(40, '0')

    let message = date + time + user.insz + production_number_registry + ticket_number + event_label + total_price + vat_tariff_amount_1 + vat_1_amount + vat_tariff_amount_2 + vat_2_amount + vat_tariff_amount_3 + vat_3_amount + vat_tariff_amount_4 + vat_4_amount + plu_hash

    let result = await this.sendDataAndReadResponseV2('H', message);
    let data = result.data;

    if (data.fdm_identifier == 'H' && data.fdm_error_1 == '0' || (data.fdm_error_1 == '1' && data.fdm_error_2 == '01')) {
      this.sendConfirmationToBE(merchant_id, null, plu_hash, true, ticket_number, user, user.insz, local_location_id, data, false, true);
    }

    return result;

  }

  addRequestTransactionsToQueue(date, index) {
    this.fdm_requests.push({ type: 'transactions', date: date, index: index });
    this.activateFDM();
  }

  addRequestErrorsToQueue(date, index) {
    this.errors_requested = [];
    this.fdm_requests.push({ type: 'errors', date: date, index: index });
    this.activateFDM();
  }

  addRequestDumpsToQueue(index) {
    this.dumps_requested = [];
    this.fdm_requests.push({ type: 'dumps', index: index });
    this.activateFDM();
  }

  addRequestStatisticsToQueue() {
    this.fdm_requests.push({ type: 'statistics' });
    this.activateFDM();
  }

  addRequestIdentificationToQueue() {
    this.fdm_requests.push({ type: 'identification' });
    this.activateFDM();
  }

  addRequestStatusToQueue() {
    this.fdm_requests.push({ type: 'status', retry_count: 0 });
    this.activateFDM();
  }

  addRequestSendPinCodeToQueue(pin_code) {
    this.fdm_requests.push({ type: 'send_pin_code', pin_code: pin_code });
    this.activateFDM();
  }

  async basicErrorHandling(request, decifered_fdm_message) {

    // console.log("BASIC ERROR HANDLING");
    // console.log(decifered_fdm_message);

    if (decifered_fdm_message && decifered_fdm_message.type == "success") {

      let error_1 = decifered_fdm_message.data.fdm_error_1;
      let error_2 = decifered_fdm_message.data.fdm_error_2;

      // console.log("ERROR_1: " + error_1);
      // console.log("ERROR_2: " + error_2);

      if (error_1 == '0') {
        this.removeRequestFromQueue();
      } else if (error_1 == '1') {
        if (error_2 == '01') {
          this.fdmWarningSubject.next(true);
        } else if (error_2 == '02') {
          this.removeRequestFromQueue();
        } else if (error_2 == '03') {
          //geen records; gewoon request uit de queue halen
          this.removeRequestFromQueue();
        } else if (error_2 == '99') {
          this.removeRequestFromQueue();
          // TODO: log to the backend
        }
      } else if (error_1 == '2') {
        if (request.retry_count < 2) {
          request.retry_count += 1;
        } else {
          this.removeRequestFromQueue();
          this.fdmErrorSubject.next(true);
        }
      }
    } else {
      if (request.retry_count++ > 5) this.removeRequestFromQueue();
    }
  }

  async sendDataAndReadResponseV2(text1, text2 = ""): Promise<FDMResponse> {
    if (this.port && this.port.writable) {
      await this.sendData(text1, text2);
    }
    if (this.port && this.port.writable) {
      let result = await this.readDataWithTimeLimit(text1);
      return result;
    } else {
      return null;
    }
  }

  private async sendData(text1, text2) {
    let str = text1 +
      this.sequence_number.toString().padStart(2, '0') +
      this.retry_count.toString() +
      text2;

    console.log("SEND => " + str);

    let lrc = this.calculateLRC(str);

    const encoder = new TextEncoder();
    const messageUint8 = encoder.encode(str);

    const messageUint8Array = new Uint8Array(messageUint8);

    const messageWithSTX = new Uint8Array(messageUint8Array.length + 3);
    messageWithSTX.set([2], 0); // set STX at the beginning
    messageWithSTX.set(messageUint8Array, 1);
    messageWithSTX.set([3], messageWithSTX.length - 2);
    messageWithSTX.set([lrc], messageWithSTX.length - 1);

    this.writer = this.port.writable.getWriter();
    await this.writer.write(messageWithSTX);
    this.writer.releaseLock();

    this.updateSequenceNumber();

  }

  async readData(request_type): Promise<FDMResponse> {
    return new Promise(async (resolve, reject) => {
      const decoder = new TextDecoder();
      let holding_value = new Uint8Array([]);
      this.reader = this.port.readable.getReader();
      let failed = false;
      let message_fdm = null;

      try {
        while (true) {
          const { value, done } = await this.reader.read();

          let current_value = holding_value;
          holding_value = new Uint8Array(current_value.length + value.length);

          holding_value.set(current_value);
          holding_value.set(value, current_value.length);

          if (holding_value.includes(3)) {
            let start = holding_value.indexOf(2);
            let end = holding_value.indexOf(3);
            let message_arr = holding_value.slice(start + 1, end - start);
            message_fdm = decoder.decode(message_arr);
            holding_value = holding_value.slice(end + 1);
            this.reader.releaseLock();
            await this.sendAck();
            break;
          } else if (holding_value.includes(6)) {
            console.log("=> ACK");
            holding_value = holding_value.slice(holding_value.indexOf(6) + 1);
            //this.updateSequenceNumber();
          } else if (holding_value.includes(21)) {
            console.log("=> NACK");
            failed = true
            holding_value = holding_value.slice(holding_value.indexOf(21) + 1);
            this.reader.releaseLock();
            break;
          }
        }

        if (failed) {
          resolve({ type: "fail NACK" })
        } else {
          let formatted = this.deciferIt(request_type, message_fdm);
          resolve({ type: "success", data: formatted })
        };

      } catch (error) {
        // TODO: Handle non-fatal read error.
        console.log('a non-fatal read error occurred');
        resolve({ type: "fail non-fatal read error" });
      }
    });
  }

  async readDataWithTimeLimit(request_type) {
    let timeout;
    const timeoutPromise = new Promise((resolve, reject) => {
      timeout = setTimeout(() => {
        this.fdmDisconnected.next(true);
        console.log('the timeout has been reached');
        this.reader.releaseLock();
        resolve({ type: "timeout" });
      }, 3000);
    });
    const response = await Promise.race([this.readData(request_type), timeoutPromise]);
    if (timeout) { clearTimeout(timeout); }
    return response;
  }

  updateSequenceNumber() {
    if (this.sequence_number == 99) {
      this.sequence_number = 0;
    } else {
      this.sequence_number += 1;
    }
  }

  handleMessage(request_type, fdm_rest_of_message) {

    console.log("WILL NOW HANDLE MESSAGE");
    console.log(request_type);
    console.log(fdm_rest_of_message);

    let to_return = null;
    //for errors
    if (fdm_rest_of_message != '' && request_type == 'E') {

      to_return = {
        date: fdm_rest_of_message.slice(0, 8),
        time: fdm_rest_of_message.slice(8, 14),
        index: fdm_rest_of_message.slice(14, 20),
        total_requests: fdm_rest_of_message.slice(20, 26),
        error_data: {
          error_1: fdm_rest_of_message.slice(26, 27),
          error_2: fdm_rest_of_message.slice(27, 29),
          error_3: fdm_rest_of_message.slice(29, 32)
        }
      }
    } else if (fdm_rest_of_message != '' && request_type == 'T') {

      let transaction_data = this.hex2a(fdm_rest_of_message.slice(20));

      to_return = {
        date: fdm_rest_of_message.slice(0, 8),
        index: fdm_rest_of_message.slice(8, 14),
        total_requests: fdm_rest_of_message.slice(14, 20),
        transaction_data: {
          date: transaction_data.slice(0, 8),
          time: transaction_data.slice(8, 14),
          user_id: transaction_data.slice(14, 25),
          production_number_registry: transaction_data.slice(25, 39),
          ticket_number: transaction_data.slice(39, 45),
          event_label: transaction_data.slice(45, 47)
        }
      }
    } else if (fdm_rest_of_message != '' && request_type == 'D') {

      to_return = {
        index: fdm_rest_of_message.slice(0, 6),
        total_dumps_to_port3: fdm_rest_of_message.slice(6, 12),
        date_of_dump: fdm_rest_of_message.slice(12, 20),
        time_of_dump: fdm_rest_of_message.slice(20, 26),
        start_date_of_dump: fdm_rest_of_message.slice(26, 34),
        end_date_of_dump: fdm_rest_of_message.slice(34, 42)
      }

    } else if (fdm_rest_of_message != '' && request_type == 'O') {
      to_return = {
        date_oldest_transaction: fdm_rest_of_message.slice(0, 8),
        date_newest_transaction: fdm_rest_of_message.slice(8, 16),
        date_oldest_error_message: fdm_rest_of_message.slice(16, 24),
        date_newest_error_message: fdm_rest_of_message.slice(24, 32),
        number_dumps_to_port_3: fdm_rest_of_message.slice(32, 38),
        date_real_time_clock: fdm_rest_of_message.slice(38, 46),
        time_real_time_clock: fdm_rest_of_message.slice(46, 52),
        production_number_last_linked_registry: fdm_rest_of_message.slice(52, 58),
        vsc_identification_number_last_linked_to_registry: fdm_rest_of_message.slice(58)
      }


    } else if (fdm_rest_of_message != '' && request_type == 'H') {

      to_return = {
        vsc_identification_number: fdm_rest_of_message.slice(0, 14).trim(),
        date: fdm_rest_of_message.slice(14, 22).trim(),
        time: fdm_rest_of_message.slice(22, 28).trim(),
        event_label: fdm_rest_of_message.slice(28, 30).trim(),
        vsc_ticket_counter: fdm_rest_of_message.slice(30, 39).trim(),
        vsc_total_counter: fdm_rest_of_message.slice(39, 48).trim()
      }

    } else if (fdm_rest_of_message != '' && request_type == 'I') {

      to_return = {
        fdm_firmware_version_number: fdm_rest_of_message.slice(0, 20),
        fdm_communication_protocol_version: fdm_rest_of_message.slice(20, 21),
        vsc_identification_number: fdm_rest_of_message.slice(21, 35),
        vsc_version_number: fdm_rest_of_message.slice(35, 38)
      }

    }

    console.log(to_return);
    return to_return;

  }

  deciferIt(request_type, fdm_response) {
    console.log(fdm_response);
    let formatted = { fdm_identifier: null, fdm_sequence_number: null, fdm_retry_teller: null, fdm_error_1: null, fdm_error_2: null, fdm_error_3: null, fdm_production_number: null, fdm_rest_of_message: null, fdm_response: fdm_response, error_message: null, message: null };

    formatted.fdm_identifier = fdm_response.slice(0, 1);
    formatted.fdm_sequence_number = fdm_response.slice(1, 3);
    formatted.fdm_retry_teller = fdm_response.slice(3, 4);

    formatted.fdm_error_1 = fdm_response.slice(4, 5);
    formatted.fdm_error_2 = fdm_response.slice(5, 7);
    formatted.fdm_error_3 = fdm_response.slice(7, 10);

    formatted.fdm_production_number = fdm_response.slice(10, 21);
    formatted.fdm_rest_of_message = fdm_response.slice(21);

    formatted.message = this.handleMessage(request_type, formatted.fdm_rest_of_message);
    formatted.error_message = this.findErrorMessage(formatted);


    return formatted;
  }

  findErrorMessage(decifered_fdm_response) {
    let error_message = null;
    if (decifered_fdm_response.fdm_error_2 == "10" || decifered_fdm_response.fdm_error_2 == "99") {
      error_message = this.all_errors[decifered_fdm_response.fdm_error_1][decifered_fdm_response.fdm_error_2];
    } else {
      error_message = this.all_errors[decifered_fdm_response.fdm_error_1][decifered_fdm_response.fdm_error_2[1]];
    }

    if (error_message == "ok") {
      this.fdmErrorSubject.next(false);
      this.fdmErrorMessageSubject.next(null);
    } else {
      console.log("IS THIS THE REASON THE ERROR IS SET?????");
      console.log(error_message);
      console.log(decifered_fdm_response);
      //this.fdmErrorSubject.next(true);
      this.fdmErrorMessageSubject.next(error_message);
    }

    return error_message;
  }

  async getFDMStatus(): Promise<FDMResponse> {

    console.log("getFDMStatus()");

    let fdm_response = await this.sendDataAndReadResponseV2('S', '');
    let data = fdm_response.data;

    console.log("FDM Response: ");
    console.log(fdm_response);

    this.status = fdm_response;

    if (data) {

      console.log("DATA RETURNED REGARDING STATUS =>");
      console.log(data);

      if (data.fdm_error_1 == '0' || (data.fdm_error_1 == '1' && data.fdm_error_2 == '01')) {
        this.fdmDisconnected.next(false);
      } else if (data.fdm_error_1 == '1') {
        if (data.fdm_error_2 == '02') {
          console.log('the status has already been requested');
        } else if (data.fdm_error_2 == '03') {
          console.log('there was no status found');
        }
        this.fdmDisconnected.next(true);
      } else {
        this.fdmDisconnected.next(true);
      }
    }

    return fdm_response;
  }

  private removeRequestFromQueue() {
    this.fdm_requests.shift();
  }

  async sendAck() {
    let writer = this.port.writable.getWriter();
    await writer.write(new Uint8Array([6]));
    writer.releaseLock();
  }

  setTaxAmounts(order) {
    let tariffs = [{ tariff: 0.21, vat_amount: '' }, { tariff: 0.12, vat_amount: '' }, { tariff: 0.06, vat_amount: '' }, { tariff: 0, vat_amount: '' }];

    for (let combo of tariffs) {
      let vat_amount = order.vat_info.find(x => x.tariff == combo.tariff)?.excl;
      if (order.order_type == 'refund') { vat_amount *= -1; }

      if (vat_amount) {
        combo.vat_amount = (vat_amount * 100).toFixed(0).toString().padStart(3, '0').padStart(11, ' ');
      } else {
        combo.vat_amount = '        000'
      }
    }

    return tariffs
  }

  calculateLRC(message) {
    let lrc = 0;
    for (let i = 0; i < message.length; i++) {
      lrc = (lrc + message.charCodeAt(i)) & 0xff;
    }
    lrc = (((lrc ^ 0xff) + 1) & 0xff);
    return lrc;
  }

  async generatePLUCode(order, event_label) {
    let total_string = '';

    for (let ol of order.order_lines) {
      if (event_label == 'NS' || event_label == 'NR') {
        total_string += this.generatePLUCodeForOrderLine(ol, ol.amount);
      } else {
        if (ol.plus > 0) {
          total_string += this.generatePLUCodeForOrderLine(ol, ol.plus);
        }
        if (ol.minus > 0) {
          total_string += this.generatePLUCodeForOrderLine(ol, ol.minus);
        }
      }
    }

    for (let price_modifier of order.order_order_price_modifiers) {
      total_string += this.generatePLUCodeForPriceModifier(price_modifier);
    }

    let response = await this.calculateSha1(total_string);
    return response
  }

  generatePLUCodeForPriceModifier(price_modifier) {
    let combined_plu_code = '';

    price_modifier.vat_info.forEach(vi => {
      let amount = '0001';
      let name = this.replaceSpecialCharacters(price_modifier.name);
      name = name.replace(/[^a-zA-Z0-9]/g, '').substring(0, 20).padEnd(20, ' ').toUpperCase();
      let price = (+vi.incl).toFixed(2).toString().replace(/[^0-9]/g, '').padStart(8, '0');
      let tax_code = 'D';
      if (vi) { tax_code = vi.tariff == 0.21 ? 'A' : vi.tariff == 0.12 ? 'B' : vi.tariff == 0.06 ? 'C' : 'D'; }

      let plu_code = amount + name + price + tax_code;
      combined_plu_code += plu_code;
    });

    return combined_plu_code;
  }

  generatePLUCodeForOrderLine(ol, amount) {
    // if the order unit is kg, multiply the amount by 1000 to get the amount in grams
    if (ol.order_unit && ol.order_unit == 'kg') { amount = amount * 1000; }
    amount = amount.toString().replace(/[^0-9]/g, '').padStart(4, '0');

    let name = this.replaceSpecialCharacters(ol.product_name);
    // remove everything from name that is not a letter or number, take the first 20 characters and pad with spaces to 20 characters
    name = name.replace(/[^a-zA-Z0-9]/g, '').substring(0, 20).padEnd(20, ' ').toUpperCase();
    // the price equals the price as a string, without comma or dot, padded to 8 characters with 0
    let price = (+ol.total_price).toFixed(2).toString().replace(/[^0-9]/g, '').padStart(8, '0');
    //  the tax code is A if the vat_info[0].tariff equals 0.21, B if it equals 0.12, C if it equals 0.06 and D if it equals 0.00
    let tax_code = 'D';
    if (ol.vat_info) { tax_code = ol.vat_info.tariff == 0.21 ? 'A' : ol.vat_info.tariff == 0.12 ? 'B' : ol.vat_info.tariff == 0.06 ? 'C' : 'D'; }

    let plu_code = amount + name + price + tax_code;
    return plu_code;
  }

  async calculateSha1(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-1', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  private replaceSpecialCharacters(input: string): string {
    const specialCharacters = [
      { find: /[ÄÅÂÁÀâäáàã]/g, replace: 'A' },
      { find: /[Ææ]/g, replace: 'AE' },
      { find: /ß/g, replace: 'SS' },
      { find: /[çÇ]/g, replace: 'C' },
      { find: /[ÎÏÍÌïîìí]/g, replace: 'I' },
      { find: /[ÊËÉÈêëéè€]/g, replace: 'E' },
      { find: /[ÛÜÚÙüûúù]/g, replace: 'U' },
      { find: /[ÔÖÓÒöôóò]/g, replace: 'O' },
      { find: /[ñÑ]/g, replace: 'N' },
      { find: /[ýÝÿ]/g, replace: 'Y' },
    ];

    let output = input;

    specialCharacters.forEach((character) => {
      output = output.replace(character.find, character.replace);
    });

    return output;
  }

  ngOnDestroy() {
    if (this.timer_get_orders) this.timer_get_orders.unsubscribe();
    if (this.timer_send_orders) this.timer_send_orders.unsubscribe();
    if (this.timer_send_request) this.timer_send_request.unsubscribe();
    if (this.timer_no_port) this.timer_no_port.unsubscribe();
  }
}
