import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';

import { Merchant } from '../models/merchant';

import { environment } from '../../environments/environment';

declare var google;

@Injectable()
export class AdvertisementService {


	constructor(
		private apiService: ApiService,
		private userService: UserService
	) {
	}

	getAdvertisements(search?: string) {
  		return this.apiService.get('api/v1/images?' + search, this.userService.access_token);
  	}

  	createAdvertisementLog(advertisement, location = null) {
  		let user_id = null;
  		if (this.userService.user) user_id = this.userService.user.id;

  		let order_id = null;
  		if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) order_id = this.userService.basket.order.id;

    	const payload = '{ "advertisement_log": { "user_id": ' + user_id + ', "image_id": ' + advertisement.id + ', "location": "' + location + '", "order_id":' + order_id + '} }';
    	return this.apiService.post('api/v1/advertisement_logs', payload, this.userService.access_token);
  	}

}
