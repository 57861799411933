import { AfterViewInit, Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';
import { MerchantService } from '../services/merchant.service';

import { Location as AngularLocation } from '@angular/common';

import { AppHelper } from '../helpers/app-helper';

import {environment} from '../../environments/environment';

import { Address } from '../models/address';

import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';

import { UntypedFormControl } from '@angular/forms';

import PlaceResult = google.maps.places.PlaceResult;

import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../services/utility.service';

import { faMapMarker, faLocation } from '@fortawesome/pro-solid-svg-icons';
import { faMapMarker as faMapMarkerRegular, faLocation as faLocationRegular } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-location',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() back_goes_to = 'back';
  @Input() feature = 'page';
  @Output() completed = new EventEmitter<boolean>();

  faMapMarker = faMapMarker;
  faMapMarkerRegular = faMapMarkerRegular;
  faLocation = faLocation;
  faLocationRegular = faLocationRegular;

  getPlaces = new Subject<string>();

  public selected_address;
  public currentAddress;
  public geoAddress;

  public placeCtrl = new UntypedFormControl();
  public selectedAddress: any;

  private addressUpdateSubscription: any;

  public predictions = [];
  public placeSuggestions = [];
  private sessionToken;

  constructor(public userService: UserService,
    public orderService: OrderService,
    public merchantService: MerchantService,
    private cd: ChangeDetectorRef,
    private angularLocation: AngularLocation,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public appHelper: AppHelper,
    private translate: TranslateService,
    private util: UtilityService) {

      
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  
     this.getPlaces.pipe(
       debounceTime(400),
       distinctUntilChanged())
     .subscribe(value => {
       const service = new google.maps.places.AutocompleteService();
       if (value.length > 3) {
         if (this.userService.geoAddress) {
           let myLat = this.userService.geoAddress.latitude;
           let myLng = this.userService.geoAddress.longitude;
           let myLatLng = new google.maps.LatLng({lat: myLat, lng: myLng});
           service.getPlacePredictions({ input: value, types: ['geocode'], location: myLatLng, radius: 20000, sessionToken: this.sessionToken }, this.displaySuggestions.bind(this));
         } else {
           service.getPlacePredictions({ input: value, types: ['geocode'], sessionToken: this.sessionToken }, this.displaySuggestions.bind(this));
         } 
       }
     });
    }
    
    ngAfterViewInit() {
      //if (this.feature == 'page') this.getPlaceAutocomplete1();
      //this.placeCtrl.setValue(this.userService.basket.order.dist_location_name);
  }

  ngOnInit() {

    
    if (this.userService.address) { 
      this.currentAddress = JSON.parse(JSON.stringify(this.userService.address));
      this.currentAddress.selected = true; 
    };
    
    if (this.userService.geoAddress && !this.userService.geoAddress.city) {
      this.userService.geocode();
    } else if (this.userService.geoAddress) {
      this.geoAddress = JSON.parse(JSON.stringify(this.userService.geoAddress));
      this.geoAddress.selected = false;
    };

    this.addressUpdateSubscription = this.userService.addressUpdate.subscribe((address) => {
      if (this.userService.geoAddress && this.userService.geoAddress.city) {
        this.geoAddress = JSON.parse(JSON.stringify(this.userService.geoAddress));
      }
      this.cd.detectChanges();
    });

  }

  ngOnDestroy() {
    this.addressUpdateSubscription.unsubscribe();
  }

  setCurrentLocation() {
    this.userService.useCurrentLocation = true;
    this.userService.setAddress(this.geoAddress);

    const address_submit = JSON.parse(JSON.stringify(this.userService.address));
    address_submit.line1 += ' ' + address_submit.street_number;

    if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.scenario == 'delivery') {
      this.userService.basket.order.dist_location = { address: address_submit };
      this.userService.basket.order.dist_location_name = this.appHelper.getAddressFormatted(this.userService.address);
      this.userService.basket.order.dist_location_typecode = 'remote';
      this.userService.setValidDeliveryAddress(-1);
    }

    for (let prediction of this.predictions) { prediction.selected = false };
    if (this.currentAddress) this.currentAddress.selected = false;
    if (this.geoAddress) this.geoAddress.selected = true;

    this.cd.detectChanges();
  }

  goBack() {
    if (this.back_goes_to == 'back') this.angularLocation.back();
    else this.router.navigate([this.back_goes_to, { needsOrderDetails: true }], { relativeTo: this.route });
  }

  selectLocation() {
    this.angularLocation.back();
  }

  setFocus(prediction, event) {
    console.log(document.getElementById(prediction.place_id));
    (<HTMLElement>document.getElementById(prediction.place_id).children[1].children[1]).focus();
  }

  displaySuggestions(predictions, status) {
    if(status === 'OK') {
      this.predictions = [];
      for(let prediction of predictions) {
        prediction.selected = false;
        this.predictions.push(prediction);
      }
    }
    this.cd.detectChanges();
  }

  verifySuggestions(predictions, status) {
    console.log("VERIFY SUGGESTIONS..");
    console.log(status);
    if(status === 'OK') {
      this.predictions = [];
      for(let prediction of predictions) {
        prediction.selected = false;
        this.predictions.push(prediction);
      }

      console.log(this.predictions);

      //if only 1 good one, let's select it
      if (predictions.length == 1 && (predictions[0].types.includes('street_address') || predictions[0].types.includes('premise'))) {
          this.setPlace(predictions[0]);
      } 

    } else if (status == 'ZERO_RESULTS') {
        this.util.openSnackBar(this.translate.instant('error.unknown_address'), this.translate.instant('ok'));
    }

    this.cd.detectChanges();
  }

  setStreetNumber(prediction, event) {
    let value = prediction.structured_formatting.main_text + ' ' + event.srcElement.value + ' ' + prediction.structured_formatting.secondary_text;
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value, sessionToken: this.sessionToken }, this.verifySuggestions.bind(this));
  }

  setPlace(prediction) {

    let request = {
          placeId: prediction.place_id,
          sessionToken: this.sessionToken,
          fields: ['address_component','name','geometry']
     };

    let service = new google.maps.places.PlacesService(document.createElement('div'));

    service.getDetails(request, (place, status) => {
          if(status === 'OK') {
            
            /*let has_street_number = false;
            for (const address_component of place.address_components) {
              if (address_component.types.includes('street_number')) has_street_number = true;
            }

            if (has_street_number) {*/

              for (let prediction of this.predictions) prediction.selected = false;
              prediction.selected = true;

              this.selected_address = prediction.description;
              
              let goodPlace = JSON.parse(JSON.stringify(place));
              
              goodPlace.latitude = place.geometry.location.lat();
              goodPlace.longitude = place.geometry.location.lng();
              goodPlace.address = new Address();
              
              this.userService.useCurrentLocation = false;
              this.userService.address = new Address();

              this.userService.address.latitude = goodPlace.latitude;
              this.userService.address.longitude = goodPlace.longitude;

              this.userService.setAddressFromAddressComponents(this.userService.address, goodPlace.address_components);

              if (this.currentAddress) { this.currentAddress.selected = false };
              if (this.geoAddress) { this.geoAddress.selected = false };

              const address_submit = JSON.parse(JSON.stringify(this.userService.address));
              address_submit.line1 += ' ' + address_submit.street_number;

              if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.scenario == 'delivery') {
                this.userService.basket.order.dist_location = { address: address_submit };
                this.userService.basket.order.dist_location_name = this.appHelper.getAddressFormatted(this.userService.address);
                this.userService.basket.order.dist_location_typecode = 'remote';
                this.userService.setValidDeliveryAddress(-1);
              }

              this.cd.detectChanges();
            
            //} 

          }

        });

    /*if (place.has_street_number) {

    this.userService.useCurrentLocation = false;
    this.userService.address = new Address();

    this.userService.address.latitude = place.latitude;
    this.userService.address.longitude = place.longitude;

    this.userService.setAddressFromAddressComponents(this.userService.address, place.address_components);

    const address_submit = JSON.parse(JSON.stringify(this.userService.address));
    address_submit.line1 += ' ' + address_submit.street_number;

    if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.scenario == 'delivery') {
      this.userService.basket.order.dist_location = { address: address_submit };
      this.userService.basket.order.dist_location_name = this.appHelper.getAddressFormatted(this.userService.address);
      this.userService.basket.order.dist_location_typecode = 'remote';
    }

    for (let place of this.placeSuggestions) { place.selected = false };
    if (this.currentAddress) { this.currentAddress.selected = false };
    if (this.geoAddress) { this.geoAddress.selected = false };
    place.selected = true;

    this.cd.detectChanges();

    }*/

  }

  displayPlace(place) {
    if (place) return place.formatted_address;
  }

  close() {
    this.completed.emit();
  }

  saveLocation() {
    if (this.userService.address) this.completed.emit();
  }

}