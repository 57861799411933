<div *ngIf="navigation" 
class="selected-product"
style="height: 50vh;">
	<app-location (completed)="updateDeliveryAddress()"></app-location>
</div>

<div class="faded-bg" 
(click)="backgroundClicked()"
*ngIf="navigation" 
[@fadeInOut]>
</div>

<ng-container *ngIf="userService.basket.order.scenario == 'waiter' && !merchantService.pos; else nonWaiter">

	<button mat-flat-button (click)="openWaiterLocationSheet()" 
			style="width:100%;border:1px solid;"
			[ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color: nil, 'border-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color: nil}">
		<ng-container *ngIf="!userService.basket.order.dist_location_name">{{ 'scenario.select_table' | translate }}</ng-container>
		<ng-container *ngIf="userService.basket.order.dist_location_name">{{ userService.basket.order.dist_location_name }}</ng-container>
		<fa-icon 
                    class="secondary_low ms-3"
                    [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
                    [icon]="faCaretDown">
         </fa-icon>
	</button>

</ng-container>

<ng-template #nonWaiter>

<div class="subtitle"
	 *ngIf="template != 'template_pos' && !condensed">
	{{ 'scenario.subtitle' | translate }}
</div>

<div class="{{ template }} scenarios d-flex align-items-center font-dosis" *ngIf="userService.basket.order.scenario">
	
	<div class="m-1"
		 style="width:75px;text-align: center"
		 *ngIf="template != 'template_pos'">
		<img class="image"
			 src='./assets/scenarios/{{ whitelabel_for_icons }}/inverted/{{ userService.basket.order.scenario }}.png'/>
	</div>


	<!-- POS display -->
	<div 	style="display:inline-flex;"
			*ngIf="template == 'template_pos'">
	
		<div *ngFor="let scenario of scenariosForSheet">

			<div 	class="d-flex align-items-center justify-content-center clickable" 
				style="height: 60px; width:60px; text-align: center; border-radius: 10px; background-color: darkgray; margin-right: 7px;"
				[ngStyle]="{'background-color': userService.basket.order.scenario == scenario.value ? (fol ? '#f0513f' : merchantService.merchant.primary_color) : 'darkgrey'}"
				(click)="selectScenario(scenario)">
					<img style="max-width: 4vh; max-height: 4vh;"src='./assets/scenarios/{{ whitelabel_for_icons }}/{{ scenario.value }}.png'/>
			</div>

		</div>

	</div>


	<div style="flex-grow:1;">
		<div 	*ngIf="!merchantService.pos"
				class="display-inline">
			<strong>
				{{ 'scenario.' + userService.basket.order.scenario | translate | capitalizeFirst}}
			</strong>
			<i 	class="ps-2 clickable no_selection secondary_low bold" 
				[ngStyle]="{'color': !fol &&  merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
				*ngIf="scenariosForSheet.length > 1"
				(click)="openScenarioSheet()">
				<fa-icon 
                    class="secondary_low"
                    [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
                    [icon]="faEdit">
              </fa-icon>
				{{ 'scenario.edit' | translate }}
			</i>
		</div>
		
		<ng-container *ngIf="userService.basket.order.scenario == 'roomservice'">

			<div class="clickable" (click)="openRoomserviceLocationSheet()">
            	<ng-container *ngIf="userService.basket.order.dist_location_name">{{ userService.basket.order.dist_location_name }} 
            		<ng-container *ngIf="rooms && rooms.length > 1">
            			<i>
            			<fa-icon 
                    		class="ps-1 secondary_low"
                    		[ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
                    		[icon]="faEdit">
              			</fa-icon>
          				<span 	class="secondary_low bold"
          						[ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}">
          					{{ 'scenario.edit' | translate }}
          				</span>
          				</i>
            		</ng-container>
            	</ng-container>
            	<mat-error *ngIf="!userService.basket.order.dist_location_name"><strong><i>{{ 'scenario.choose_your_location' | translate }}</i></strong></mat-error>
          	</div>

		</ng-container>	


		<ng-container *ngIf="userService.basket.order.scenario == 'waiter' || userService.basket.order.scenario == 'table'">

			<div class="clickable" (click)="openWaiterLocationSheet()">
            	<ng-container *ngIf="userService.basket.order.dist_location_name">{{ userService.basket.order.dist_location_name }} 
            		<ng-container *ngIf="tables && tables.length > 1">
            			<i>
            			<fa-icon 
                    		class="ps-1 secondary_low"
                    		[ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
                    		[icon]="faEdit">
              			</fa-icon>
          				<span 	class="secondary_low bold"
          						[ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}">
											{{ 'scenario.edit' | translate | capitalizeFirst }}
          				</span>
          				</i>
            		</ng-container>
            	</ng-container>
            	<mat-error *ngIf="!userService.basket.order.dist_location_name"><strong><i>{{ 'scenario.select_table' | translate | capitalizeFirst }}</i></strong></mat-error>
          	</div>

		</ng-container>	

		<ng-container *ngIf="userService.basket.order.scenario == 'catering'">

			<div class="clickable" (click)="openCateringLocationSheet()">
            	<ng-container *ngIf="userService.basket.order.location">{{ userService.basket.order.location }} 
            		<i>
            		<fa-icon 
                    		class="secondary_low"
                    		[ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
                    		[icon]="faEdit">
              			</fa-icon>
              		<span class="secondary_low bold"
              			  [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}">
              		{{ 'scenario.edit' | translate }}
              		</span>
              		</i>
            	</ng-container>
            	<mat-error *ngIf="!userService.basket.order.location"><strong><i>{{ 'scenario.choose_your_catering_location' | translate }}</i></strong></mat-error>
          	</div>

		</ng-container>	

		<ng-container *ngIf="userService.basket.order.scenario == 'delivery'">
		
			<div class="d-flex w-100 align-items-center clickable" (click)="selectNavigation()">
				<div>{{ userService.basket.order.dist_location_name }}</div>
				<mat-error *ngIf="!userService.basket.order.dist_location_name"><strong><i>{{ 'complete_address' | translate }}</i></strong></mat-error>
				<!--
				<div style="flex-grow:1;"><app-location [feature]="'inline'" (completed)="setDeliveryAddress()"></app-location></div>
				-->
				<div class="ms-1"
					 *ngIf="userService.basket.order.order_type == 'personal'">
					<mat-icon style="width:12px !important;height:12px !important;font-size:12px">edit</mat-icon>
				</div>
			</div>

			<mat-error *ngIf="userService.basket.validDeliveryAddress == 0"><strong><i>{{ 'scenario.no_delivery_on_chosen_address' | translate }}</i></strong></mat-error>

			<div *ngIf="userService.basket.validDeliveryAddress == 1 && userService.address"
				 class="pt-1">
				<input  type="text" 
						style="margin-left: 10px; padding:5px; border:1px solid darkgrey;border-radius:5px; width: 100%;"
						(change)="adaptAddress()"
						[(ngModel)]="userService.address.line2"
						placeholder="{{ 'location.add_address_line2' | translate }}"
						[disabled]="userService.basket.order.order_type == 'group'">
			</div>


		</ng-container>

	</div>
	
	<div style="width:50px;text-align:right;">
		<ng-container *ngIf="userService.basket.order.scenario && !scenarioEnabled()">
			<mat-icon class="secondary_400">warning</mat-icon>
		</ng-container>
	</div>

</div>

<ng-container *ngIf="!merchantService.pos && userService.basket.order.scenario == 'takeaway'">

			<div class="container clickable p-3 m-1" *ngIf="takeaway_locations && takeaway_locations.length > 1">
				<div 
						*ngFor="let location of takeaway_locations"
						(click)="selectLocalLocation(location);"
						class="clickable p-3 m-3"
						[ngClass]=" {'location-selected bg-light': location.name ===  userService.basket.order.dist_location_name }"
						[ngStyle]="{'border-color': location.name ===  userService.basket.order.dist_location_name && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
				<div class="row">
					<div class="col-2">

						<fa-icon *ngIf="location.typecode == 'vending_machine'" 
							size="2x"
		                    class="secondary_low"
		                    [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
		                    [icon]="faShopLock">
              			</fa-icon>

						<fa-icon *ngIf="location.typecode == 'counter'" 
							size="2x"
		                    class="secondary_low"
		                    [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
		                    [icon]="faShop">
              			</fa-icon>
					</div>
					<div class="col-10"
						[ngClass]=" {'text-selected': location.name ===  userService.basket.order.dist_location_name }">
						{{ location.name }}
					</div>
				</div>
				</div>
			</div>

		</ng-container>	

</ng-template>
