import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';


@Component({
  selector: 'date-sheet',
  templateUrl: './date-sheet.component.html',
  styleUrls: ['./date-sheet.component.css']
})
export class DateSheetComponent implements OnInit {

  public dateSelected = new EventEmitter();

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    for (let dist_timing_target of this.data.dist_timing_targets) {
       let times_found = false;
       for (let slot of dist_timing_target.slots) {
         if (slot.is_enabled || slot.price_reason) {
           times_found = true;
           break;
         }
       }
       if (times_found) dist_timing_target.is_enabled = true;
       else dist_timing_target.is_enabled = false;
    }
  }

  selectDate(event, date, index) {
  	this.dateSelected.emit(index);
  }

}
