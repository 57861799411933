/* src/app/basketbar/basketbar.component.scss */
button:disabled {
  opacity: 0.5;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}
.bar {
  color: white;
  opacity: 1;
  width: 100vw;
  z-index: 500;
  position: fixed;
  bottom: 0;
}
.confirmation {
  font-weight: bolder;
}
.price-flex {
  line-height: 1;
}
.price {
  font-weight: bold;
  font-size: 20px;
}
.price.price-kiosk {
  font-size: 2.5rem;
  padding-bottom: 10px;
}
.mat-mini-fab {
  background-color: white !important;
  font-size: 1.2em;
  font-weight: bolder;
  box-shadow: 0 0 0 0 !important;
}
.white {
  color: white !important;
}
mat-icon {
  vertical-align: middle;
}
.subtotals {
  height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
}
.content {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  padding: 0px;
}
.content-popup {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 60px;
  border: 0px;
}
.left-button {
  flex-grow: 0;
  flex-shrink: 0;
  width: 260px !important;
}
.right-button {
  flex-grow: 0;
  flex-shrink: 0;
  width: 260px !important;
}
.order-details-kiosk {
  font-size: 1.5rem !important;
}
.subtotals {
  color: #ffffff;
}
@media (min-width: 768px) {
  .bar-popup {
    position: absolute;
    bottom: -50px;
    width: 100%;
  }
}
.template_kiosk.basketbar {
  width: 100%;
  position: fixed;
  bottom: 100px;
  height: calc(50% - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 996;
}
.template_kiosk.content {
  height: 100px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 0px;
}
@media (max-width: 991px) {
  .template1.basketbar {
    width: 100%;
    position: fixed;
    bottom: 55px;
    height: 285px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 996;
  }
  .template2.basketbar {
    width: 100%;
    position: fixed;
    bottom: 55px;
    height: 285px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 996;
  }
  .template1.content {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 0px;
  }
  .template2.content {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 0px;
  }
}
@media (min-width: 992px) {
  .template1.bar.bar-web {
    width: 30vw;
    right: 15px;
    bottom: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 2px rgb(177, 177, 177);
  }
  .template2.bar.bar-web {
    width: 30vw;
    right: 15px;
    bottom: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 2px rgb(177, 177, 177);
  }
}
.template1.bar-relative {
  position: relative;
  width: 100%;
  border-radius: 5px;
  margin-top: 14px;
}
.template2.bar-relative {
  position: relative;
  width: 100%;
  border-radius: 5px;
}
.template_kiosk.bar-web {
  font-size: 2em;
}
/*# sourceMappingURL=basketbar.component.css.map */
