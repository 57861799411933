import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { PubnubService } from '../services/pubnub.service';

import { trigger, state, style, animate, transition } from '@angular/animations';

declare var cordova: any;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'order-notif',
  templateUrl: './order-notif.component.html',
  styleUrls: ['./order-notif.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
      opacity: 0
      })),
      transition('void <=> *', animate(1000)),
     ]),
    trigger('slideUp', [
    state('void', style({
      transform: 'translateY(150%)'
    })),
    transition('void <=> *', animate('0.5s ease-in-out')),
    ])
  ]
})
export class OrderNotifComponent implements OnInit, OnDestroy {

  @Input() order: any;
  @Output() deliverySelected = new EventEmitter<boolean>();
  private orderPushed: any;
  public delivery:boolean = false;
  private vendingMachineTimer: any;
  public vending_machine_order: any;

  constructor(	public orderService: OrderService,
  				public userService: UserService,
  				private pubnubService: PubnubService,
          private cd: ChangeDetectorRef) { }

  ngOnInit() {

  	this.orderPushed = this.pubnubService.orderPushed.subscribe(
      (pubnubOrder) => {
            if (this.order.id == pubnubOrder.id) { 
              this.order = pubnubOrder;
              console.log(this.order); 
              this.cd.detectChanges();
            }
      });

    if (this.order.dist_location_typecode == 'vending_machine') {
      this.getVendingMachineOrder();
    }
  }

  getVendingMachineOrder() {
		this.orderService.getVendingMachineOrder(this.order).subscribe(
			response => {
				this.vending_machine_order = response.vending_machine_order;
        this.cd.detectChanges();
			},
			error => {
				console.log(error);
			}
		);

    this.vendingMachineTimer = setTimeout(() => {
      this.getVendingMachineOrder();
    }, 20000);
	}

  ngOnDestroy() {
  	if (this.orderPushed) this.orderPushed.unsubscribe();
  }

  getJoynLink(joyn_qr) {
    const url = joyn_qr.substring(joyn_qr.indexOf('&content=') + 9);
    if (window.hasOwnProperty('cordova')) { cordova.InAppBrowser.open(url, '_system'); }
    else { 
      window.open(decodeURIComponent(url)); 
    }
  }

  toggleDelivery(event) {
    if (!this.delivery) this.delivery = true;
    else this.delivery = false;
    this.cd.detectChanges();
    this.deliverySelected.emit(this.delivery);
    event.stopPropagation();
  }

  hideDelivery() {
    this.delivery = false;
    this.deliverySelected.emit(this.delivery);
    this.cd.detectChanges();
  }

}
