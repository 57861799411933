/* src/app/order/order.component.scss */
.template_pos.orderline {
  display: inline-block;
}
.small-icon {
  font-size: 16px;
  height: 18px;
  width: 16px;
}
.older-line-details {
  padding: 2px;
}
.older-line-details .mat-chip {
  font-size: 12px;
  min-height: 22px;
  border-radius: 5px;
  margin: 1px;
}
.older-line-details .mat-chip .mat-icon {
  height: 18px;
  font-size: 16px;
}
.mat-list-base .mat-list-item {
  min-height: 48px;
  height: auto;
}
.title {
  border-bottom: 1px solid darkgrey;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
  padding-left: 5px;
}
.double-column {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 10;
  padding-left: 5px;
}
.counter {
  border: 1px solid lightgray;
  margin-right: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}
.small {
  font-size: 0.8em;
  line-height: 1.5;
}
mat-icon {
  vertical-align: middle;
}
.kitchen-title {
  color: darkgrey;
  font-size: 0.7em;
}
.kitchen-darkgrey {
  background-color: #3f3f3f;
}
.kitchen-lightgrey {
  background-color: #1f1f1f;
}
.order-button-kiosk {
  font-size: 1.5rem;
}
/*# sourceMappingURL=order.component.css.map */
