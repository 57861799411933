import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-payment-method-sheet',
  templateUrl: './payment-method-sheet.component.html',
  styleUrls: ['./payment-method-sheet.component.scss']
})
export class PaymentMethodSheetComponent implements OnInit {

  public paymentMethodSelected = new EventEmitter();

  constructor(public userService: UserService,
  			  @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    for (let payment_method of this.data.payment_methods) {
      if (!payment_method.description) payment_method.description = payment_method.value;
      if (payment_method.description =='CASH') payment_method.description += "_" + this.userService.basket.order.scenario;
    }
  }

  selectPaymentMethod(payment_method) {
  	this.paymentMethodSelected.emit(payment_method);
  }

}
