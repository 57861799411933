/* src/app/scenario/scenario.component.scss */
.image {
  max-width: 30px;
  max-height: 30px;
}
.scenarios {
  font-size: 1em;
  width: 100%;
}
.template_pos {
  font-size: 0.9em;
}
.location-selected {
  border: 2px solid;
  border-radius: 10px;
}
.text-selected {
  font-weight: bold;
}
/*# sourceMappingURL=scenario.component.css.map */
