/* src/app/timing/timing.component.css */
.template_pos {
  font-size: 0.9em;
}
.timing-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}
.timing-button {
  margin: 5px;
}
.timings {
  font-size: 1em;
  width: 100%;
}
.image {
  max-width: 30px;
  max-height: 30px;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  width: 200px;
  background-color: black;
  opacity: 0.3;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  left: 100px;
  z-index: 1;
}
/*# sourceMappingURL=timing.component.css.map */
