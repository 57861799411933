<div class="mb-2" [ngStyle]="order.device_type != 'Kiosk' && {'font-size': font_size + 'px'}">
	<div style="position:relative" *ngFor="let order_line of order.order_lines; index as i;">
		<ng-container *ngIf="!event_label_is_proforma || order_line.amount == order_line.plus || order.order_location_typecode != 'counter' else plusAndMinus">
	
		<div class="{{ template }} d-flex"
			 *ngIf="(order_line.amount !== 0 || shown_as_tab || order.device_type == 'POS') && taxons_shown && merchantService.merchant && order_line.ordering == 0">
					<div class="title column bold"
					[ngClass]=" {'kitchen-title': merchantService.kitchen }">
				<div class="d-flex">
					<div class="counter"
						 *ngIf="!order_line.order_unit">{{ categoryAmount(order_line.taxon_id) }}
					</div>
					<div>
						{{ order_line.taxon_name }}
					</div>
				</div>
			</div>
		</div>
	
		<div 	class="d-flex align-items-top justify-content-between"
				[ngClass]=" {'kitchen-darkgrey': merchantService.kitchen && i%2 == 0, 'kitchen-lightgrey': merchantService.kitchen && i%2 != 0, 'align-items-baseline': shown_as_tab  }"
				*ngIf="(mode === 'refund' || order_line.amount > 0 && !notToBeShown(order_line.product))|| shown_as_tab || order.device_type == 'POS'"
				style="position: relative">
			<ngx-spinner 	name="spinner_{{order_line.id}}" 
						type="ball-pulse" 
						[fullScreen]="false" 
						bdColor="rgba(220,220,220,0.8)" 
						size="medium">
			</ngx-spinner>
			<div class="column">
				<div class="d-flex align-items-center">
	
					<ng-container>
						<div class="d-flex align-items-center my-1">
							<div *ngIf="editable && !order_line.product_is_fee">
								<button (click)="updateAmount($event, order_line, false)" 
												[disabled]="(order.order_type == 'refund' && order_line.amount > -1) || order_line.is_loyalty || (order_line.product_in_stock && order_line.amount >= order_line.product_in_stock)"
												type="button"
												class="btn btn-sm btn-light">
									<fa-icon [icon]="faMinus"></fa-icon>
								</button>
							</div>

							<div 	class="{{ template }} mx-1"
									style="min-width: 30px; text-align:center;display:inline">
								<ng-container *ngIf="!order_line.order_unit; else hasUnit;">
									<span *ngIf="order_line">{{ order_line.amount }}</span>
								</ng-container>
								<ng-template #hasUnit>
									<span>{{ order_line.order_amount }}</span>
									<span *ngIf="order_line.order_unit != 'pieces'"> {{ 'order_unit.' + order_line.order_unit | translate }}</span>
								</ng-template>
								<span style="display:inline;" *ngIf="!editable && (!order_line.order_unit || order_line.order_unit == 'pieces')">x</span>
							</div>
	
							<div *ngIf="editable && !order_line.product_is_fee">
								<button (click)="updateAmount($event, order_line, true)"
												[disabled]="(order.order_type == 'refund' && order_line.amount > -1) || order_line.is_loyalty || (order_line.product_in_stock && order_line.amount >= order_line.product_in_stock) || order_line.plus_disabled"
												type="button"
												class="btn btn-sm btn-light">
									<fa-icon [icon]="faPlus"></fa-icon>
								</button>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
	
			<div class="d-flex flex-column double-column">
				<div class="{{ template }} orderline ms-3">
	
				<ng-container *ngIf="!condensed">
					<div class="d-flex flex-row">
						<div (click)="comment_editable ? focusOnInput(order_line) : null">
							<fa-icon [icon]="faMessagePlus"
											class="fa-lg pt-1 me-2 clickable"
											*ngIf="comment_editable">
							</fa-icon>
						</div>

						<div>
							<div class="">{{ order_line.product_name }}</div>
							<div class="small display-inline" *ngIf="order_line.order_part_removes.length > 0">
								<div *ngFor="let order_part_remove of order_line.order_part_removes">
									<mat-icon class="small-icon">remove_circle</mat-icon> {{ order_part_remove.name }}
								</div>
							</div>
							<div class="small display-inline" *ngIf="order_line.order_options.length > 0">
								<ng-container *ngFor="let order_option of order_line.order_options">
									<div *ngIf="!order_option.base_unit && order_option.order_option_values.length > 0">
										{{ order_option.name }}:
										<span *ngFor="let order_option_value of order_option.order_option_values; index as i">
											<span *ngIf="order_option_value.unit">{{ order_option_value.amount }}</span>
											<span> {{ order_option_value.name }}</span>
											<span *ngIf="order_option.order_option_values.length > i+1">,</span>
										</span>
									</div>
								</ng-container>
							</div>
							<ng-container>
								<ng-container *ngFor="let order_part_set of order_line.order_part_sets">
									<div class="small display-inline" *ngFor="let order_part_add of order_part_set.order_part_adds">
										<mat-icon class="small-icon">add_circle</mat-icon>
										<span>{{ order_part_add.name }}</span>
									</div>
								</ng-container>
							</ng-container>
							<ng-container *ngIf="order_line.comment && !order_line.edit_comment">
									<div class="small display-inline">
										{{ order_line.comment }}
									</div>
							</ng-container>
						</div>
					</div>
				</ng-container>
	
				<ng-container *ngIf="condensed">
					<div class="m-1" style="display:inline;">
	
						<div style="display:inline;"
							 (click)="comment_editable ? openReferenceDialog(order_line) : null">
							<fa-icon [icon]="faMessagePlus" class="fa-lg pt-1 me-1 clickable"
									 *ngIf="comment_editable"></fa-icon>
							{{ order_line.product_name }}
						</div>
	
						<ng-container *ngIf="order_line.order_part_removes.length > 0">
								<div class="ms-1" style="display:inline-block; background-color:red; border:1px solid red; border-radius:5px;" *ngFor="let order_part_remove of order_line.order_part_removes">
									<mat-icon class="small-icon">remove_circle</mat-icon> {{ order_part_remove.name }}
								</div>
						</ng-container>
	
						<ng-container>
							<ng-container *ngFor="let order_part_set of order_line.order_part_sets">
								<div class="ms-1" style="display:inline-block; background-color:green; border:1px solid green; border-radius:5px;" *ngFor="let order_part_add of order_part_set.order_part_adds">
									<mat-icon class="small-icon">add_circle</mat-icon>
									<span>{{ order_part_add.name }}</span>
								</div>
							</ng-container>
						</ng-container>
	
						<ng-container *ngIf="order_line.order_options.length > 0">
								<ng-container *ngFor="let order_option of order_line.order_options">
									<div class="ms-1 bg-info border-info" style="display:inline-block; border:1px solid; border-radius:5px;" *ngIf="!order_option.base_unit && order_option.order_option_values.length > 0">
										{{ order_option.name }}:
										<span *ngFor="let order_option_value of order_option.order_option_values; index as i">
											<span *ngIf="order_option_value.unit">{{ order_option_value.amount }}</span>
											<span> {{ order_option_value.name }}</span>
											<span *ngIf="order_option.order_option_values.length > i+1">,</span>
										</span>
									</div>
								</ng-container>
						</ng-container>
	
						<ng-container *ngIf="order_line.comment">
							<div class="ms-1 bg-info border-info" style="display:inline-block; border:1px solid; border-radius:5px;">
								{{ order_line.comment }}
							</div>
						</ng-container>

						<ng-container *ngIf="order_line.custom_price_comment">
							<div class="ms-1 bg-info border-info" style="display:inline-block; border:1px solid; border-radius:5px;">
								{{ order_line.custom_price_comment }}
							</div>
						</ng-container>

	
					</div>
				</ng-container>
				</div>
	
	
			</div>
	
			<!-- HIER ZITTEN DE PRIJZEN -->
			<div *ngIf="!order_line.is_loyalty && shown_as_tab"
					 class="{{ template }} column bold align-right me-2"
					 style="max-width: 90px;">
				<mat-form-field>
				<input matInput type="number" [(ngModel)]="order_line.new_unit_price" (change)="onSaveTabOrderLineModifierInASecond(order_line)">
				</mat-form-field>
			</div>
	
			<div *ngIf="tab_order_line_modifiers?.length > 0">
				{{ order_line.unit_price * order_line.amount | currency:'EUR' }}
			</div>
	
			<div *ngIf="!order_line.is_loyalty && order_line.price_shown && prices_shown && tab_order_line_modifiers?.length == 0" 
				 class="{{ template }} column bold align-right"
				 [ngClass]="{'clickable': merchantService.pos}"
				 (click)="merchantService.pos ? setCustomPrice(order_line) : ''">
				 {{ order_line.total_price | currency:'EUR' }}
			</div>
	
			<div *ngIf="!order_line.is_loyalty && !order_line.price_shown && prices_shown"
				 class="{{ template }} column bold align-right">
				 p.m.
			</div>
	
			<div *ngIf="order_line.is_loyalty == true"
				 class="{{ template }} d-flex flex-row column bold align-right justify-content-end">
								<fa-icon
								class="pe-1"
														[icon]="faCoin">
											</fa-icon>
				 {{ order_line.loyalty_points }}
			</div>
	
			<div *ngIf="!order_line.is_loyalty && shown_as_tab">
				<fa-icon [icon]="faXMark" class="ms-3" (click)="setOrderLineAmountToZero(order_line)">
				</fa-icon>
			</div>
		</div>
	</ng-container>

	<ng-template #plusAndMinus>
		<div class="{{ template }} d-flex"
			*ngIf="taxons_shown && merchantService.merchant && order_line.ordering == 0">
			<div class="title column bold"
				 [ngClass]=" {'kitchen-title': merchantService.kitchen }">
		 		<div class="d-flex">
			 		<div class="counter"
							*ngIf="!order_line.order_unit">{{ categoryAmount(order_line.taxon_id) }}
			 		</div>
			 		<div>
						{{ order_line.taxon_name }}
					</div>
				</div>
	 		</div>
 		</div>

		<!-- PLUS -->

		<div class="d-flex align-items-top justify-content-between">
			<div class="column">
				<div class="d-flex align-items-center">
					<ng-container>
						<div class="d-flex align-items-center my-1">
							<div class="{{ template }} mx-1"
										style="min-width: 30px; text-align:center;display:inline">
								<ng-container *ngIf="!order_line.order_unit; else hasUnit;">
									<span *ngIf="order_line">{{ order_line.plus }}</span>
								</ng-container>
								<ng-template #hasUnit>
									<span>{{ order_line.order_amount }}</span>
									<span *ngIf="order_line.order_unit != 'pieces'"> {{ 'order_unit.' + order_line.order_unit | translate }}</span>
								</ng-template>
								<span style="display:inline;" *ngIf="!editable && (!order_line.order_unit || order_line.order_unit == 'pieces')">x</span>
							</div>
						</div>
			 		</ng-container>
		 		</div>
	 		</div>

			<div class="{{ template }} orderline double-column ms-3">
				<ng-container *ngIf="!condensed">
					<div class="">{{ order_line.product_name }}</div>
					<div class="small display-inline" *ngIf="order_line.order_part_removes.length > 0">
						<div *ngFor="let order_part_remove of order_line.order_part_removes">
							<mat-icon class="small-icon">remove_circle</mat-icon> {{ order_part_remove.name }}
						</div>
					</div>

					<div class="small display-inline" *ngIf="order_line.order_options.length > 0">
						<ng-container *ngFor="let order_option of order_line.order_options">
							<div *ngIf="!order_option.base_unit && order_option.order_option_values.length > 0">
								{{ order_option.name }}:
								<span *ngFor="let order_option_value of order_option.order_option_values; index as i">
									<span *ngIf="order_option_value.unit">{{ order_option_value.amount }}</span>
									<span> {{ order_option_value.name }}</span>
									<span *ngIf="order_option.order_option_values.length > i+1">,</span>
								</span>
							</div>
						</ng-container>
					</div>

					<ng-container>
						<ng-container *ngFor="let order_part_set of order_line.order_part_sets">
							<div class="small display-inline" *ngFor="let order_part_add of order_part_set.order_part_adds">
								<mat-icon class="small-icon">add_circle</mat-icon>
								<span>{{ order_part_add.name }}</span>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>

			<div class="column bold align-right">
				{{ order_line.unit_price | currency:'EUR' }}
			</div>

		<!-- HIER ZITTEN DE PRIJZEN -->
			<div *ngIf="!order_line.is_loyalty && order_line.price_shown"
					class="{{ template }} column bold align-right">
					{{ order_line.unit_price * order_line.plus | currency:'EUR' }}
			</div>

			<div *ngIf="!order_line.is_loyalty && !order_line.price_shown"
					class="{{ template }} column bold align-right">
					p.m.
			</div>

			<div *ngIf="order_line.is_loyalty == true"
					class="{{ template }} d-flex flex-row column bold align-right justify-content-end">
				<fa-icon class="pe-1"
										[icon]="faCoin">
				</fa-icon>
				{{ order_line.loyalty_points }}
			</div>

			<div *ngIf="!order_line.is_loyalty && shown_as_tab" 
					 class="{{ template }} column bold align-right me-2"
					 style="max-width: 90px;">
				<mat-form-field>
				<input matInput type="number" [(ngModel)]="order_line.new_unit_price" (change)="onSaveTabOrderLineModifierInASecond(order_line)">
				</mat-form-field>
			</div>
				
			<div *ngIf="tab_order_line_modifiers?.length > 0">
				{{ order_line.unit_price * order_line.amount | currency:'EUR' }} 
			</div>
			
			<div *ngIf="!order_line.is_loyalty && order_line.price_shown && prices_shown && tab_order_line_modifiers?.length == 0" 
				 class="{{ template }} column bold align-right"
				 [ngClass]="{'clickable': merchantService.pos}"
				 (click)="merchantService.pos ? setCustomPrice(order_line) : ''">
				 {{ order_line.total_price | currency:'EUR' }}
			</div>
			
			<div *ngIf="!order_line.is_loyalty && !order_line.price_shown && prices_shown" 
				 class="{{ template }} column bold align-right">
				 p.m.
			</div>
		</div>

		<!-- MINUS -->

		<div class="d-flex align-items-top justify-content-between">
			<div class="column">
				<div class="d-flex align-items-center">
					<ng-container>
						<div class="d-flex align-items-center my-1">
							<div class="{{ template }} mx-1"
										style="min-width: 30px; text-align:center;display:inline">
								<ng-container *ngIf="!order_line.order_unit; else hasUnit;">
									<span *ngIf="order_line">- {{ order_line.minus }}</span>
								</ng-container>
								<ng-template #hasUnit>
									<span>{{ order_line.order_amount }}</span>
									<span *ngIf="order_line.order_unit != 'pieces'"> {{ 'order_unit.' + order_line.order_unit | translate }}</span>
								</ng-template>
								<span style="display:inline;" *ngIf="!editable && (!order_line.order_unit || order_line.order_unit == 'pieces')">x</span>
							</div>
						</div>
			 		</ng-container>
		 		</div>
	 		</div>

			<div class="{{ template }} orderline double-column ms-3">
				<ng-container *ngIf="!condensed">
					<div class="">{{ order_line.product_name }}</div>
					<div class="small display-inline" *ngIf="order_line.order_part_removes.length > 0">
						<div *ngFor="let order_part_remove of order_line.order_part_removes">
							<mat-icon class="small-icon">remove_circle</mat-icon> {{ order_part_remove.name }}
						</div>
					</div>

					<div class="small display-inline" *ngIf="order_line.order_options.length > 0">
						<ng-container *ngFor="let order_option of order_line.order_options">
							<div *ngIf="!order_option.base_unit && order_option.order_option_values.length > 0">
								{{ order_option.name }}:
								<span *ngFor="let order_option_value of order_option.order_option_values; index as i">
									<span *ngIf="order_option_value.unit">{{ order_option_value.amount }}</span>
									<span> {{ order_option_value.name }}</span>
									<span *ngIf="order_option.order_option_values.length > i+1">,</span>
								</span>
							</div>
						</ng-container>
					</div>

					<ng-container>
						<ng-container *ngFor="let order_part_set of order_line.order_part_sets">
							<div class="small display-inline" *ngFor="let order_part_add of order_part_set.order_part_adds">
								<mat-icon class="small-icon">add_circle</mat-icon>
								<span>{{ order_part_add.name }}</span>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>

			<div class="column bold align-right">
				{{ order_line.unit_price | currency:'EUR' }}
			</div>

		<!-- HIER ZITTEN DE PRIJZEN -->
			<div *ngIf="!order_line.is_loyalty && order_line.price_shown"
					class="{{ template }} column bold align-right">
					- {{ order_line.unit_price * order_line.minus | currency:'EUR' }}
			</div>

			<div *ngIf="!order_line.is_loyalty && !order_line.price_shown"
					class="{{ template }} column bold align-right">
					p.m.
			</div>

			<div *ngIf="order_line.is_loyalty == true"
					class="{{ template }} d-flex flex-row column bold align-right justify-content-end">
				<fa-icon class="pe-1"
										[icon]="faCoin">
				</fa-icon>
				{{ order_line.loyalty_points }}
			</div>
		</div>
	</ng-template>

	<ng-container *ngIf="order_line.edit_comment">
		<mat-form-field class="w-100">
			<mat-label>{{ 'order.remark' | translate }}</mat-label>
			<input type="text" matInput (blur)="hideInputAndSave(order_line)" [(ngModel)]="order_line.comment" id="{{order_line.id}}" (keyup.enter)="onEnter(order_line)">
		</mat-form-field>
	</ng-container>

</div>


</div>

<ng-container *ngIf="showTotal && order && order.order_lines?.length > 0">

	<div [ngStyle]="order.device_type != 'Kiosk' &&  {'font-size': font_size + 'px'}">

		<div class="d-flex" *ngIf="order.order_order_price_modifiers?.length > 0">
			<div class="column"></div>
			<div class="double-column align-right bold">{{ 'basket.subtotal' | translate }}</div>
			<div class="column align-right" *ngIf="electronic_journal"></div>
			<div class="column align-right bold">{{ order.price_before_modifiers | currency:'EUR' }}</div>
		</div>

		<ng-container *ngFor="let price_modifier of order.order_order_price_modifiers">
			<ng-container *ngIf="!electronic_journal">
				<div class="d-flex">
					<div class="column"></div>
					<div class="double-column align-right">{{ price_modifier.name }}</div>
					<div class="column align-right">{{ price_modifier.price_adapt | currency:'EUR' }}</div>
				</div>
			</ng-container>

			<ng-container *ngIf="electronic_journal">
				<div class="d-flex">
					<div class="column"></div>
					<div class="double-column align-right">{{ price_modifier.name }}</div>
					<div class="column align-right"></div>
					<div class="column align-right"></div>
				</div>
				<div class="d-flex" *ngFor="let vi of price_modifier.vat_info">
					<div class="column"></div>
					<div class="double-column align-right">{{ vi.tariff * 100 }} %</div>
					<div class="column align-right">{{ vi.excl | currency: 'EUR' }}</div>
					<div class="column align-right">{{ vi.incl | currency: 'EUR' }}</div>
				</div>
			</ng-container>
		</ng-container>

		<div class="d-flex">
			<div class="column"></div>
			<div class="double-column align-right bold">{{ 'order.total_order' | translate }}</div>
			<div class="column align-right" *ngIf="electronic_journal"></div>
			<div class="column align-right bold">
			
				<div *ngIf="order.price_shown; else pro_memory;">
					{{ order.price | currency:'EUR' }}
				</div>

				<ng-template #pro_memory>
					p.m.
				</ng-template>
				
				<div *ngIf="order.loyalty_points && order.loyalty_points > 0" 
					 class="d-flex flex-row column bold align-right justify-content-end">
					 
					 <fa-icon
									class="pe-1" 
		                      		[icon]="faCoin">
		                		</fa-icon>
		                		{{ order.loyalty_points }}
				</div>
			</div>

		</div>

		<ng-container *ngIf="electronic_journal">
			<div class="d-flex">
				<div class="column"></div>
				<div class="double-column align-right bold">Cash ontvangen</div>
				<div class="column align-right"></div>
				<div class="column align-right">{{ extra_info_ej.payment_amount | currency:'EUR' }}</div>
			</div>
			<div class="d-flex">
				<div class="column"></div>
				<div class="double-column align-right bold">Terug</div>
				<div class="column align-right"></div>
				<div class="column align-right">{{ extra_info_ej.payment_spare_change | currency:'EUR' }}</div>
			</div>
			<div class="d-flex">
				<div class="column"></div>
				<div class="double-column align-right bold">Cash betaald</div>
				<div class="column align-right"></div>
				<div class="column align-right">{{ extra_info_ej.payment_amount - extra_info_ej.payment_spare_change | currency:'EUR' }}</div>
			</div>
			<div class="d-flex mt-4">
				<div class="column">BTW%</div>
				<div class="double-column align-right">BTW</div>
				<div class="column align-right">Excl</div>
				<div class="column align-right">Incl</div>
			</div>
			<div class="d-flex" *ngFor="let vi of order.vat_info">
				<div class="column">{{ vi.tariff * 100 }} %</div>
				<div class="double-column align-right">{{ vi.price | currency: 'EUR'}}</div>
				<div class="column align-right">{{ vi.excl | currency: 'EUR'}}</div>
				<div class="column align-right">{{ vi.incl | currency: 'EUR'}}</div>
			</div>
		</ng-container>

	</div>

</ng-container>