import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MerchantService } from 'src/app/services/merchant.service';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import Keyboard from "simple-keyboard";

@Component({
  selector: 'app-custom-price-dialog',
  templateUrl: './custom-price-dialog.component.html',
  styleUrls: ['./custom-price-dialog.component.css']
})
export class CustomPriceDialogComponent {

  faCaretRight = faCaretRight;
  public original_price: string;
  public custom_price: string;
  public comment: string;
  public show_keyboard: boolean = false;
  public keyboard: Keyboard;
  public keyboard_input: any;

  constructor(
    public merchantService: MerchantService,
    public dialogRef: MatDialogRef<CustomPriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.original_price = this.data.order_line.unit_price;
  }

  ngAfterViewInit() {
    if (this.show_keyboard) {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input),
        onKeyPress: button => this.onKeyPress(button)
      });

      this.keyboard_input = 'custom_price';
    }
  }

  applyDiscount(percentage) {
    this.custom_price = (+this.data.order_line.unit_price * (100 - percentage) / 100).toFixed(2);
  }

  onChange = (input: string) => {
    if (this.keyboard_input === 'comment') { this.comment = input; }
    if (this.keyboard_input === 'custom_price') { this.custom_price = input; }
  };

  setKeyboardInput(input:string) {
    if (!this.keyboard) { return };

    if (input === 'comment') { this.keyboard.setInput(this.data.order_line.custom_price_comment); }

    this.keyboard_input = input;
  }

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

  onKeyPress = (button: string) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  onSave() {
    let data = {
      custom_price: this.custom_price,
      comment: this.comment
    }
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
