<div
 style="background-color: #ececec; z-index:9999;width: 100vw;max-width:1200px;position:fixed; bottom:30px;left: 50%;transform: translate(-50%);">
  <div class="simple-keyboard"></div>
</div>

<div mat-dialog-title>{{ 'pos_custom_price.title' | translate }}</div>
<div mat-dialog-content>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row">
      <mat-form-field>
        <mat-label>{{ 'pos_custom_price.original_label' | translate }}</mat-label>
        <input matInput [(ngModel)]="original_price" disabled="true">
      </mat-form-field>
      <div class="d-flex justify-content-center me-2 ms-1">
        <fa-icon [icon]="faCaretRight" class="fa-4x"></fa-icon>
      </div>
      <mat-form-field>
        <mat-label>{{ 'pos_custom_price.label' | translate }}</mat-label>
        <input (focus)="setKeyboardInput('custom_price')"
              matInput
              [(ngModel)]="custom_price">
      </mat-form-field>
    </div>
    <div class="d-flex">
      <button mat-stroked-button class="me-1" (click)="applyDiscount(5)">-5%</button>
      <button mat-stroked-button class="me-1" (click)="applyDiscount(10)">-10%</button>
      <button mat-stroked-button class="me-1" (click)="applyDiscount(25)">-25%</button>
      <button mat-stroked-button class="me-1" (click)="applyDiscount(50)">-50%</button>
      <button mat-stroked-button class="me-1" (click)="applyDiscount(75)">-75%</button>
      <button mat-stroked-button class="me-1" (click)="applyDiscount(80)">-80%</button>
      <button mat-stroked-button (click)="applyDiscount(100)">-100%</button>
    </div>
    <mat-form-field class="mt-3">
      <mat-label>{{ 'pos_custom_price.comment' | translate }}</mat-label>
      <input (focus)="setKeyboardInput('comment')"
            type="text"
            matInput
            [(ngModel)]="comment">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="onCancel()">{{ 'cancel' | translate }}</button>
  <button mat-raised-button
    [ngStyle]="{ 'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
    (click)="onSave()">{{ 'save' | translate }}</button>
</div>