<!--
<div>
              <ngx-spinner  name="basketbar" 
                            [fullScreen]="false" 
                            type="ball-pulse" 
                            size="small"></ngx-spinner>
              <button   class="ms-1 align-items-center" 
                        color="primary"
                        mat-raised-button 
                        [ngStyle]="{'background-color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
                        (click)="doPayment()">
                                Betalen
            </button>
</div>
-->

<div *ngIf="position == 'main'">
  
  <div class="p-3">
    <button mat-flat-button
          (click)="goNext($event)" 
          class="voucher-button"
          style="width: 100%; font-size: 1.6em;"
          [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}">
      {{ 'basketbar.start_order' | translate }}
    </button>
  </div>

</div> 


  <div class="{{ template }} basketbar"
       [@fadeInOut]
        *ngIf="position != 'main' && animationState != 'small'">

    <div class="d-flex flex-column" style="height:100%">
            
      <div class="px-3 pb-3">
        <order  [order]="userService.basket.order"
                [editable]="editable"
                [comment_editable]="comment_editable"
                class="white"
                [ngClass]="{'order-details-kiosk': this.merchantService.kiosk}" >
        </order>
      </div>

      <div class="subtotals p-3" *ngIf="feature != 'menu' && userService.basket.order.price_before_modifiers != userService.basket.order.price_after_coupons">

        <div class="d-flex">
          <div class="column"></div>
          <div class="double-column align-right bold">{{ 'basket.subtotal' | translate }}</div>
          <div class="column align-right bold">{{ userService.basket.order.price_before_modifiers | currency:'EUR' }}</div>
        </div>

        <ng-container *ngFor="let price_modifier of userService.basket.order.order_order_price_modifiers">
          <div class="d-flex">
            <div class="column"></div>
            <div class="double-column align-right">{{ price_modifier.name }}</div>
            <div class="column align-right">{{ price_modifier.price_adapt | currency:'EUR' }}</div>
          </div>
        </ng-container>

         <ng-container *ngFor="let coupon of userService.basket.coupons">
          <div class="d-flex">
            <div class="column"></div>
            <div class="double-column align-right" *ngIf="coupon.is_generic">Coupon ({{ coupon.generic_code | slice:0:11}})</div>
             <div class="double-column align-right" *ngIf="!coupon.is_generic">Coupon ({{ coupon.coupon_code }})</div>
            <div class="column align-right">{{ (coupon.value_remaining - coupon.new_value_remaining) * -1 | currency:'EUR' }}</div>
          </div>
        </ng-container>


      </div>



    </div>
  </div>

<!--
<div style="position: fixed; bottom: 60px; width: 100vw; z-index: 499;">
      <advisories   *ngIf="!menu_only && userService.user && userService.basket && userService.basket.checkoutFields">
      </advisories>
</div>
-->

<div  *ngIf="position != 'main' && userService.basket && userService.basket.order && (position != 'relative' || (feature != 'payment' && feature != 'final'))"
      (clickOutside)="onClickedOutside($event)" 
      class="{{ template }} bar bg_secondary rounded-bottom" 
      [ngClass]="{ 'bar-relative': position == 'relative', 'bar-web': position == 'web', 'bar-popup': position == 'popup' }"
      [ngStyle]="{'background-color':  !fol && merchantService.merchant.primary_color}"
      [@slideInOut]="{value: animationState, params: { height: basketbar_height }}"
      matTooltip="{{ disabled_reason }}" [matTooltipDisabled]="!is_disabled"
      id="basketbar">

  <button class="{{ template }} font_primary"
          id="basketbar-button"
          [ngClass]="[position == 'popup' ? 'content-popup' : 'content bg_secondary']"
          [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color}"
          [disabled]="is_disabled"
          (click)="goNext($event)">

    
    <div class="d-none d-lg-block subtotals p-3" *ngIf="feature != 'menu' && userService.basket.order.price_before_modifiers != userService.basket.order.price_after_coupons && userService.basket.order.device_type != 'Kiosk'">

        <div class="d-flex">
          <div class="column"></div>
          <div class="double-column align-right bold">{{ 'basket.subtotal' | translate }}</div>
          <div class="column align-right bold">{{ userService.basket.order.price_before_modifiers | currency:'EUR' }}</div>
        </div>

        <ng-container *ngFor="let price_modifier of userService.basket.order.order_order_price_modifiers">
          <div class="d-flex">
            <div class="column"></div>
            <div class="double-column align-right">{{ price_modifier.name }}</div>
            <div class="column align-right">{{ price_modifier.price_adapt | currency:'EUR' }}</div>
          </div>
        </ng-container>

         <ng-container *ngFor="let coupon of userService.basket.coupons">
          <div class="d-flex">
            <div class="column"></div>
             <div class="double-column align-right" *ngIf="coupon.is_generic">Coupon ({{ coupon.generic_code | slice:0:11}})</div>
             <div class="double-column align-right" *ngIf="!coupon.is_generic">Coupon ({{ coupon.coupon_code }})</div>
             <div class="column align-right">{{ (coupon.value_remaining - coupon.new_value_remaining) * -1 | currency:'EUR' }}</div>
          </div>
        </ng-container>

      </div>

  <div class="d-flex justify-content-between align-items-center py-2 px-3" style="min-height:100%">
  	<div>
      <ng-container *ngIf="feature != 'menu' && feature != 'checkoutWeb' && feature != 'paymentWeb'">

        <div 
              *ngIf="!merchantService.kiosk" 
              class="d-flex justify-content-center align-items-center rounded rounded-circle bg-white primary"
              style="height:40px; width:40px;">
          <strong>{{ number_of_products }}</strong>

        </div>
  		  
      </ng-container>
  	</div>
    
    <div class="" style="text-align:center;">

      <div style="position: absolute; left:-20px; top:-20px; transform: rotate(-8deg);" *ngIf="fol">
        <span class="badge font-dosis" style="color: #f0513f; background-color: #fdd329">
          {{ 'fol.ordering!' | translate }}
        </span>
      </div>


  		<div class="d-flex justify-content-between align-items-center"
           [ngStyle]="{'color': !fol ? 'white' : '#fdd329'}">
  			<div class="d-flex">

          <ngx-spinner name="basketbar" [fullScreen]="false" type="ball-pulse" size="small"></ngx-spinner>

          <div class="align-right" *ngIf="feature == 'checkoutWeb' || feature == 'paymentWeb'">
            {{ 'order.total_order' | translate }}
          </div>
          
          <div class="confirmation clickable">

            <ng-container *ngIf="feature == 'menu'">
              {{ button_text }}
            </ng-container>

            <ng-container *ngIf="feature != 'menu'">
              <mat-icon>{{ button_icon }}</mat-icon>
              {{ button_text }}
            </ng-container>

          </div>

  			</div>
  		</div>
  	</div>
  	<div (click)="toggleShowDiv($event)" class="d-flex flex-column price-flex ps-2" style="text-align:right;">
      <ng-container *ngIf="feature != 'menu' && (feature != 'pos_consumer' || this.show_prices_consumer_screen)">
  		  <div  *ngIf="userService.basket.order.price_shown; else pro_memory" 
              class="price font-dosis" 
              [ngClass]="{'price-kiosk': this.merchantService.kiosk}" >
            {{ userService.basket.order.price_after_coupons | currency:'EUR' }}
        </div>
        <ng-template #pro_memory>
            p.m.
        </ng-template>
        <div *ngIf="showDetails" 
              class="font-dosis"
             [ngClass]="{ 'd-block d-lg-none': template != 'template_kiosk', 'd-block': template == 'template_kiosk' }">
          {{ 'basketbar.details' | translate | capitalizeFirst }}
          <mat-icon *ngIf="animationState == 'small'">expand_less</mat-icon>
          <mat-icon *ngIf="animationState != 'small'">expand_more</mat-icon>
        </div>
      </ng-container>
  	</div>
  </div>

  </button>

</div>

<div class="d-flex justify-content-between"
     *ngIf="position == 'relative' && (feature == 'payment' || feature == 'final')">
  <div class="{{ template }} left-button bar-relative me-2"
       *ngIf="backButtonShown">
    <button class="{{ template }} content bg_secondary font_primary"
            [ngStyle]="{'background-color':  !fol && merchantService.merchant.primary_color}"
            (click)="goBack($event)">
            <div class="p-3">
              <mat-icon>chevron_left</mat-icon>
              {{ 'basketbar.back' | translate }}
            </div>
    </button>
  </div>
  <div class="{{ template }} right-button bar-relative"
       style="flex-grow: 1"
       matTooltip="{{ disabled_reason }}" [matTooltipDisabled]="!is_disabled">

       <ngx-spinner name="basketbar" [fullScreen]="false" type="ball-pulse" size="small"></ngx-spinner>

    <button class="{{ template }} content bg_secondary font_primary"
            [ngStyle]="{'color': !fol ? 'white' : '#fdd329', 'background-color':  !fol && merchantService.merchant.primary_color}"
            [disabled]="is_disabled"
            (click)="goNext($event)">
            <div class="confirmation p-3 d-flex justify-content-between align-items-center">
                <mat-icon>{{ button_icon }}</mat-icon>
                  {{ button_text }}
                <mat-icon>chevron_right</mat-icon>
            </div>
    </button>
  </div>
</div>

<div  class="d-none d-md-block mt-1"
      style="font-size: 0.9em;"
      *ngIf="userService.basket && userService.basket.order && userService.basket.order.id && !userService.basket.order.price_shown && feature != 'menu' && position != 'main' && (position != 'relative' || (feature != 'payment' && feature != 'final'))">
  <div>
    <strong>p.m. = Prijzen zijn indicatief!</strong>
  </div>
  <div>
    De uiteindelijk te betalen prijs komt overeen met de gewogen producten en zal u bij afhaling in de winkel meegedeeld worden.
  </div>
</div>