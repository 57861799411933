import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { ProductService } from '../services/product.service';
import { MerchantService } from '../services/merchant.service';

import { PartSet } from '../models/part_set';
import { OrderPartAdd } from '../models/order_part_add';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { UserService } from '../services/user.service';

@Component({
  selector: 'part-set',
  templateUrl: './part-set.component.html',
  styleUrls: ['./part-set.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class PartSetComponent implements OnInit {

  public faCircleCheck = faCircleCheck;

  @Input() public product_price = 0;
  @Input() public order_part_set: any;
  @Output() updated = new EventEmitter<boolean>();
  public part_set: PartSet;
  public selected_part_adds_ids: Array<number> = [];
  public primary_color = '#828D8B';
  public with_image = false;

  public template: string = "template_default";

  constructor(private productService: ProductService,
              private merchantService: MerchantService,
              public userService: UserService) { }

  ngOnInit() {

    if (this.merchantService.pos) { 
        this.template = "template_pos";
        this.with_image = true;
    } else if (this.merchantService.kiosk) { 
      this.template = "template_kiosk"; 
      if (this.merchantService.merchant && this.merchantService.merchant.id !== 950) { this.with_image = true; };
    };

  	this.part_set = this.productService.getStoredPartSet(this.order_part_set.part_set_id);
    if (this.part_set.min_select == 0) {
      this.order_part_set.valid = true;
      this.updated.emit(true);
    }

    //order the products
    this.part_set.products = this.part_set.products.sort ((a, b) => { 

      let a_product_taxon = a.product_taxons.find(pt => { 
         let taxon = this.part_set.part_set_containee_taxons.find(t => { 
           return t.taxon_id == pt.taxon_id });
         return taxon != undefined;
      });

      let b_product_taxon = b.product_taxons.find(pt => { 
         let taxon = this.part_set.part_set_containee_taxons.find(t => { 
           return t.taxon_id == pt.taxon_id 
         });
         return taxon != undefined;
      });

      if (a_product_taxon.taxon_id == b_product_taxon.taxon_id) {
        return a_product_taxon.ordering - b_product_taxon.ordering;
      } else {
        return a_product_taxon.taxon_id - b_product_taxon.taxon_id;
      }

    });

    if (this.merchantService.merchant.primary_color) {
      this.primary_color = this.merchantService.merchant.primary_color;
    }
  }

  togglepart_setValue(part_add) {

    if (part_add.is_available) {

      const index = this.order_part_set.order_part_adds.findIndex(x => x.product_id == part_add.id);
      const index_id = this.selected_part_adds_ids.findIndex(x => x == part_add.id);
      if (index != -1) { this.order_part_set.order_part_adds.splice(index, 1); this.selected_part_adds_ids.splice(index_id, 1);}
      else {
        const order_part_add  = new OrderPartAdd();
        order_part_add.product_id = part_add.id;
        order_part_add.name = part_add.name;
      
        if (this.part_set.price_adapt_level == 'value') order_part_add.price_adapt = parseFloat(part_add.price_with_modifiers);
        if (this.part_set.price_adapt_method == 'rel') {
          order_part_add.price_adapt = +this.product_price * +this.part_set.price_adapt_rel;
        }
        this.order_part_set.order_part_adds.push(order_part_add);
        this.selected_part_adds_ids.push(part_add.id);
      }
      
      this.verifyValid();
  
    }

  }

  switchpart_setValue(part_add) {

    if (part_add.is_available) {

      this.order_part_set.order_part_adds = [];

      const order_part_add  = new OrderPartAdd();
      //order_part_add.is_default = order_part_add.is_default;
      order_part_add.product_id = part_add.id;
      order_part_add.name = part_add.name;
    
    
      if (this.part_set.price_adapt_level == 'value') order_part_add.price_adapt = parseFloat(part_add.price_with_modifiers);
    

      this.order_part_set.order_part_adds.push(order_part_add);
      this.selected_part_adds_ids = [part_add.id];
      this.verifyValid();

    }
  
  }


  verifyValid() {

    let currentSelection = this.order_part_set.order_part_adds.length;
    let valid = true;
    if (this.part_set.min_select > 0 && currentSelection < this.part_set.min_select) valid = false;
    if (this.part_set.max_select > 0 && currentSelection > this.part_set.max_select) valid = false;
    this.order_part_set.valid = valid;
    this.updated.emit(true);
  }

}
