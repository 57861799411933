/* src/app/payment-method/payment-method.component.scss */
.image {
  max-width: 100px;
  max-height: 30px;
}
.payment-method_img {
  height: auto;
  width: auto;
  max-width: 230px;
  max-height: 80px;
}
.text-highlight {
  font-weight: bolder;
}
.marker-empty {
  width: 4px;
}
.template_pos {
  color: white;
  font-size: 0.9em;
}
.payment-method {
  padding: 20px;
  border: 4px solid;
  height: 200px;
  border-radius: 15px;
  width: 270px;
}
.payment-method.marked {
  border: 4px solid !important;
  border-radius: 15px;
  padding: 0px;
}
/*# sourceMappingURL=payment-method.component.css.map */
