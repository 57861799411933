import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-local-location-sheet',
  templateUrl: './local-location-sheet.component.html',
  styleUrls: ['./local-location-sheet.component.scss']
})
export class LocalLocationSheetComponent implements OnInit {

  public localLocationSelected = new EventEmitter();


  constructor(public userService: UserService,
  			  @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
  }

  selectLocalLocation(localLocation) {
  	this.localLocationSelected.emit(localLocation);
  }

}
