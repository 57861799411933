<ng-template #description_allergenes>

<ng-container *ngIf="product.description">
	<div class="{{ template }}_description font-dosis" [innerHTML]="product.description"></div>
</ng-container>

<div class="d-flex justify-content-between">
	<div class="">
	<div 	style="display: inline-block;"
			*ngFor="let allergene of product.allergenes">
	
	<button 
		style="background-color: rgba(255,255,255,0); padding: 0; margin: 0; border: 0px; width:45px; height:45px;" #tooltip="matTooltip" (click)="tooltip.toggle()" (onmouseover)="tooltip.show()" (onmouseout)="tooltip.hide()" matTooltip="{{ allergene.name }}"> <img width=40 src='./assets/allergenes_v2/{{ allergene.id }}.png'> </button>

	</div>
	</div>

</div>

</ng-template>


<div class="main d-flex flex-column justify-content-between" style="height:100%;">

	<div class="{{ template }}_header first-part rounded-top">

	<div class="d-flex align-items-center justify-content-between p-3" style="height: 100%;">
		<div style="flex-basis: 150px;">
			<button mat-icon-button (click)="closeProduct()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</div>
		<div style="flex-grow:1; text-align: center;">{{ product.name }} </div>
	
		<div class="d-flex justify-content-end" style="flex-basis: 150px;">

			<div 	class="product_price line-through me-1" 
					*ngIf="product.in_promo">
					<span>{{ product.price | currency:'EUR' }}</span> 
					<span *ngIf="product.unit != 'piece'"> / {{ product.unit }}</span>
			</div>

			<div>
				<ng-container *ngIf="product.price_with_modifiers > 0">
					<span>{{ product.price_with_modifiers | currency:'EUR'}}</span>
					<span *ngIf="product.unit != 'piece'"> / {{ 'order_unit.' + product.unit | translate }}</span>
				</ng-container>
			</div>

		</div>
	
	</div>

	</div>

	<div #trackScrolling class="content d-flex flex-column" style="padding: 20px; flex-grow:1;">

		<div class="down_arrow"
		     *ngIf="needs_scrolling"
		     [@fadeInOut]
			  [ngStyle]="{ 'top': needs_scrolling_pos_bottom + 'px', 'left': needs_scrolling_pos_right + 'px' }">
			  
			 


  <div class="chevron"></div>
  <div class="chevron"></div>
  <div class="chevron"></div>







		</div>
		
<ng-container *ngIf="!merchantService.pos">
	<div class="product_image" *ngIf="!product.main_image && category" style="position:relative; text-align:center;">
			<div style="position:absolute;top:0px;right:0px;z-index:490"
			*ngIf="product.in_promo">
			<img class="promo" src='./assets/promo/promo.png'>
		</div>
		<p 	 class="svg-icon"
			 [ngStyle]="{'fill': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '#fdd329' }"
			 [innerHTML]="icon_data">
		</p>
	</div>

	<div 	class="product_image" 
			*ngIf="product.main_image"
			style="position:relative;">
		<img class="product_image" src="{{ product.main_image.large_url }}" style="border-radius: 5px;">
		<div style="position:absolute;top:0px;right:6px;z-index:998"
		*ngIf="product.in_promo">
		<img width="80" height="80" src='./assets/promo/promo.png'>
		</div>

	</div>
</ng-container>

<div class="d-flex flex-column align-items-between" style="flex: 1">
<div style="flex: 1">
	<ng-container 
	*ngIf="!merchantService.pos">
	<ng-container
		*ngTemplateOutlet="description_allergenes">
	</ng-container>
</ng-container>

	<ng-container *ngIf="product.product_parts.length > 0">

		<div class="title pt-3 pb-2">
			{{ 'product.ingredients' | translate }}		
		</div>

		<mat-chip-list class="font-dosis">
			<ng-container *ngFor="let product_part of product.product_parts">
				<p 	style="margin:4px;"
					*ngIf="!product_part.is_removable">
					{{ product_part.name }}
				</p>
				
				<mat-chip 	*ngIf="product_part.name && product_part.is_removable"
						class="me-1"
						(click)="toggleProductPartRemove(product_part)">
				<p [ngClass]="[product_part.active ? 'product_part product_part_active' : 'product_part']">{{ product_part.name }}</p>
				</mat-chip>

			</ng-container>
	</mat-chip-list>

</ng-container>

<div class="row"
	 *ngIf="merchantService.pos">
	<div class="col-6"
		*ngFor="let order_option of orderLine.order_options">
			<ng-container *ngIf="!order_option.product_id">
				<app-option  
							 id="option_{{ order_option.option_id }}"
							 [order_option]="order_option" 
							 [product]="product"
							 (updated)="orderLineValid()"
							 style="width:100%">
				</app-option>
			</ng-container>
	</div>

	<div class="col-6"
		 *ngFor="let order_part_set of orderLine.order_part_sets">
				<div class="d-flex">
					<part-set id="part_set_{{ order_part_set.part_set_id }}"
							  [product_price]="orderLine.product_price"
							  [order_part_set]=order_part_set
							  (updated)="orderLineValid()" 
							  style="width:100%"></part-set>

				</div>

				<ng-container *ngFor="let order_option of orderLine.order_options">
				
					<div 	class="d-flex p-3 mb-3"  
					style="width:100%; border:1px solid; border-radius: 5px;"
					*ngIf="product.is_bundle && order_option.product_id && order_part_set.order_part_adds.length > 0 && order_part_set.order_part_adds[0].product_id == order_option.product_id">

						<app-option  
									 id="option_{{ order_option.option_id }}"
									 [order_option]="order_option" 
									 [product]="product"
									 (updated)="orderLineValid()"
									 style="width:100%">
						</app-option>
					</div>
				</ng-container>

	</div>
</div>

<ng-container *ngIf="!merchantService.pos">
	<div 	class="d-flex pt-3"  
	style="width:100%"
	*ngFor="let order_option of orderLine.order_options">
	<ng-container *ngIf="!order_option.product_id">
	<app-option  
				 id="option_{{ order_option.option_id }}"
				 [order_option]="order_option" 
				 [product]="product"
				 (updated)="orderLineValid()"
				 style="width:100%"></app-option>
				</ng-container>
	</div>

<ng-container *ngFor="let order_part_set of orderLine.order_part_sets">
<div class="d-flex">
	<part-set id="part_set_{{ order_part_set.part_set_id }}"
			  [product_price]="orderLine.product_price"
			  [order_part_set]=order_part_set
			  (updated)="orderLineValid()" 
			  style="width:100%"></part-set>

</div>

<ng-container *ngFor="let order_option of orderLine.order_options">
			  <div 	class="d-flex p-3 mb-3"  
style="width:100%; border:1px solid; border-radius: 5px;"
*ngIf="product.is_bundle && order_option.product_id && order_part_set.order_part_adds.length > 0 && order_part_set.order_part_adds[0].product_id == order_option.product_id">

<app-option  
			 id="option_{{ order_option.option_id }}"
			 [order_option]="order_option" 
			 [product]="product"
			 (updated)="orderLineValid()"
			 style="width:100%"></app-option>
</div>
			</ng-container>
</ng-container>

</ng-container>

		<div *ngIf="loyalty_enabled && product.is_loyalty && product.loyalty_points && product.loyalty_points > 0 && this.userService.user && !this.userService.user.is_anonymous_user">
	
			<div class="d-flex justify-content-between title pt-3 mb-3">
					<div class="title-name">{{ 'loyalty_points.title' | translate }}</div>
				</div>
	
					<mat-selection-list #loyalty_values>
						<mat-checkbox class="example-margin"
										[(ngModel)]="orderLine.is_loyalty"
										[disabled]="loyalty_disabled"
										(change)="calculateDisabled();">
									<ng-container *ngIf="orderLine.amount * product.loyalty_points  == 1">{{ 'loyalty_points.use_1' | translate | capitalizeFirst }}</ng-container>
									<ng-container *ngIf="orderLine.amount * product.loyalty_points > 1">{{ 'loyalty_points.use_multiple' | translate:{ loyalty_points: orderLine.amount * product.loyalty_points } | capitalizeFirst }}</ng-container>
							</mat-checkbox>
				</mat-selection-list>
		</div>

<div *ngIf="mode === 'normal' && loyalty_enabled && product.is_loyalty && product.loyalty_points && product.loyalty_points > 0 && this.userService.user && !this.userService.user.is_anonymous_user">

	<div class="d-flex justify-content-between title pt-3 mb-3">
      <div class="title-name">{{ 'loyalty_points.title' | translate }}</div>
    </div>

    	<mat-selection-list #loyalty_values>
  			<mat-checkbox class="example-margin font-dosis" 
    	  				[(ngModel)]="orderLine.is_loyalty"
    	  				[disabled]="loyalty_disabled"
    	  				(change)="calculateDisabled();">
    	  			<ng-container *ngIf="orderLine.amount * product.loyalty_points  == 1">{{ 'loyalty_points.use_1' | translate | capitalizeFirst }}</ng-container>
    	  			<ng-container *ngIf="orderLine.amount * product.loyalty_points > 1">{{ 'loyalty_points.use_multiple' | translate:{ loyalty_points: orderLine.amount * product.loyalty_points } | capitalizeFirst }}</ng-container>
    	  	</mat-checkbox>
		</mat-selection-list>

</div>

<div class="pt-3"
	*ngIf="merchantService.pos">
	<ng-container
		*ngTemplateOutlet="description_allergenes">
	</ng-container>
</div>

	</div>
	
		<ng-container *ngIf="comment_editable">
			<button mat-stroked-button class="mt-4" style="width: fit-content" *ngIf="!show_input_comment" (click)="focusOnInput()">
				<div class="d-flex align-items-center">
					<fa-icon [icon]="faMessagePlus" class="fa-lg me-2"></fa-icon> {{ 'order.add_remark' | translate }}
				</div>
			</button>
			<mat-form-field style="width: 100%" *ngIf="show_input_comment">
				<mat-label>{{ 'order.remark' | translate }}</mat-label>
				<input type="text" id="comment_input" matInput [(ngModel)]="orderLine.comment" (keyup.enter)="onEnter()" maxlength="250">
				<button matSuffix mat-icon-button (click)="hideInput()">
					<mat-icon>clear</mat-icon>
				</button>
			</mat-form-field>
		</ng-container>
	</div>
</div>

<div class="rounded-bottom"
	 [ngClass]="[orderLine.valid ? 'footer footer-available p-3' : 'footer footer-unavailable p-3']"
	 [ngStyle]="{'background-color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">

	 <div 	class="d-flex justify-content-center align-items-center"
	 		*ngIf="unit_option && product.is_available && !menu_only">

			<div class="no_selection">
				<button (click)="alterAmount(-alterationAmount)" mat-icon-button [disabled]="remove_disabled">
   					<mat-icon  style="cursor: pointer;" >remove_circle_outline</mat-icon>
				</button>
			</div>
			<div class="bar-amount no_selection">
				<input 	type="number" 
						style="width: 70px; text-align: center; border: none; border-radius: 5px;"
						[(ngModel)]="displayAmount" 
						(change)="manualInput()"/>
						 <ng-container *ngIf="unit_name && unit_name != 'piece'">
						 	{{ unit_name }}
						 </ng-container>
			</div>
			<div class="no_selection">
				<button (click)="alterAmount(alterationAmount)" mat-icon-button 
						[disabled]="add_disabled">
   					<mat-icon  style="cursor: pointer;" >add_circle_outline</mat-icon>
				</button>
			</div>

	 </div>

	 <div class="d-flex flex-row align-items-center justify-content-between last-part w-100">

	<div class="d-flex align-items-center">

		<ng-container *ngIf="!unit_option && product.is_available && !menu_only">
			<div class="no_selection">
				<button (click)="alterAmount(-1)" 
						type="button"
						class="btn btn-sm"
						[ngClass]="[!fol ? 'btn-light' : 'btn-warning']"
						[disabled]="remove_disabled">
   					<fa-icon
                      		[icon]="faMinus">
              		</fa-icon>
				</button>
			</div>
			
			<div 	class="bar-amount no_selection"
					[ngStyle]="{'color': fol ? '#fdd329' : ''}">
				{{ orderLine.amount }}
			</div>
			
			<div class="no_selection">
				<button (click)="alterAmount(+1)" 
						type="button"
						class="btn btn-sm"
						[ngClass]="[!fol ? 'btn-light' : 'btn-warning']"
						[disabled]="add_disabled">
   					<fa-icon
                      		[icon]="faPlus">
              		</fa-icon>
				</button>
			</div>
		</ng-container>

	</div>

	<div class="bar-add clickable no_selection" *ngIf="menu_only" (click)="closeProduct()">
		Terug naar menu
	</div>

	<div class="bar-add clickable no_selection mx-1" 
		 *ngIf="product.is_available && !menu_only" 
		 (click)="submitOrderLine()">
		<button 
			type="button"
			class="btn"
			[ngClass]="[!fol ? 'btn-sm btn-light' : 'btn-warning']"
			[ngStyle]="{'color': fol ? '#f0513f' : ''}">
			{{ 'product.add_to_basket' | translate }}
		</button>
	</div>

	<div class="bar-add clickable no_selection" *ngIf="!product.is_available && !menu_only">
		{{ 'product.out_of_stock' | translate }}
	</div>

	<div class="bar-price"
		 [ngStyle]="{'color': !fol ? 'white' : '#fdd329', 'background-color': !fol && merchantService.merchant.primary_color}">
		<ng-container *ngIf="product.is_available && !menu_only && price_shown">
			{{ orderLine.amount * getProductPrice() | currency:'EUR'}}
		</ng-container>

		<ng-container *ngIf="product.is_available && !menu_only && !price_shown">
			p.m.
		</ng-container>

		<div 	class="d-flex justify-content-end" 
				*ngIf="mode === 'normal' && !orderLine.is_loyalty && loyalty_points_earn">
			<div class="d-none d-lg-block pe-2">{{ 'loyalty_points.you_save' | translate }}</div>
			<div class="d-block d-lg-none pe-2">+</div>
			<fa-icon
							class="pe-1" 
                      		[icon]="faCoin">
              </fa-icon>
              {{ orderLine.amount * product.loyalty_points_value }}
	</div>


		<div class="d-flex justify-content-end" 
			 *ngIf="mode === 'normal' && orderLine.is_loyalty">
			<div class="d-none d-lg-block pe-2">{{ 'loyalty_points.you_use' | translate }}</div>
			<div class="d-block d-lg-none pe-2">-</div>
			<fa-icon
							class="pe-1" 
                      		[icon]="faCoin">
              </fa-icon>
              {{ orderLine.amount * product.loyalty_points }}
		</div>

</div>

</div>	

</div>