<ng-container *ngIf="!(option.base_unit && option.min_select == 1 && option.max_select == 1 && option.option_values.length == 1 && option.option_values[0].is_default == true)">

<div class="d-flex justify-content-between align-items-end {{ template }}_title pt-3 pb-2">
      <div class="d-flex flex-column justify-content-start title-name">
            <div>{{ option.name }}</div>
            <div class="mt-1"
                *ngIf="units.length > 0">
                <!--
        <mat-chip-list
  class=""
  cdkDropList 
  cdkDropListOrientation="horizontal">
  <mat-chip
    #c="matChip"
    class=""
    color="primary"
    cdkDrag
    [selected]="selectedUnit == unit.value"
    (click)="toggleSelection(c)"
    *ngFor="let unit of units"
    [value]="unit.value">
    <mat-icon *ngIf="c.selected">check</mat-icon>
    {{ unit.value }}
  </mat-chip>
</mat-chip-list>
-->
            </div>
       <!-- 
        <mat-button-toggle-group [(ngModel)]="selectedUnit" 
                                 (change)="updateValues()" aria-label="Unit of measurement"
                                 *ngIf="units.length > 0">
          <mat-button-toggle *ngFor="let unit of units" value="{{ unit.value }}">{{ unit.value }}</mat-button-toggle>
        </mat-button-toggle-group>
-->
      </div>
      <div *ngIf="option.min_select == 1 && option.max_select == 1; else multipleOptionsTitle"
           style="text-align: right">
        <mat-error *ngIf="!order_option.valid">{{ 'option.radio_title' | translate }}</mat-error>
        <ng-container *ngIf="order_option.valid">{{ 'option.radio_title' | translate }}</ng-container>
      </div>
      <ng-template #multipleOptionsTitle>
        <mat-error *ngIf="!order_option.valid">
            {{ 'option.checkbox_title_begin' | translate }}{{ option.min_select }} {{ 'option.checkbox_title_mid' | translate }} {{ option.max_select }} {{ 'option.checkbox_title_end' | translate }}
        </mat-error>
        <ng-container *ngIf="order_option.valid && option.min_select > 0 && option.max_select > 0">
        <div style="text-align: right">
          {{ 'option.checkbox_title_begin' | translate }}{{ option.min_select }} {{ 'option.checkbox_title_mid' | translate   }} {{ option.max_select }} {{ 'option.checkbox_title_end' | translate }}
          </div>
        </ng-container>
      </ng-template>
</div>

<ng-container *ngIf="merchantService.pos || merchantService.kiosk else default">
  <div *ngIf="option.min_select == 1 && option.max_select == 1; else multipleOptions">
    <div style="padding-top:8px">
      <mat-radio-group [(ngModel)]="chosenOption" class="row">
        <div class="col-6 mb-3 radio-button-option" 
            *ngFor="let option_value of option.option_values"
            (click)="switchOptionValue(option_value)">
          <mat-radio-button 
            matRipple
            [matRippleColor]="userService.darkMode ? '#535252' : '#E0E0E0'"
            [matRippleAnimation]="{enterDuration: 200, exitDuration: 50}"
            *ngIf="!option_value.unit || !selectedUnit || option_value.unit == selectedUnit" 
            class="{{ template }} {{template}}_mat-list-option invisible-checkbox option d-flex justify-content-center"
            [ngStyle]="{'border-color': option_value.checked ? primary_color : '#D3D3D3'}">
            <fa-icon [icon]="faCircleCheck"
              style="position:absolute;top:10px;right:10px;"
              class="fa-2x"
              [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
              *ngIf="option_value.checked"></fa-icon>
    
            <div class="d-flex flex-row flex-wrap justify-content-center mt-2 option-info"
                style="margin-right: 20px;white-space: normal; line-height: normal;text-align:center">
              <div class="me-2">{{ option_value.name }}</div>
              <div>
                <ng-container *ngIf="option_value.price_adapt_method == 'abs'">  
                  ( <ng-container *ngIf="option_value.price_adapt_abs > 0">+</ng-container> {{ option_value.price_adapt_abs | currency:'EUR' }} )
                </ng-container>
                <ng-container *ngIf="option_value.price_adapt_method == 'rel'">
                  <ng-container *ngIf="option.price_adapt_display == 'total_price'; else relativePrice">
                    ({{ option_value.price_adapt_rel * product.price + +product.price | currency: 'EUR' }})
                  </ng-container>
            
                  <ng-template #relativePrice>
                    ( <ng-container *ngIf="option_value.price_adapt_rel > 0">+</ng-container> {{ option_value.price_adapt_rel * product.price | currency:'EUR' }} )
                  </ng-template>
                </ng-container>
        
                <ng-container *ngIf="option_value.unit_proportion_shown">
                  ( +/- {{ option_value.unit_proportion }} {{ 'order_unit.' + option.base_unit | translate }})
                </ng-container>
              </div>
            </div>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </div>

  <ng-template #multipleOptions>
    <mat-selection-list #mySel>
      <div class="row">
        <div class="col-6 mb-3" *ngFor="let option_value of option.option_values;">
          <mat-list-option
                    *ngIf="!option_value.unit || !selectedUnit || option_value.unit == selectedUnit"
                    class="{{ template }} {{template}}_mat-list-option mat-list-option option invisible-checkbox"
                    [ngStyle]="{'border-color': option_value.checked ? primary_color : '#D3D3D3'}"
                    [value]="option_value.id"
                    [hideSingleSelectionIndicator]="true"
                    (click)="toggleOptionValue(option_value)">
            <fa-icon [icon]="faCircleCheck"
              style="position:absolute;top:10px;right:10px;"
              class="fa-2x"
              [ngStyle]="{'color': merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
              *ngIf="option_value.checked"></fa-icon>
              <div [ngClass]=" {'d-flex justify-content-center': merchantService.pos }">
                <div>
                  <div class="d-flex flex-row flex-wrap justify-content-center mt-2" style="white-space: normal; line-height: normal;text-align:center">
                    <div class="me-2">{{ option_value.name }}</div>
                    <div *ngIf="option_value.price_adapt_method == 'abs' && option_value.price_adapt_abs > 0">
                      (+{{ option_value.price_adapt_abs | currency:'EUR' }})
                    </div>
                  </div>
                </div>
              </div>
          </mat-list-option>
        </div>
      </div>
    </mat-selection-list>
  </ng-template>
</ng-container>
  
<ng-template #default>
  <div *ngIf="option.min_select == 1 && option.max_select == 1; else multipleOptions">
	  <div style="padding-top:8px">
      <mat-radio-group [(ngModel)]="chosenOption">
        <ng-container *ngFor="let option_value of option.option_values">
          <mat-radio-button 
              class="{{ template }} font-dosis"
              *ngIf="!option_value.unit || !selectedUnit || option_value.unit == selectedUnit" 
              [value]="option_value.id" 
              [checked]="option_value.checked"
              (change)="switchOptionValue(option_value)"
              style="height:30px;">
              {{ option_value.name }} 
            <ng-container *ngIf="option_value.price_adapt_method == 'abs'">  
              (
              <ng-container *ngIf="option_value.price_adapt_abs > 0">+</ng-container>
              {{ option_value.price_adapt_abs | currency:'EUR' }}
              )
            </ng-container>

            <ng-container *ngIf="option_value.price_adapt_method == 'rel'">
              
              <ng-container *ngIf="option.price_adapt_display == 'total_price'; else relativePrice">
                ({{ option_value.price_adapt_rel * product.price + +product.price | currency: 'EUR' }})
              </ng-container>
        
              <ng-template #relativePrice>
                (
                <ng-container *ngIf="option_value.price_adapt_rel > 0">+</ng-container>
                  {{ option_value.price_adapt_rel * product.price | currency:'EUR' }}
                )
              </ng-template>


            </ng-container>

            <ng-container *ngIf="option_value.unit_proportion_shown">
              ( +/- {{ option_value.unit_proportion }} {{ 'order_unit.' + option.base_unit | translate }})
            </ng-container>

          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>
  </div>

  <ng-template #multipleOptions>
    <mat-selection-list #mySel>
      <ng-container *ngFor="let option_value of option.option_values;">
        <mat-list-option
                  *ngIf="!option_value.unit || !selectedUnit || option_value.unit == selectedUnit"
                  class="{{ template }} {{template}}_mat-list-option mat-list-option font-dosis"
                  style="height:30px !important;"
                  [value]="option_value.id"
                  [selected]="option_value.checked"
                  [checkboxPosition]="'before'"
                  (click)="toggleOptionValue(option_value)">
          {{ option_value.name }}
          <ng-container *ngIf="option_value.price_adapt_method == 'abs'">
            ( <ng-container *ngIf="option_value.price_adapt_abs > 0">+</ng-container> {{ option_value.price_adapt_abs | currency:'EUR' }} )
          </ng-container>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </ng-template>
</ng-template>