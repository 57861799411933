export class Address {
    zipcode?: string;
    city?: string;
    country?: string;
    country_alpha2?: string;
    line1?: string;
    line2?: string;
    latitude?: number;
    longitude?: number;
    timestamp?: any;
    street_number?: string;
    dist_location_id?: number;

    constructor() {}
  }
