import { OrderOption } from '../models/order_option';
import { OrderPart } from '../models/order_part';
import { OrderPartSet } from '../models/order_part_set';

export class OrderLine {

    id?: number;
    product_id?: number;
    product_name?: string;
    amount?: number;
    order_options?: Array<OrderOption>;
    order_part_sets?: Array<OrderPartSet>;
    order_part_removes?: Array<OrderPart>;
    total_price?: number;
    product_price?: number;
    unit_price?: number;

    taxon_id?: number;
    taxon_name?: string;
    ordering?: number;

    valid = false;
    _destroy?: boolean;

    is_loyalty: boolean = false;
    loyalty_points?: number;

    order_unit?: any;
    order_amount?: any;

    comment?: string;

    custom_price_comment?: any;

    vat_info?: any;
    plus?: any;
    minus?: any;

    minus_disabled?: boolean = false;
    plus_disabled?: boolean = false;

    constructor(product, category = null, amount = null) {

        this.product_id = product.id;
        this.product_name = product.name;
        this.product_price = product.price;
        this.total_price = product.price_with_modifiers;
        this.unit_price = product.price_with_modifiers;
        this.amount = 1;
        if (amount) this.amount = amount;
        this.order_options = [];
        this.order_part_sets = [];
        this.order_part_removes = [];
        if (category) { this.taxon_id = category.id; };
        this.plus = amount == 1 ? 1 : 0;
        this.minus = amount == -1 ? 1 : 0;
    }
}
