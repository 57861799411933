import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { UserService} from '../services/user.service';
import { MerchantService } from '../services/merchant.service';
import { OrderService} from '../services/order.service';

import { MatSelectionList, MatListOption } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';

import { PaymentMethodSheetComponent } from './payment-method-sheet/payment-method-sheet.component';

import { AppHelper } from '../helpers/app-helper';

import { environment } from '../../environments/environment';

import { faWallet,faFileLines, faCoins, faEdit } from '@fortawesome/pro-regular-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit, OnDestroy {

   @Input() mode = "normal";
   @Output() monizzeBalance = new EventEmitter();
   @Output() emitUpdate = new EventEmitter<boolean>();

   faWallet = faWallet;
   faFileLines = faFileLines;
   faCoins = faCoins;
   faEdit = faEdit;

   public payment_methods: any;
   public payment_method_description: any;
   public monizze_balance: any;
   public printer: any;
   private primary_color: any;
   public user_loyalty_point: any;
   public languageUpdateSubscription: any;

   public payment_method = { value: null, description: null };

   public template = "template_default";

   public checkoutFieldsSubscription: any;
   public font_size: number;

   public whitelabel = environment.whitelabel;
   public fol = false;

  constructor(	public userService: UserService,
                public merchantService: MerchantService,
  				      public orderService: OrderService,
  				      private bottomSheet: MatBottomSheet,
                private cd: ChangeDetectorRef,
                private appHelper: AppHelper) { 

    this.fol = this.appHelper.isFol();

  }

  openPaymentMethodSheet(): void { 
  
    if (this.payment_methods.length > 1) {
      const sheetRef = this.bottomSheet.open(PaymentMethodSheetComponent, { data: { payment_methods: this.payment_methods }, });
      const paymentMethodSubscription = sheetRef.instance.paymentMethodSelected.subscribe((payment_method) => {
  		  this.selectPaymentMethod(payment_method);
  		  sheetRef.dismiss();
	    });
	    sheetRef.afterDismissed().subscribe(() => {
  		  paymentMethodSubscription.unsubscribe();
  	  });
    }
  
  }

  ngOnInit() {

    if (this.merchantService.merchant.primary_color) {
      this.primary_color = this.merchantService.merchant.primary_color 
    } else {
      this.primary_color = '' 
    }

    if (this.merchantService.pos) { 
      this.template = 'template_pos'
      this.font_size = this.userService.fontSize;
      this.userService.getFontSize().subscribe(
        font_size => {
          if (font_size) { this.font_size = font_size; }
        }
      );
     };

    this.payment_method.value = this.userService.basket.order.payment_method;
    this.appHelper.setPaymentMethodDescription(this.payment_method);
    
    this.payment_methods = this.appHelper.setPaymentMethodDescriptions(this.userService.basket.checkoutFields.payment_method);
    this.payment_methods = this.appHelper.filterPaymentMethods(this.merchantService.kiosk, this.userService.basket.order, this.payment_methods, this.mode);
    this.appHelper.setPaymentMethodDescription(this.payment_method);
    
    this.calculateBalances();

    this.checkoutFieldsSubscription = this.userService.checkoutFieldsUpdate.subscribe((field_values) => {
      this.appHelper.setPaymentMethodDescription(this.payment_method);
      this.cd.detectChanges();
    });

    this.languageUpdateSubscription = this.userService.languageUpdate.subscribe((event) => {
      if (this.merchantService.pos) {
        this.merchantService.getMerchantByDomain(this.merchantService.merchant.domain).subscribe(
          merchant => {
            this.merchantService.merchant = this.merchantService.updateMerchant(merchant);
            this.appHelper.translatePaymentMethodDescriptions(this.payment_methods);
            this.cd.detectChanges();
          }
        );
      }
    });
  }

  ngOnDestroy() {
     if (this.checkoutFieldsSubscription) this.checkoutFieldsSubscription.unsubscribe();
     if (this.languageUpdateSubscription) this.languageUpdateSubscription.unsubscribe();
  }

  calculateBalances() {
    if (this.userService.basket.order.payment_method == 'MONIZZEMEAL' && this.userService.user.monizze == true) { this.getMonizzeBalance() }
    // else if (this.userService.basket.order.payment_method == 'WALLET') { this.getCredit(); }
  }

  selectPaymentMethod(payment_method) {
    this.userService.basket.order.payment_method = payment_method.value;
    this.payment_method = payment_method;
    this.appHelper.setPaymentMethodDescription(this.payment_method);
    this.emitToParent();
    this.calculateBalances();
  }

  // getCredit() {
  //   console.log("Here we will request the current credit on the card");
  //    if (this.userService.hasUserLoyaltyPoints()) { 
  //      this.user_loyalty_point = this.userService.getUserLoyaltyPoint(this.merchantService.merchant.id);
  //      console.log(this.user_loyalty_point);
  //    };
  // }

  getMonizzeBalance() {

    this.userService.getMonizzeBalance()
    .subscribe(
      response => {
        this.monizze_balance = response.data.emv;
        this.monizzeBalance.emit(this.monizze_balance);
      },
      error => {
        console.log(error);
      }
      );
  }

   emitToParent() {
    this.emitUpdate.emit(true);
   }

}
