/* src/app/components/language-selection/language-selection.component.scss */
.clickable {
  cursor: pointer;
}
.underlined {
  border-bottom: 1px solid;
}
.menu-item {
  padding: 6px 0px 6px 0px;
}
.language-button-kiosk {
  padding: 0.3rem;
  border-radius: 4px;
  font-size: 1.5rem;
  color: white;
}
.language-button-pos {
  padding: 0.2rem;
  border-radius: 4px;
  color: white;
}
/*# sourceMappingURL=language-selection.component.css.map */
