<mat-nav-list>
  <div class="timing d-flex" *ngFor="let dist_timing of data.dist_timings; let i = index">
    
    <div class="marker" *ngIf="dist_timing.value == userService.basket.order.dist_timing">
    </div>
    <div style="width:100%;">
      <a (click)="selectTiming(dist_timing,i)" mat-list-item>
      <span mat-line>
        <div class="d-flex">
          <div style="width:50px;text-align:center">
            <img class="timing_img" src='./assets/timing/{{ dist_timing.value }}.png'>
          </div>
          <div>{{ 'timing.' + dist_timing.value | translate | capitalizeFirst }}</div>
        </div>
      </span>
      </a>
    </div>
  </div>
  
</mat-nav-list>