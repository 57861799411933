import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class TaxonService {

  private taxons:  { [key: number]: any } = {};

  constructor(
  	private apiService: ApiService,
    private userService: UserService
  ) { }

  getMainTaxon(merchant_id: number) {
  	const access_token = null;
    return this.apiService.get('api/v1/taxons?q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant&q[depth_eq]=0&q[is_in_menu_eq]=true&q[s]=lft asc', access_token);
  }

  getStoredTaxon(id) {
    return this.taxons[id];
  }

  getSubTaxons(parent_id: number) {
  	const access_token = null;
    return this.apiService.get('api/v1/taxons?q[parent_id_eq]=' + parent_id + '&q[s]=lft asc', access_token);
  }

  getTaxons(merchant_id: number) {
  	const access_token = null;
    return this.apiService.get('api/v1/taxons?q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant&q[s]=lft asc', access_token);
  }

  getTaxon(id: number) {
  	const access_token = null;
    return this.apiService.get('api/v1/taxons?q[id_eq]=' + id, access_token);
  }

  updateTaxon(taxon): Observable<any> {
      return this.apiService.put('api/v1/taxons/' + taxon.id, JSON.stringify({ taxon: taxon }), this.userService.access_token);
    }

  updateStoredTaxons(taxons) {
    for (const taxon of taxons) {
      if (this.taxons[taxon.id]) {
        for (const key in taxon) {
          this.taxons[taxon.id][key] = taxon[key];
      }
      } else {
        this.taxons[taxon.id] = taxon;
      }
    }
  }

  updateStoredTaxonsProducts(taxon_id, products) {
    if (this.taxons[taxon_id]) { this.taxons[taxon_id].products = products; }
  }

}
