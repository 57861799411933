import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from 'src/app/services/merchant.service';
import { UserService } from 'src/app/services/user.service';
import Keyboard from "simple-keyboard";

@Component({
  selector: 'app-reference-dialog',
  templateUrl: './reference-dialog.component.html',
  styleUrls: ['./reference-dialog.component.scss']
})

export class ReferenceDialogComponent {

  public reference = '';
  public comment = '';
  public placeholder: string;
  public show_keyboard: boolean = false;
  public keyboard: Keyboard;
  public keyboard_input: any;
  public show_reference = false;
  
  constructor(
    private translate: TranslateService,
    public merchantService: MerchantService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ReferenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.merchantService.merchant.comment_title) {
      this.placeholder = this.merchantService.merchant.comment_title;
    }

    if (this.data.show_reference) { this.show_reference = this.data.show_reference }
    if (this.data.reference) { this.reference = this.data.reference; }
    if (this.data.comment) { this.comment = this.data.comment; }
  }

  ngAfterViewInit() {
    if (this.show_keyboard) {
      this.keyboard = new Keyboard({
        onChange: input => this.onChange(input),
        onKeyPress: button => this.onKeyPress(button)
      });

      this.keyboard_input = 'reference';
    }
  }

  onChange = (input: string) => {
    if (this.keyboard_input === 'comment') { this.comment = input; }
    if (this.keyboard_input === 'reference') { this.reference = input; }
  };

  setKeyboardInput(input:string) {
    if (!this.keyboard) { return };

    if (input === 'comment') { this.keyboard.setInput(this.userService.basket.order.comment); }
    if (input === 'reference') { this.keyboard.setInput(this.userService.basket.order.reference); }

    this.keyboard_input = input;
  }

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

  onKeyPress = (button: string) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  onSave() {
    this.dialogRef.close({
      reference: this.reference,
      comment: this.comment
    });
  }
}
