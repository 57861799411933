/* src/app/components/loyalty-card/loyalty-card.component.scss */
.plus-points {
  border-radius: 5px;
  width: 100px;
  height: 70px;
  line-height: 70px;
  font-size: 24px;
  color: white;
  text-align: center;
  font-weight: bold;
}
.title {
  font-weight: bolder;
  text-align: center;
  flex-grow: 1;
  font-size: 1.4em;
}
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.container {
  display: block;
}
.flipstyle {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 600ms;
  max-width: 400px;
}
.front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
}
.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reward-card {
  box-shadow: 5px 5px 20px -9px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 100%;
  height: 200px;
  cursor: pointer;
  position: relative;
}
.loyalty-point {
  position: absolute;
  top: 0px;
  left: 30px;
  z-index: 100;
  width: calc(100% - 60px);
}
.shady {
  border-radius: 10px 10px 0px 0px;
  background: rgba(255, 255, 255, 0.9);
  background:
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 79%,
      rgba(255, 255, 255, 0) 100%);
}
@media (min-width: 992px) {
  .loyalty-point {
    position: relative;
  }
  .loyalty-card {
    width: 390px;
  }
}
/*# sourceMappingURL=loyalty-card.component.css.map */
