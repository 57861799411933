<mat-nav-list>
  <div class="location d-flex" *ngFor="let localLocation of data.localLocations; let i = index">
  	<div class="marker" *ngIf="localLocation.id == data.compareWith">
  	</div>
  	<div style="width:100%;">
  		<a (click)="selectLocalLocation(localLocation)" mat-list-item>
    	<span mat-line>
        <div class="d-flex">
          <div style="width:50px;text-align:center">
            <!-- <ng-container *ngIf="userService.darkMode == false"> -->
              <img class="location_img" src='./assets/scenarios/gray/catering.png'>
            <!-- </ng-container>
            <ng-container *ngIf="userService.user.darkMode == true">
              <img class="location_img" src='./assets/scenarios/guide/catering.png'>
            </ng-container> -->
          </div>
          <div>{{ localLocation.name }}</div>
        </div>
      </span>
  		</a>
  	</div>
  </div>
  
</mat-nav-list>