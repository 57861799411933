<div class="faded-bg" 
(click)="backClicked($event)"
[@fadeInOut]>
</div>

<div class="delivery-dialog"
	 [@slideUp]>

<div style="z-index:9999;position:absolute; bottom: 20px; left: 20px; width:100px;">
      <mat-icon class="clickable" (click)="backClicked($event)">arrow_back</mat-icon>
 </div>

<ng-container *ngIf="order.state == 'distributed'">
	<div style="height:100vh; margin-top: 100px; text-align: center; color: darkred;">
		<fa-icon 
        	class="fa-8x"
            [icon]="faUtensils">
        </fa-icon>
	</div>
<div class="delivery-content px-3" >
	<div class="delivery-content-part">
		<div class="delivery-title">Je bestelling werd afgeleverd.</div>
		<div>Smakelijk!</div>
	</div>
</div>
</ng-container>

<ng-container *ngIf="delivery_coords.length > 0 && order.order_delivery && order.dist_location && order.dist_location.address">

<div class="delivery-content px-3">
	<div class="dot bg-secondary">
		<div style="font-size:1.6em;"><strong>{{ order.order_delivery.duration_in_traffic / 60 | number:'1.0-0' }}</strong></div>
		<div style='font-size:1.4em;'>min</div>
	</div>
	<div class="delivery-content-part">
		<div class="delivery-title">Je bestelling is onderweg</div>
		<div>Je bestelling bij {{ order.merchant_name }} is onderweg en zal binnen +/- {{ order.order_delivery.duration_in_traffic / 60 | number:'1.0-0' }} minuten geleverd worden 
			<ng-container *ngIf="order.order_delivery.user_first_name">door {{ order.order_delivery.user_first_name }}
			</ng-container>
		</div>
	</div>
</div>

<div class="map-wrapper">
	<google-map
		[center]="{lat: delivery_coords[0].latitude, lng: delivery_coords[0].longitude}"
		[zoom]="14"
		width="100%"
		height="91%">
	<map-marker
	[position]="{lat: order.dist_location.address.latitude, lng: order.dist_location.address.longitude}"
	(markerClick)="markerClicked($event)"
	[title]="order.dist_location_name"></map-marker>
	<map-marker *ngIf="delivery_coords.length > 0"
	[position]="{lat: delivery_coords[0].latitude, lng: delivery_coords[0].longitude}"
	(markerClick)="markerClicked($event)"></map-marker>
</google-map>

</div>

</ng-container>

</div>