import { OrderLine } from '../models/order_line';
import { environment } from '../../environments/environment';

declare var device: any;

export class Order {

    id?: number;
    merchant_id?: number;
    merchant_name?: string;
    user_id?: number;
    user_email?: string;
    user_phone?: string;
    intaker?: string;
    device_type?: string;
    dist_timing?: string;
    dist_timing_target?: string;
    dist_timing_target_index = -1;
    dist_location?: any;
    consumption_area?: string;
    scenario?: string;
    state?: string;
    kiosk_id?: number;
    order_lines?: Array<OrderLine>;
    
    payment_method?: string;
    payment_state?: string;

    group_order = false;
    price?: number;
    rounded_price?: number;
    order_order_price_modifiers?: any[];
    coupon_codes?: any;
    price_before_modifiers?: number;
    price_after_coupons?: number;
    joyn_amount?: number;
    joyn_qr?: string;
    joyn_status?: string;
    order_type?: string;
    group?: any;
    group_id?: number;
    group_member_id?: number;
    group_who_pays?: string;
    //group_instant_checkout: boolean = false;
    invoice_requested = false;
    location?: string;
    comment?: string;
    reference?: string;
    created_at?: string;
    updated_at?: string;
    white_label_name?: string;
    
    payconiq?: any;
    bancontact?: any;
    worldline?: any;
    ccv?: any;
    mollie?: any;

    dist_location_id?: number;
    dist_location_name?: string;
    dist_location_typecode?: string;
    order_location_id?: number;
    order_location_typecode?: string;
    order_location_name?: string;

    no_of_persons?: any;

    order_delivery?: any;

    informed_when_prepared = true;
    price_shown = true;

    reservation_id?: number;
    reservations_token?:string;

    vat_info?:Array<any>;

    loyalty_card_qr_code?:string;

    marker?: any;
    infoWindow?: any;

    payment?: any;

    constructor(merchant_id: number) {

        //alert(device.platform);        
        if (typeof device !== 'undefined' && device && device.platform == 'iOS') {
            this.device_type = 'iOSV2'
        } else if (typeof device !== 'undefined' && device && device.platform == 'Android') {
            this.device_type = 'AndroidV2';
        } else { this.device_type = 'web v2'; }

        this.merchant_id = merchant_id;
        this.price = 0;
        this.price_after_coupons = 0;
        this.intaker = 'consumer';
        this.state = 'composing';
        this.order_lines = new Array<OrderLine>();
        this.order_type = 'personal';
        this.white_label_name = environment.whitelabel;
        this.order_order_price_modifiers = new Array<any>();
    }


  }
