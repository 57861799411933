/* src/app/navigation/navigation.component.scss */
.logo {
  max-height: 110px;
  max-width: 150px;
}
.white-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -2;
}
.background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}
.underlined {
  border-bottom: 1px solid;
}
.menu-item {
  padding: 6px 0px 6px 0px;
  align-items: center;
}
.welcome {
  font-size: 2em;
  font-weight: bolder;
}
.menu-link {
  color: black !important;
  text-decoration: none;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0px !important;
  background-color: transparent !important;
}
.menu-top-div {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 10px;
}
.menu-top-div > .close-button {
  right: 10px;
  color: white;
}
.menu-content {
  font-family: "Dosis";
  padding-bottom: 30px;
  background-color: #f0513f;
}
.full-height {
  height: 100vh;
}
ul.nav-menu {
  margin-bottom: 0;
}
.nav-menu {
  display: flex;
  flex-direction: column;
  border-left: white solid 0.5px;
  margin-left: 20px;
  padding: 0;
  color: #fff;
}
.nav-menu a {
  width: 100%;
  border: 0 none;
  text-transform: uppercase;
  color: #ffffff;
}
.nav-menu h5 {
  padding-left: 30px;
}
.nav-item {
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
}
/*# sourceMappingURL=navigation.component.css.map */
