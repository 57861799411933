<div class="title">{{ 'payment_method.title' | translate }}</div>

<mat-nav-list>
  <div class="payment-method d-flex" *ngFor="let payment_method of data.payment_methods; let i = index">
    
    <ng-container *ngIf="payment_method.is_supported && payment_method.is_enabled">

    <div class="marker" *ngIf="payment_method.value == userService.basket.order.payment_method">
    </div>
    <div>
      <a (click)="selectPaymentMethod(payment_method)" mat-list-item>
      <span mat-line>
        <div class="d-flex">
          <div style="width:100px;text-align:center">
            <img class="payment-method_img" src='./assets/payment_methods/{{ payment_method.value }}.png'>
          </div>
          <div>{{ payment_method.description }}</div>
        </div>
      </span>
      </a>
    </div>

    </ng-container>

  </div>
  
</mat-nav-list>