<ng-container *ngIf="delivery">
  <delivery [order]="order" (delivery_unselected)="hideDelivery($event)"></delivery>
</ng-container>


<mat-card class="orders-card p-3" [@fadeInOut] *ngIf="order.dist_location_typecode != 'vending_machine'">
  <div class="d-flex">
    <div class="pe-3">
      <img src='assets/state/state_{{ order.state }}.png' width=40 height=40/>
    </div>
    
    <div *ngIf="order.state == 'distributing'">
      <div *ngIf="order.order_delivery.latitude != null && order.order_delivery.longitude != null; else nolocation" 
        class="clickable" 
        (click)="toggleDelivery($event)" >
        {{ 'order_notif.order_distributing_1' | translate: { merchant_name: order.merchant_name } }} <p class="secondary" style="text-decoration: underline; display:inline;">{{ 'order_notif.order_distributing_2' | translate: { duration : order.order_delivery.duration_in_traffic/60 | number:'1.0-0'} }}</p>
      </div>
      <ng-template #nolocation>
        {{ 'order_notif.order_distributing_no_location' | translate: { merchant_name: order.merchant_name } }}
      </ng-template>
    </div>

    <div *ngIf="order.state == 'distributed'">
      {{ 'order_notif.order_distributed' | translate }}
    </div>
  </div>
</mat-card>


<mat-card class="orders-card p-3" [@fadeInOut] *ngIf="order.dist_location_typecode == 'vending_machine'">
  <div class="d-flex">
    <div class="pe-3">
      <img src='assets/state/state_{{ order.state }}.png' width=40 height=40/>
    </div>

    <div *ngIf="order.state == 'prepared'">
      {{ 'order_notif.order_prepared' | translate: { merchant_name: order.merchant_name } }}
    </div>

    <div *ngIf="order.state == 'distributed'">
      {{ 'order_notif.order_distributed_vending_machine' | translate }}
    </div>
  </div>

  <div *ngIf="order.state == 'prepared' && vending_machine_order" style="text-align:center">
    <div style="font-size:1.4rem;">{{ 'order_notif.unlock_code' | translate }}</div>
    <div style="font-size:2rem;"><strong>{{ vending_machine_order.unlock_code }}</strong></div>
  </div>

</mat-card>