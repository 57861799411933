/* src/app/product/product.component.scss */
.svg-icon {
  margin: auto;
  width: 120px;
  height: 120px;
}
.template_default_description {
  white-space: pre-line;
  font-size: 1.1em;
  line-height: 1.1;
  padding-top: 20px;
  padding-bottom: 10px;
}
.template_pos_description {
  white-space: pre-line;
  font-size: 24px;
  line-height: 1.1;
  padding-top: 20px;
  padding-bottom: 10px;
}
.product_image {
  position: relative;
  max-width: 90vw;
  max-height: 300px;
  width: auto;
  height: auto;
}
.xbutton_add_product {
  position: fixed;
  bottom: 0px;
  left: 0px;
}
Xmat-chip {
  font-size: 8px;
}
.Xmat-chip-list-wrapper .mat-standard-chip,
.mat-chip-list-wrapper input.mat-input-element {
  margin: 4px;
}
.Xmat-standard-chip {
  padding: 3px 5px;
  min-height: 16px;
  height: 1px;
}
.footer-unavailable {
  opacity: 0.5;
}
.template_default_header {
  width: 100%;
  background-color: lightgray;
  font-weight: bolder;
  font-size: 1.4em;
}
.template_pos_header {
  width: 100%;
  background-color: lightgray;
  font-weight: bolder;
  font-size: 24px;
}
.line-through {
  text-decoration: line-through;
}
.content {
  overflow-y: auto;
}
.content::-webkit-scrollbar {
  display: none;
}
.product_part {
  margin: 0px;
}
.product_part_active {
  text-decoration: line-through;
}
.product_image {
  text-align: center;
}
.bar-price {
  font-weight: bolder;
  font-size: 1.4em;
  text-align: right;
}
.bar-add {
  font-size: 1.4em;
  flex-grow: 1;
  text-align: center;
  font-weight: bolder;
}
.bar-amount {
  font-weight: bolder;
  font-size: 1.4em;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 60px;
  text-align: center;
}
.title {
  font-weight: bolder;
  font-size: 14px;
  border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
}
Xmat-icon {
  vertical-align: middle;
}
@media (max-width: 320px) {
  .bar-add {
    font-size: 1em;
  }
  .bar-price {
    font-size: 1em;
  }
  .bar-amount {
    font-size: 1em;
  }
  .template_default_header {
    font-size: 1em;
  }
}
@media (min-width: 321px) and (max-width: 413px) {
  .bar-add {
    font-size: 0.9em;
  }
  .bar-price {
    font-size: 0.9em;
  }
  .bar-amount {
    font-size: 0.9em;
  }
  .template_default_header {
    font-size: 0.9em;
  }
}
@media (min-width: 414px) and (max-width: 767px) {
  .bar-add {
    font-size: 1.2em;
  }
  .bar-price {
    font-size: 1.2em;
  }
  .bar-amount {
    font-size: 1.2em;
  }
  .template_default_header {
    font-size: 1.2em;
  }
}
.down_arrow {
  position: fixed;
  z-index: 99999999;
}
.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}
.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}
.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}
.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #b0b0b0;
}
.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}
.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}
@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}
@media (min-width: 768px) {
  .content {
    min-height: 50vh;
  }
}
/*# sourceMappingURL=product.component.css.map */
