import { Component, OnInit, Input, Output, EventEmitter, ViewChild,ViewEncapsulation } from '@angular/core';

import { ProductService } from '../services/product.service';
import { MerchantService } from '../services/merchant.service';

import { Option } from '../models/option';
import { OrderOptionValue } from '../models/order_option_value';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class OptionComponent implements OnInit {

  @Input() public order_option: any;
  @Input() public product: any;
  @Output() updated = new EventEmitter<boolean>();
  public option: Option;

  public units = [];
  public selectedUnit: any;
  public chosenOption: any;

  public faCircleCheck = faCircleCheck;
  public primary_color = '#828D8B';

  public template: string = "template_default";

  @ViewChild('mySel') skillSel;
  
  constructor(private productService: ProductService,
              private merchantService: MerchantService,
              public userService: UserService) { }

  ngOnInit() {

     if (this.merchantService.pos) { this.template = "template_pos"; };
     if (this.merchantService.kiosk) { this.template = 'template_kiosk'; };

    this.option = this.productService.getStoredOption(this.order_option.option_id);

    for (const option_value of this.option.option_values) {

      if (option_value.is_default) {
        option_value.checked = true;
        this.chosenOption = option_value.id;
        if (this.option.min_select == 1 && this.option.max_select == 1) { this.switchOptionValue(option_value); }
        else { this.toggleOptionValue(option_value); }
        //if (option_value.unit != undefined) { this.selectedUnit = option_value.unit };
      } else {
        option_value.checked = false;
      }

      option_value.unit_proportion_shown = false;
      if (option_value.unit && (['kg','g'].indexOf(this.option.base_unit) != -1) && (['kg','g'].indexOf(option_value.unit) == -1)) {
        option_value.unit_proportion_shown = true;
      }

      if (option_value.unit && this.option.base_unit && option_value.unit == this.option.base_unit) { 
        this.option.base_unit_name = option_value.name;
      };
      if(option_value.unit && this.units.find(unit => unit.value == option_value.unit) == undefined) { this.units.push({ value: option_value.unit, selected: false }); }
    }

    /*if (this.units.length > 0 && !this.selectedUnit) {
      this.selectedUnit = this.units[0].value;
    }*/

    this.updateValues(false);

    if (this.option.min_select == 0) {
      this.order_option.valid = true;
      this.updated.emit(true);
    }

    if (this.merchantService.merchant.primary_color) {
      this.primary_color = this.merchantService.merchant.primary_color;
    }
  }

  toggleSelection(chip) {
    chip.toggleSelected();
    chip.selected ? this.selectedUnit = chip.value : this.selectedUnit = undefined;
    this.updateValues(true);  
  }

  updateValues(unselectAll) {
    
    if (unselectAll) {
      this.chosenOption = null;
      for (let option_value of this.option.option_values) { option_value.checked = false; }
      this.order_option.order_option_values = [];
      if (this.skillSel) { this.skillSel.options.forEach( (item) => { item.selected = false; }) };
    }

    this.verifyOrderOptionValid();
  }

  verifyOrderOptionValid() {

    let currentSelection = this.order_option.order_option_values.length;

    let valid = true;
    if (this.option.min_select > 0 && currentSelection < this.option.min_select) valid = false;
    if (this.option.max_select > 0 && currentSelection > this.option.max_select) valid = false;
    this.order_option.valid = valid;
    this.updated.emit(true);
  }

  toggleOptionValue(option_value) {

    const index = this.order_option.order_option_values.findIndex(x => x.option_value_id == option_value.id);
    
    if (index != -1) { this.order_option.order_option_values.splice(index, 1); option_value.checked = false;}
    else {
      const order_option_value  = new OrderOptionValue();
      order_option_value.is_default = option_value.is_default;
      order_option_value.option_value_id = option_value.id;
      order_option_value.name = option_value.name;
      order_option_value.unit = option_value.unit;
      order_option_value.price_shown = option_value.price_shown;
      
      order_option_value.price_adapt = option_value.price_adapt_abs;
      
      if (option_value.price_adapt_method == 'rel') order_option_value.price_adapt = option_value.price_adapt_rel * this.product.price;
      
      option_value.checked = true;
      this.order_option.order_option_values.push(order_option_value);
    }

    this.verifyOrderOptionValid();
  }

  switchOptionValue(option_value) {

    this.order_option.order_option_values = [];

    const order_option_value  = new OrderOptionValue();
    order_option_value.is_default = option_value.is_default;
    order_option_value.option_value_id = option_value.id;
    order_option_value.name = option_value.name;
    order_option_value.price_shown = option_value.price_shown;
    order_option_value.unit = option_value.unit;
    
    order_option_value.price_adapt = option_value.price_adapt_abs;
    if (option_value.price_adapt_method == 'rel') order_option_value.price_adapt = option_value.price_adapt_rel * this.product.price;
    this.option.option_values.forEach(option_value => { option_value.checked = false; });
    option_value.checked = true;

    this.order_option.order_option_values.push(order_option_value);
    this.verifyOrderOptionValid();
  }

}
