import { Injectable } from '@angular/core';

@Injectable()
export class StylingHelper {

	constructor() {}

	getTemplate(template) {
		let to_return = 'template1';
		if (template) { to_return = template; }
		return to_return;
	}

}