import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { UserService} from '../../services/user.service';

import { AppHelper } from '../../helpers/app-helper';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-scenario-sheet',
  templateUrl: './scenario-sheet.component.html',
  styleUrls: ['./scenario-sheet.component.scss']
})
export class ScenarioSheetComponent implements OnInit {

	public scenarioSelected = new EventEmitter();
  public whitelabel = environment.whitelabel;


  constructor(   public userService: UserService,
                 private appHelper: AppHelper,
  			         @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {

    if (!this.appHelper.doesFileExist(`assets/scenarios/${this.whitelabel}/takeaway.png`)) {
      this.whitelabel = "guide";
    };

  }

  selectScenario(scenario) {
  	this.scenarioSelected.emit(scenario);
  }

}
