import { Address } from '../models/address';
import { Taxon } from '../models/taxon';
import { Product } from '../models/product';

export class Merchant {
    id?: number;
    name?: string;
    domain?: string;
    header_image?: any;
    venue_image?: any;
    image?: any;

    description?: string;
    short_description?: string;
    address?: Address;
    features?: any;

    style_overrides?: any;

    waiting_time?: number;
    waiting?: number;
    daily_order_count?: number;
    daily_order_count_week_ago?: number;
    daily_revenue?: number;
    daily_revenue_week_ago?: number;

    local_locations?: any;

    tags?: any;
    line1?: any;
    latitude?: any;
    longitude?: any;

    primary_color?: any;

    promotion?: any;

    level_of_activity?: number;

    current_week_scheme?: any;

    is_open?: any;
    settings?: any;

    state_info: any = {};
    distance_to_user?: number;

    mainCategories: Array<Taxon>;
    categories: Array<Taxon>;
    products: Array<Product>;
    order_constraints: any;
    menu?: any;

    favorite = false;

    comment_title?: string;
    payment_credential_monizze?: any;

    cash_description_takeaway?: any;
    cash_description_delivery?: any;
    cash_description_eatin?: any;
    cash_description_table?: any;
    status_formatted?: any;

    pm_on_top?: boolean;

    fol_uplift?: number = 0;

    constructor() {}

  }
