import { Component, OnInit, Input } from '@angular/core';
import { MerchantService } from 'src/app/services/merchant.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { faChevronDown, faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { ChangeDetectorRef } from '@angular/core';
import { AppHelper } from '../../helpers/app-helper';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss']
})
export class LanguageSelectionComponent implements OnInit {

  public faChevronDown = faChevronDown;
  public faGlobe = faGlobe;
  public whitelabel = "guide";
  public fol = false;

  @Input() highlightLanguageSelection = false;

  constructor(
    public merchantService: MerchantService,
    public translate: TranslateService,
    public userService: UserService,
    private appHelper: AppHelper,
    private cd: ChangeDetectorRef,
  ) { 
    this.fol = this.appHelper.isFol();
  }

  ngOnInit(): void {

    this.whitelabel = environment.whitelabel;

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      let buttons = document.querySelectorAll(".current-lang");
        Array.from(buttons).forEach(b => {
          b.textContent = event.lang.toUpperCase()
        })
    });

  }

  changeLanguage(lang) {
    this.translate.use(lang).subscribe(() => {
        this.cd.detectChanges()
      });
    this.userService.changeUserLanguage(lang);
  }

}
