<div class="subtitle"
     *ngIf="template != 'template_pos' && !condensed">
  {{ 'timing.subtitle' | translate }}
</div>


<div class="{{ template }} d-flex align-items-center timings font-dosis"
     *ngIf="userService.basket.order.dist_timing && !kiosk">

  <div class="" style="width:75px;text-align: center"
       *ngIf="template != 'template_pos'">
    <img class="image"
       src='./assets/timing/{{ userService.basket.order.dist_timing }}.png'/>
  </div>

  
  <ng-container *ngIf="template == 'template_pos' && !pos_dialog">
    <div *ngFor="let dist_timing of userService.basket.checkoutFields.dist_timing">

        <div  class="d-flex align-items-center justify-content-center clickable" 
          style="height: 60px; width: 60px; border-radius: 10px; background-color: darkgray; margin-right: 7px;"
          [ngStyle]="{'background-color': userService.basket.order.dist_timing == dist_timing.value ? (fol ? '#f0513f' : merchantService.merchant.primary_color) : 'darkgrey'}"
          (click)="selectTime(dist_timing.value);">
            <!--
            <img style="max-width: 4vh; max-height: 4vh;"
                 src='./assets/timing/{{ dist_timing.value }}.png'>
            -->
                  <fa-icon *ngIf="dist_timing.value == 'asap'"
                      size="2x"
                      [icon]="faPaperPlane"
                      style="color:'white'">
                  </fa-icon> 
                  <fa-icon *ngIf="dist_timing.value == 'timed'"
                      size="2x"
                      [icon]="faClock"
                      style="color:'white'">
                  </fa-icon> 
        </div>

    </div>
  </ng-container>
  
  <div style="flex-grow:1" *ngIf="!pos_dialog">
    
    <div style="position:relative;">

        <div (click)="openTimingSheet(timed_enabled)"
             class="display-inline clickable no-selection"
             *ngIf="!merchantService.pos && userService.basket.order.dist_timing == 'asap'">
            <strong>{{ 'timing.asap' | translate }}</strong>
            <i  *ngIf="timed_enabled" 
                class="ps-2 secondary_low bold"
                [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}">
              <fa-icon 
                    class="secondary_low"
                    [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : nil}"
                    [icon]="faEdit">
              </fa-icon>
              {{ 'timing.edit' | translate }}
            </i>
        </div>

        <ng-container *ngIf="timed_enabled && userService.basket.order.dist_timing == 'timed'">
          <div *ngIf="!merchantService.pos"
               class="display-inline clickable no-selection"
               (click)="openTimingSheet(asap_enabled)">
               <strong>{{ 'timing.timed' | translate }}</strong>
               <i *ngIf="asap_enabled" 
                  class="ps-2 secondary_low bold"
                  [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}">
                <fa-icon 
                    class="secondary_low"
                    [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
                    [icon]="faEdit">
              </fa-icon>
              {{ 'timing.edit' | translate }}
              </i>
          </div>

          <div class="d-flex">
            
            <mat-error  class="clickable no-selection"
                        (click)="openDateSheet()"
                        *ngIf="userService.basket.order.dist_timing_target_index == -1 || !userService.basket.checkoutFields.dist_timing_target[userService.basket.order.dist_timing_target_index]; else dateChosen">
              <strong><i>{{ dateFormatted }}</i></strong>
            </mat-error>

            <ng-template #dateChosen>

              <div>

                <div  class="clickable no-selection"
                (click)="openDateSheet()"><i>{{ dateFormatted }}</i>
                
                  <i  *ngIf="!merchantService.pos"
                      class="clickable no-selection ps-2 secondary_low bold" 
                      (click)="openDateSheet()"      
                      [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '' }">
                  
                    <fa-icon 
                      class="secondary_low"
                      [ngStyle]="{'color': !fol && merchantService.merchant.primary_color ? merchantService.merchant.primary_color : ''}"
                      [icon]="faEdit">
                    </fa-icon>
                    {{ 'timing.edit' | translate }}
                  
                  </i>
                
                </div>
                <div class="d-flex">
                  <div  class="clickable no-selection"
                      (click)="openTimeSheet()"
                      *ngIf="dist_timing_target_index != -1">
                      <i *ngIf="dist_timing_target && (!merchantService.merchant.settings['s_block_display_scenario_' + userService.basket.order.scenario] || merchantService.merchant.settings['s_block_display_scenario_' + userService.basket.order.scenario] == 'TIME')">
                      {{ 'timing.at' | translate }} {{ dist_timing_target | date:'HH:mm' }}{{ 'timing.h' | translate }}
                      </i>
                      <i *ngIf="dist_timing_target && merchantService.merchant.settings['s_block_display_scenario_' + userService.basket.order.scenario] && merchantService.merchant.settings['s_block_display_scenario_' + userService.basket.order.scenario] == 'BLOCK'">
                        {{ 'timing.block' | translate: { begin: dist_timing_target | date:'HH:mm', end: dist_timing_target_end | date:'HH:mm'} }}
                      </i>
                  </div>
                </div>
              </div>
                
            </ng-template>

          </div>
        </ng-container>

  </div>

</div>


    <div style="width:50px;text-align:right;" *ngIf="!timingEnabled()">
      <mat-icon class="secondary_400">warning</mat-icon>
    </div>


</div>

<ng-container *ngIf="kiosk">
  <ng-container *ngTemplateOutlet="timingBlocks">

  </ng-container>
</ng-container>

<ng-container *ngIf="pos_dialog">
  <div mat-dialog-title>
    <div class="subtitle">
      {{ 'timing.subtitle_pos' | translate }}
    </div>
  </div>
</ng-container>

  <div  class="invisible-scroll"
        *ngIf="pos_dialog">  
    <ng-container *ngTemplateOutlet="timingBlocks">
    </ng-container>
  </div>


<ng-template #timingBlocks>
  <div class="row" *ngIf="!pos_dialog">
    <ng-container *ngFor="let dist_timing of this.userService.basket.checkoutFields.dist_timing">
      <div *ngIf="dist_timing.value == 'asap' && dist_timing.is_supported"
          class="col-3">
        <div class="d-flex align-items-center justify-content-center rounded shadow m-2 p-2 clickable"
          style="min-height:60px;"
          (click)="updateTiming(dist_timing.value)"
          [ngClass]="{'bg-primary fw-bold': dist_timing.value == userService.basket.order.dist_timing }">
            <fa-icon *ngIf="dist_timing.value == 'asap'"
                      [icon]="faPaperPlane"
                      class="me-1"
                      style="color:'black'">
            </fa-icon> 
            {{ 'timing.' + dist_timing.value | translate | capitalizeFirst }}
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngFor="let dist_timing_target of userService.basket.checkoutFields.dist_timing_target; let i = index">
      <div class="fw-bold"
           *ngIf="dist_timing_target.slots_available">
       {{ dist_timing_target.label }} ({{ dist_timing_target.value | date:'dd MMMM yyyy':'':this.userService.user.locale }})
      </div>
      <div class="row">
        <ng-container *ngFor="let slot of userService.basket.checkoutFields.dist_timing_target[i].slots">
          <div  *ngIf="slot.is_enabled"
                class="col-3">
            <div class="d-flex align-items-center justify-content-center rounded shadow m-2 p-2 clickable"
                style="min-height:60px;"
                (click)="setTime(slot, index)"
                [ngClass]="{'fw-bold': slot.value == userService.basket.order.dist_timing_target }"
                [ngStyle]="{'color': userService.darkMode ? 'white' : 'black', 'background-color': slot.value == userService.basket.order.dist_timing_target ? (fol? 'rgb(240, 81, 63)' : (merchantService.merchant.primary_color ? merchantService.merchant.primary_color : '')) : (userService.darkMode ? '#515151' : '')}">
                <div *ngIf="slot.value == userService.basket.order.dist_timing_target">
                </div>
            <ng-container *ngIf="block_display == 'TIME'">{{ slot.value | date:'HH:mm'}}{{ 'timing.h' | translate }}</ng-container>
            <ng-container *ngIf="block_display == 'BLOCK'">{{ 'timing.block' | translate: { begin: slot.begin | date:'HH:mm', end: slot.end | date:'HH:mm'} }}</ng-container>
          </div>
        </div>
        </ng-container>
      </div>
  </ng-container>
</ng-template>

