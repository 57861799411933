import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from '../navbar/navbar.component';
import { LanguageSelectionComponent } from '../components/language-selection/language-selection.component';
import { LocationComponent } from '../location/location.component';
import { OrderComponent } from '../order/order.component';
import { CategoryComponent } from '../category/category.component';
import { ScenarioComponent } from '../scenario/scenario.component';
import { TimingComponent } from '../timing/timing.component';
import { ProductComponent } from '../product/product.component';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';
import { LoyaltyCardComponent } from '../components/loyalty-card/loyalty-card.component';
import { OptionComponent } from '../option/option.component';
import { BasketbarComponent } from '../basketbar/basketbar.component';
import { PartSetComponent } from '../part-set/part-set.component';

import { TranslateModule } from "@ngx-translate/core";

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { StylingHelper } from '../helpers/styling-helper';

import { OrderNotifComponent } from '../order-notif/order-notif.component';
import { DeliveryComponentComponent } from '../components/delivery-component/delivery-component.component';

import { CapitalizeFirstPipe } from '../pipes/capitalize-first.pipe';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { QRCodeModule } from 'angularx-qrcode';

import { NgxSpinnerModule } from 'ngx-spinner';

import { GoogleMapsModule } from '@angular/google-maps'
import {MatRippleModule} from '@angular/material/core';


@NgModule({
  declarations: [

  	NavbarComponent,
    LanguageSelectionComponent,
  	LocationComponent,
  	OrderComponent,
    ScenarioComponent,
    TimingComponent,
    ProductComponent,
    PaymentMethodComponent,
    LoyaltyCardComponent,
    OptionComponent,
    BasketbarComponent,
    PartSetComponent,

    OrderNotifComponent,
    DeliveryComponentComponent,
    CategoryComponent,
    CapitalizeFirstPipe
  ],
  providers: [
      StylingHelper
  ],
  exports: [
  	NavbarComponent,
    LanguageSelectionComponent,
  	LocationComponent,
  	OrderComponent,
    ScenarioComponent,
    TimingComponent,
    ProductComponent,
    PaymentMethodComponent,
    LoyaltyCardComponent,
    OptionComponent,
    BasketbarComponent,
    PartSetComponent,
    OrderNotifComponent,
    DeliveryComponentComponent,
  	MatSlideToggleModule,
    MatCheckboxModule,
    MatStepperModule,
    MatBottomSheetModule,
    MatTabsModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSnackBarModule,
    MatChipsModule,
    MatGridListModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    CategoryComponent,
    CapitalizeFirstPipe,
    LazyLoadImageModule,
    NgxSpinnerModule,
    GoogleMapsModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    QRCodeModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatStepperModule,
    MatBottomSheetModule,
    MatTabsModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSnackBarModule,
    MatChipsModule,
    MatGridListModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    MatSelectModule,
    MatDialogModule,
    TranslateModule,
    FontAwesomeModule,
    LazyLoadImageModule,
    NgxSpinnerModule,
    GoogleMapsModule,
    MatRippleModule,
  ]
})
export class SharedModule { }
