<div class="first-part full-height"
     [ngStyle]="{'background-color': fol && '#f0513f'}">

<ng-container *ngIf="!fol">

<button mat-icon-button (click)="closeMenu()">
  <mat-icon>close</mat-icon>
</button>

<div class="container ps-2">

  <div class="d-flex flex-column">

    <div class="menu-item " 
         style="display: inline-flex"
         [routerLink]="['/']">

        <button class="me-2" mat-icon-button>
          <h5>
           <fa-icon
              class="" 
              [icon]="faHome">
        </fa-icon>
        </h5>

        </button>

        <h5>
      <a class="menu-link">{{ 'navigation.home' | translate }}</a>

       <mat-icon   matBadge="0"
                  [matBadgeHidden]="true" 
                  matBadgeColor="warn">
      </mat-icon>

      </h5>
    </div>

    <ng-container *ngFor="let page of userService.pages; index as i">
      <div class="menu-item"
      [ngClass]="{ '': (i+1) < userService.pages.length }"
      style="display: inline-flex"
      routerLink="{{ page.url }}">
      
      
      <button class="me-2" mat-icon-button>
          <h5>
           <fa-icon
              class="" 
              [icon]="page.icon">
        </fa-icon>
      </h5>

        </button>

      <h5>
      


      <a class="menu-link">{{ page.title }}</a>

      <mat-icon   matBadge="{{page.warnings}}"
                  [matBadgeHidden]="page.warnings == 0" 
                  matBadgeColor="warn"
                  (click)="openPage(page.url)">
      </mat-icon>

      </h5>

    </div>
  </ng-container>

  <div class="menu-item " [routerLink]="['/terms-and-conditions']">
    <a class="menu-link">{{ 'navigation.terms_and_conditions' | translate | capitalizeFirst }}</a>
  </div>

  <div class="menu-item" [routerLink]="['/privacy-policy']">
    <a class="menu-link">{{ 'navigation.privacy_policy' | translate | capitalizeFirst }}</a>
  </div>

  <div class="pt-3 menu-item"
       *ngIf="userService.user">
  <a (click)="logoutUser()">{{ 'navigation.log_out' | translate }}</a>
</div>

</div>


<div class="d-md-block button-row">

  <ng-container *ngIf="userService.user">

    <button *ngIf="1==2"
    mat-flat-button 
    matBadge="8" 
    matBadgePosition="after" 
    matBadgeColor="accent"
    color="primary">
    {{ 'navigation.notifications' | translate }}
  </button>

</ng-container>

</div>

</div>

<div class="overflow-hidden white-background">
</div>

<div class="overflow-hidden background"
[style.background]="'url(' + background_image + ')'"
[style.background-repeat]="background_repeat"
[style.background-size]="background_size"
[style.background-position]="'center'">
</div>

</ng-container>


<!-- FRIETJESONLINE -->
<ng-container *ngIf="fol">

    <nav class="menu-content">
      <div class="menu-top-div">
        <button class="close-button" mat-icon-button (click)="closeMenu()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ul class="nav-menu">
        <div class="d-flex nav-item">
          <button mat-icon-button>
            <fa-icon [icon]="faHome"></fa-icon>
          </button>
          <div class="h5 mb-0 ms-4">
            <a routerLink="/" style="text-decoration: none;">Home</a>
            <mat-icon   matBadge="0"
              [matBadgeHidden]="true" 
              matBadgeColor="warn">
            </mat-icon>
          </div>
        </div>

        <ng-container *ngIf="!this.userService.user">
          <div class="d-flex nav-item">
            <button mat-icon-button>
              <fa-icon [icon]="faSignIn"></fa-icon>
            </button>
            <div class="h5 mb-0 ms-4">
              <a  style="text-decoration: none;"
                  routerLink="/login">{{ 'user.login' | translate }}</a>
              <mat-icon matBadge="0"
                [matBadgeHidden]="true"
                matBadgeColor="warn">
              </mat-icon>
            </div>
          </div>
          <div class="d-flex nav-item">
            <button mat-icon-button>
              <fa-icon [icon]="faUser"></fa-icon>
            </button>
            <div class="h5 mb-0 ms-4">
              <a routerLink="/register"
                style="text-decoration: none;">{{ 'user.register' | translate }}</a>
              <mat-icon matBadge="0"
                [matBadgeHidden]="true"
                matBadgeColor="warn">
              </mat-icon>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="this.userService.user">
          <ng-container *ngFor="let page of userService.pages; index as i">
            <div class="d-flex nav-item"
              routerLink="{{ page.url }}">
              <button mat-icon-button>
                <fa-icon [icon]="page.icon"></fa-icon>
              </button>
              <div class="h5 mb-0 ms-4">
                <a>{{ page.title }}</a>
                <mat-icon   matBadge="{{page.warnings}}"
                            [matBadgeHidden]="page.warnings == 0"
                            matBadgeColor="warn"
                            (click)="openPage(page.url)">
                </mat-icon>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div class="d-flex nav-item">
          <div style="width:40px;height:40px;"></div>
          <div class="h5 mb-0 ms-4">
            <a routerLink="/about-us" style="text-decoration: none;">{{ 'navigation.about_us' | translate | capitalizeFirst }}</a>
            <mat-icon matBadge="0"
              [matBadgeHidden]="true" 
              matBadgeColor="warn">
            </mat-icon>
          </div>
        </div>
        
        <div class="d-flex nav-item">
          <div style="width:40px;height:40px;"></div>
          <div class="h5 mb-0 ms-4">
            <a routerLink="/contact-us-support" style="text-decoration: none;">{{ 'contact.contact_us' | translate | capitalizeFirst }}</a>
            <mat-icon matBadge="0"
              [matBadgeHidden]="true" 
              matBadgeColor="warn">
            </mat-icon>
          </div>
        </div>

<div class="d-md-block button-row">
        <ng-container *ngIf="this.userService.user">
          <div class="d-flex nav-item"
            (click)="logoutUser()">
            <button mat-icon-button>
              <fa-icon [icon]="faSignOut"></fa-icon>
            </button>
            <div class="h5 mb-0 ms-4">
              <a>{{ 'pos.log_out' | translate }}</a>
              <mat-icon matBadge="0"
                [matBadgeHidden]="true" 
                matBadgeColor="warn">
              </mat-icon>
            </div>
          </div>
        </ng-container>
      </div>
      </ul>
    </nav>

</ng-container>

</div>
