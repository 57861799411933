/* src/app/category/category.component.scss */
.svg-icon {
  margin: auto;
  width: 60px;
  height: 45px;
}
hr {
  margin: 3px;
}
.category_background {
  width: 100%;
  height: 75px;
  background-color: lightgray;
  position: relative;
  top: -1px;
}
.product {
  min-height: 85px;
}
.product_unavailable {
  font-size: 10px;
}
.marked-product-div {
  margin-left: -15px;
  flex-basis: 25px;
  flex-shrink: 0;
  min-height: 60px;
}
img {
  max-width: 60px;
  max-height: 45px;
}
.unavailable {
  opacity: 0.35;
}
.line-through {
  text-decoration: line-through;
}
.main-image-div {
  text-align: center;
  flex-basis: 110px;
  flex-shrink: 0;
  flex-grow: 0;
  padding-left: 5px;
  padding-right: 5px;
}
.main-image-small {
  height: 30px;
}
.main-image-signage {
  height: 70px;
}
.main-image {
  text-align: center;
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
  height: 55px;
}
.product-div {
  flex-grow: 1;
}
.product-col {
  border-bottom: 1px solid lightgray;
}
.product-col:focus {
  outline: none;
}
.product-col {
  transition: all 0.2s ease-in-out;
}
.promo {
  width: 20px;
  height: 20px;
}
.pos-box {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 12px;
  height: 100%;
}
@media (min-width: 992px) and (max-width: 1439px) {
  .main-image-div {
    flex-basis: 110px;
  }
  .marked-product-div {
    flex-basis: 10px;
    flex-shrink: 0;
  }
  .product-div {
    padding-left: 20px;
    flex-basis: 20px;
  }
  .promo {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1440px) {
  .marked-product-div {
    flex-basis: 10px;
    flex-shrink: 0;
  }
  .promo {
    width: 40px;
    height: 40px;
  }
  .product-div {
    padding-left: 20px;
  }
}
.template1.product_name {
  font-weight: bolder;
  font-size: 1em;
  line-height: 1;
}
.template1.product_description {
  overflow-wrap: anywhere;
  white-space: pre-line;
  line-height: 1;
}
.template1.product_price {
  font-weight: bolder;
}
@media (min-width: 992px) and (max-width: 1439px) {
  .template1.category_title {
    border-radius: 0px 5px 5px 0px;
    width: calc(70vw - 30px) !important;
  }
  .template1.row {
    padding-left: 50px;
    padding-right: 50px;
    width: 70vw !important;
  }
  .template1.main-image {
    height: 70px;
  }
}
@media (min-width: 1200px) {
  .template1.main-image-div {
    flex-basis: 160px;
  }
  .template1.main-image {
    height: 110px;
  }
}
@media (min-width: 1440px) {
  .template1.category_title {
    border-radius: 0px 5px 5px 0px;
    width: calc(70vw - 30px) !important;
  }
  .template1.row {
    padding-left: 50px;
    padding-right: 50px;
    width: 70vw !important;
  }
}
.template2.category_title {
  font-size: 18px;
  font-weight: bolder;
  width: 100%;
  position: relative;
  top: -1px;
  border-bottom: 1px solid;
}
.template2.category_title_signage {
  font-size: 30px;
  font-weight: bolder;
  width: 100%;
  position: relative;
  top: -1px;
  border-bottom: 1px solid;
}
.template2.product_name {
  font-weight: bolder;
  font-size: 1.2em;
  line-height: 1.1;
}
.template2.product_name_signage {
  font-size: 26px;
  font-weight: bolder;
  line-height: 1.1;
  text-transform: uppercase;
}
.template2.product_description {
  overflow-wrap: anywhere;
  white-space: pre-line;
  font-size: 1em;
  line-height: 1.1;
  margin-top: 5px;
  margin-bottom: 5px;
}
.template_kiosk.category_title {
  font-size: 18px;
  font-weight: bolder;
  width: 100%;
  position: relative;
  top: -1px;
  border-bottom: 1px solid;
}
.template_kiosk.category_title_signage {
  font-size: 30px;
  font-weight: bolder;
  width: 100%;
  position: relative;
  top: -1px;
  border-bottom: 1px solid;
}
.template_kiosk.product_name {
  font-weight: bolder;
  font-size: 1.2em;
  line-height: 1.1;
}
.template_kiosk.product_name_signage {
  font-size: 26px;
  font-weight: bolder;
  line-height: 1.1;
  text-transform: uppercase;
}
.template_kiosk.product_description {
  overflow-wrap: anywhere;
  white-space: pre-line;
  font-size: 1em;
  line-height: 1.1;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1439px) {
  .template2.category_title {
    width: calc(100vw - 680px) !important;
  }
  .template2.row-full {
    width: calc(100vw - 680px) !important;
  }
  .template2.main-image {
    height: 70px;
  }
  .template_kiosk.category_title {
    width: 70vw !important;
  }
  .template_kiosk.row-full {
    width: 70vw !important;
  }
  .template_kiosk.main-image {
    height: 70px;
  }
}
@media (min-width: 1440px) {
  .template2.main-image-div {
    flex-basis: 175px;
  }
  .template2.category_title {
    width: calc(100vw - 680px) !important;
  }
  .template2.row-full {
    width: calc(100vw - 680px) !important;
  }
  .template2.main-image {
    height: 110px;
  }
  .template_kiosk.main-image-div {
    flex-basis: 175px;
  }
  .template_kiosk.category_title {
    width: calc(100vw - 680px) !important;
  }
  .template_kiosk.row-full {
    width: calc(100vw - 680px) !important;
  }
  .template_kiosk.main-image {
    height: 110px;
  }
}
.highlight {
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #f26657;
}
.highlight_fol {
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #fdd329;
}
.highlight-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.template_product_highlight {
  height: 350px;
}
/*# sourceMappingURL=category.component.css.map */
