/* src/app/option/option.component.scss */
.template_default_title {
  font-size: 14px;
  border-bottom: 1px solid lightgray;
}
.template_pos_title {
  font-size: 18px;
  border-bottom: 1px solid lightgray;
}
.option {
  padding: 20px;
  border: 4px solid;
  border-radius: 10px;
}
.option.marked {
  border: 4px solid !important;
  border-radius: 15px;
  padding: 0px;
}
.option .mdc-form-field {
  width: 100% !important;
}
.option .mdc-form-field > label {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
.option.mat-mdc-list-item-interactive:focus::before {
  opacity: 0 !important;
}
.radio-button-option:not(.darkMode .radio-button-option) > mat-radio-button:hover {
  background-color: #EFEFEF;
}
.darkMode .radio-button-option > mat-radio-button:hover {
  background-color: #454545;
}
.mat-ripple-element {
  z-index: -1 !important;
}
.mat-ripple {
  z-index: 50;
}
.template_pos_mat-list-option {
  height: 70px !important;
}
.template_pos_mat-list-option .option-info {
  max-height: 70px;
}
.template_kiosk_mat-list-option {
  height: 200px !important;
}
.title-name {
  font-weight: bolder;
}
mat-radio-button {
  display: block !important;
}
.template_kiosk .mat-radio-container {
  height: 30px !important;
  width: 30px !important;
}
.template_kiosk .mat-pseudo-checkbox {
  height: 30px !important;
  width: 30px !important;
}
.template_kiosk .mat-pseudo-checkbox-checked:after {
  height: 12px !important;
  width: 20px !important;
}
.template_kiosk .mat-radio-outer-circle {
  height: 30px !important;
  width: 30px !important;
}
.template_kiosk .mat-radio-inner-circle {
  height: 30px !important;
  width: 30px !important;
}
.template_kiosk .mat-radio-button .mat-radio-ripple {
  height: 30px !important;
  width: 30px !important;
  left: calc(50% - 30px) !important;
  top: calc(50% - 30px) !important;
}
.template_kiosk .mat-list-item-content {
  height: 50px !important;
}
/*# sourceMappingURL=option.component.css.map */
