export const environment = {
    production: true,
    base_api_url: 'https://prd.one2three.network/',
    ENVIRONMENT: 'production',
    product_display: 'contain',
    whitelabel: 'fol_fritesenligne',
    tag: 'fol',
    venueImage: 'logo',
    senderID: '751061951976',
    publish_key: 'pub-c-90616b63-2c3d-4ef4-8788-ac7f335f6ad9',
    subscribe_key: 'sub-c-51e5d7a2-fd65-11e4-9d89-0619f8945a4f',
    scenarios: ['takeaway', 'delivery'],
    invoice_request: false,
    gps_requested: true,
    background_size: 'cover',
    background_repeat: 'cover',
    use_predefined_scenarios: false,
    pos: false,
    kitchen: false
};
