export class OrderOptionValue {

    id?: number;
    price_adapt?: number;
    is_default?: boolean;
    option_value_id?: number;
    name?: string;
    unit?: string;
    amount: number;
    price_shown: boolean = true;

    constructor() {
    	this.amount = 1;
    }
  }
