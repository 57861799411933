/* src/app/order-notif/order-notif.component.css */
.orders-card {
  opacity: 0.9;
  max-width: 600px;
  width: 75%;
}
mat-card {
  background-color: #fff9 !important;
}
.mat-mdc-card {
  box-shadow: none !important;
}
/*# sourceMappingURL=order-notif.component.css.map */
